import api from '@/api/api';
import { TMarketBetResponse } from '../../trademanager.types';
import { TBetsForRunnerParams } from './betsForRunner.types';

export const betsForRunnerURL =
  '/bookie/trade-manager/race-details/bets-for-runner';

// Bets For Runner
export const queryBetsForRunner = (params: TBetsForRunnerParams) =>
  api
    .get<TMarketBetResponse>(betsForRunnerURL, { params })
    .then((res) => res.data);
