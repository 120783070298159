import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  RadioGroup,
  VStack,
  Radio,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '@/common/hooks/useRedux';
import { setConfigureRunnerModal } from '@/features/tradeManager/pages/RaceDetails/Services/RaceDetails.slices';
import {
  ERunnerConfigureOption,
  TRaceDetailsFormValues,
} from '../../RaceDetailsTable.types';

type TRunnerConfigureModalProps = {
  onSubmitSettled: (values: TRaceDetailsFormValues | undefined) => void;
  setConfigureOption: (option: ERunnerConfigureOption) => void;
  configureOption: string;
  isSubmitting: boolean;
};

const RunnerConfigureModal = ({
  onSubmitSettled,
  setConfigureOption,
  configureOption,
  isSubmitting,
}: TRunnerConfigureModalProps) => {
  const dispatch = useAppDispatch();
  const {
    configureRunnerModal: { isOpen, values },
  } = useAppSelector((state) => state.raceDetails);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => dispatch(setConfigureRunnerModal({ isOpen: false }))}
      size="xl"
      scrollBehavior="inside"
      trapFocus={true}
      returnFocusOnClose={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalHeader>
          <FormattedMessage id="trademanagerpage.racedetails.modal.runnerconfig.header" />
        </ModalHeader>
        <ModalBody>
          <RadioGroup
            value={configureOption}
            onChange={(option: ERunnerConfigureOption) =>
              setConfigureOption(option)
            }
          >
            <VStack align="flex-start" spacing={2}>
              <Radio value={ERunnerConfigureOption.Resettle}>
                <FormattedMessage id="trademanagerpage.racedetails.modal.runnerconfig.resettle" />
              </Radio>

              <Radio value={ERunnerConfigureOption.DisplayOnly}>
                <FormattedMessage id="trademanagerpage.racedetails.modal.runnerconfig.displayonly" />
              </Radio>
            </VStack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter alignItems="flex-end">
          <Button
            variant="outline-rounded"
            borderRadius="lg"
            onClick={() => dispatch(setConfigureRunnerModal({ isOpen: false }))}
            disabled={isSubmitting}
          >
            <FormattedMessage id="generic.cancel" />
          </Button>

          <Button
            borderRadius="lg"
            variant="rounded"
            ml="4"
            onClick={() => onSubmitSettled(values)}
            isLoading={isSubmitting}
          >
            <FormattedMessage id="generic.update" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RunnerConfigureModal;
