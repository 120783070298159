import React from 'react';
import Form from './components/Form';
import RunnerConfigureModal from './components/RunnerConfigureModal/RunnerConfigureModal';
import { FormikProvider } from 'formik';
import { useRaceTableForm } from './services/RaceDetailsTable.hooks';
import { TRaceDetails, TRunnerResult, TMarket } from '@/lib/DBModels';
import { ERaceMarketType } from '@/features/tradeManager/pages/RaceDetails/Services/RaceDetails.types';

type TRaceDetailsTable = {
  raceData: TRaceDetails;
  raceResults?: TRunnerResult[];
  raceMarkets?: TMarket[];
  priceType?: ERaceMarketType;
};

export default function RaceDetailsTable({
  raceData,
  raceResults,
  raceMarkets,
  priceType,
}: TRaceDetailsTable) {
  const {
    formik,
    data,
    onSubmitSettled,
    setConfigureOption,
    configureOption,
    isManuallyManaged,
    isSubmitting,
    onClickRow,
    apiModel,
    priceModifierState,
    priceModifiersDispatch,
    isUpdateButtonEnabled,
    setIsUpdateButtonEnabled,
  } = useRaceTableForm(raceData, raceResults);

  return (
    <FormikProvider value={formik}>
      <RunnerConfigureModal
        onSubmitSettled={onSubmitSettled}
        setConfigureOption={setConfigureOption}
        configureOption={configureOption}
        isSubmitting={isSubmitting}
      />
      <Form
        formik={formik}
        data={data}
        raceMarkets={raceMarkets}
        priceModifierState={priceModifierState}
        priceModifiersDispatch={priceModifiersDispatch}
        isManuallyManaged={isManuallyManaged}
        onClickRow={onClickRow}
        apiModel={apiModel}
        isUpdateButtonEnabled={isUpdateButtonEnabled}
        setIsUpdateButtonEnabled={setIsUpdateButtonEnabled}
        priceType={priceType}
      />
    </FormikProvider>
  );
}
