import React, { FC, ReactElement } from 'react';
import {
  Badge,
  Box,
  Center,
  HStack,
  Icon,
  SystemStyleObject,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import StatusBadge from '../../StatusBadge';
import { EGeneralStatus } from '../../../../lib/DBModels';
import { useStartTimeCalculator } from '../Services/Hooks.StartTimeCalculator';
import { Cancel } from '@styled-icons/typicons/Cancel';
import dayjs from 'dayjs';

interface StartTimeCalculatorProps {
  date?: string;
  status?: EGeneralStatus;
  sx?: SystemStyleObject;
  withStatus?: boolean;
  isManualOverride?: boolean;
  isSuspended?: boolean;
  isProtestOverride?: boolean;
}

export const StartTimeCalculator: FC<StartTimeCalculatorProps> = ({
  date,
  status,
  withStatus = false,
  isManualOverride = false,
  isSuspended = false,
  isProtestOverride = false,
  sx,
}): ReactElement | null => {
  const { countdownTimeLeft, countdownLabel, startsIn15Mins, countdownShow } =
    useStartTimeCalculator(date);

  const showCountdownBadge = date && EGeneralStatus.Open && countdownShow;

  return (
    <Box position="relative">
      {status && withStatus && (
        <StatusBadge
          status={status}
          isManualOverride={isManualOverride}
          isSuspended={isSuspended}
          size="lg"
          sx={{
            lineHeight: 'inherit',
            fontSize: 'xs',
          }}
        />
      )}

      <HStack justifyContent="flex-start">
        {isProtestOverride && (
          <Badge
            textTransform="none"
            fontWeight="500"
            color="white"
            height={5}
            lineHeight={5}
            px="1"
            textAlign="center"
            background="gray.500"
            borderRadius="md"
            fontSize="xs"
          >
            <Icon as={Cancel} mr="0.5" />
            <FormattedMessage id="trademanagerpage.config.protest" />
          </Badge>
        )}
        {showCountdownBadge ? (
          <Center sx={sx}>
            <Badge
              textTransform="none"
              fontWeight="500"
              color={
                countdownTimeLeft > 0 && startsIn15Mins ? 'white' : 'gray.600'
              }
              height={5}
              lineHeight={5}
              px={3}
              textAlign="center"
              background={
                countdownTimeLeft > 0 && startsIn15Mins ? 'red.600' : 'gray.200'
              }
              borderRadius="md"
              fontSize="xs"
              minW="14"
            >
              {countdownLabel}
            </Badge>
          </Center>
        ) : (
          <Badge fontWeight="400" px="2">
            {dayjs(date).format('DD MMM YYYY HH:mm A')}
          </Badge>
        )}
      </HStack>
    </Box>
  );
};
