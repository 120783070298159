import React from 'react';
import { Box } from 'grommet';
import { getStrings } from '../../../../../../../common/utils';
import RaceDetailsTable from './components/RaceDetailsTable/RaceDetailsTable';
import RaceResultsTable from './components/RaceResultsTable';
import { RaceDetailsHeading } from '../../../RaceDetailsPage.styles';
import { TRaceDetails, TRunnerResult, TMarket } from '@/lib/DBModels';
import { useSelector } from 'react-redux';
import { AppState } from '@/app/types';
import { LoadingSpinner } from '@/common/components';

type TMarketWinPlace = {
  isLoading: boolean;
  raceData: TRaceDetails;
  raceResults?: TRunnerResult[];
  raceMarkets?: TMarket[];
};

export const MarketWinPlace = ({
  isLoading,
  raceData,
  raceResults,
  raceMarkets,
}: TMarketWinPlace) => {
  const [
    {
      TradeManagerPage: { Common },
    },
  ] = getStrings();

  const raceResultsLoading = useSelector(
    (state: AppState) => state.raceDetails.raceResultsLoading
  );

  const hasRaceResults = !!raceResults?.length;

  return (
    <>
      {hasRaceResults && (
        <>
          <Box
            margin={{ bottom: '10px', top: '30px', horizontal: '20px' }}
            gap="10px"
            align="center"
            direction="row"
            justify="between"
          >
            <RaceDetailsHeading>{Common.RaceResults}</RaceDetailsHeading>
          </Box>
          <Box>
            <RaceResultsTable
              results={raceResults}
              resultsLoading={raceResultsLoading}
            />
          </Box>
        </>
      )}
      <Box
        margin={{ bottom: '10px', top: '30px', horizontal: '20px' }}
        gap="10px"
        align="center"
        direction="row"
        justify="between"
      >
        <RaceDetailsHeading>{Common.RaceForm}</RaceDetailsHeading>
      </Box>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <RaceDetailsTable
          raceData={raceData}
          raceResults={raceResults}
          raceMarkets={raceMarkets}
        />
      )}
    </>
  );
};
