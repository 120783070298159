import { TRaceDetailsFormValues } from '../RaceDetailsTable.types';
import { TRunnerResult } from '@/lib/DBModels';
import { EPositions } from '@/api/tradeManager/raceDetails/settleRace/settleRace.types';

export const parseResettlementData = ({
  raceId,
  values,
  raceResults,
}: {
  raceId: string;
  values: TRaceDetailsFormValues;
  raceResults?: TRunnerResult[];
}) => {
  if (!raceResults) return;

  const scrachedRunnerNumbers = Object.values(values.runners)
    .filter((r) => r.isScratched)
    .map((r) => r.race_runner_external_ref.toString());

  const nameSpace: Record<number | string, EPositions> = {
    1: EPositions.FirstPlaces,
    2: EPositions.SecondPlaces,
    3: EPositions.ThirdPlaces,
    4: EPositions.ForthPlaces,
  };

  const positions = raceResults.reduce<Record<EPositions, string[]>>(
    (acc, cur) => {
      if (!cur.result_place) return acc;

      const position = cur.result_place;

      const key = nameSpace[position];

      return {
        ...acc,
        ...(!!key && {
          [key]: [...(acc[key] ?? []), cur.runner_number],
        }),
      };
    },
    {
      [EPositions.FirstPlaces]: [] as string[],
      [EPositions.SecondPlaces]: [] as string[],
      [EPositions.ThirdPlaces]: [] as string[],
      [EPositions.ForthPlaces]: [] as string[],
      [EPositions.Scratched]: scrachedRunnerNumbers as string[],
    }
  );

  return {
    race_id: raceId,
    ...positions,
  };
};
