import { useMutationRaceStatus } from '@/api/tradeManager/raceDetails/raceStatus/raceStatus.hooks';
import { useAppDispatch, useAppSelector } from '@/common/hooks/useRedux';
import { EGeneralStatus } from '@/lib/DBModels';
import { TRaceDetailsState } from '../../../../Services/RaceDetails.types';
import { TStatusFormMarket, TStatusFormValues } from '../Status.types';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { errorMessageFromHTTPException } from '@/lib/Error';
import {
  setIsConfigureRaceModalLoading,
  setIsConfigureRaceModalOpen,
} from '../../../../Services/RaceDetails.slices';
import { FEATURE_FLAGS } from '@/constants/featureFlags';

const allowedRaceStatus = [
  EGeneralStatus.Open,
  EGeneralStatus.Closed,
  EGeneralStatus.Voided,
  EGeneralStatus.Removed,
];

const getInitialValues = ({ raceData, raceMarkets }: TRaceDetailsState) => {
  const unorderedMarkets = raceMarkets.reduce<
    Record<string, TStatusFormMarket>
  >((acc, x) => {
    const marketType = x.market_type;
    const marketId = x.market_id;
    const status = x.status;

    if (marketType && marketId && status) {
      acc[marketType] = {
        id: marketId,
        name: marketType.replace('Racing ', ''),
        status: status,
        follow_feed: !x.lock_status,
        settle_by_feed: !x.ignore_winter_settlement,
        allowedStatus: allowedRaceStatus,
      };
    }

    return acc;
  }, {});

  const keys = [
    'Racing Win',
    'Racing Place',
    'Exacta',
    'Quinella',
    'Trifecta',
    'FirstFour',
  ];

  if (FEATURE_FLAGS.IS_SRM_ENABLED) {
    keys.push('SRMulti');
  }

  return {
    race: {
      id: raceData.race_id ?? '',
      status: raceData.status ?? EGeneralStatus.Open,
      follow_feed: !raceData.lock_status,
      settle_by_feed: !raceData.ignore_winter_settlement,
      allowedStatus: allowedRaceStatus,
    },
    markets: keys.map((x) => unorderedMarkets[x]).filter((x) => !!x),
  };
};

export const useStatus = () => {
  const raceDetails = useAppSelector((state) => state.raceDetails);
  const dispatch = useAppDispatch();

  const { mutateAsync } = useMutationRaceStatus();

  // eslint-disable-next-line
  const apiData: any = useMemo(() => ({}), []);

  const formik = useFormik<TStatusFormValues>({
    initialValues: getInitialValues(raceDetails),
    enableReinitialize: true,
    onSubmit: async (_, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(setIsConfigureRaceModalLoading(true));

      try {
        if (apiData.race || apiData.markets) {
          await mutateAsync({
            race: apiData.race,
            markets: Object.values(apiData.markets || {}),
          });
          toast.success('Successfully updated race');

          dispatch(setIsConfigureRaceModalOpen(false));
        }
      } catch (error) {
        toast.error(errorMessageFromHTTPException(error));
      } finally {
        setSubmitting(false);
        dispatch(setIsConfigureRaceModalLoading(false));
      }
    },
  });

  const setRaceStatus = (id: string, status: EGeneralStatus) => {
    formik.setFieldValue('race.status', status);
    apiData.race = {
      id,
      status,
      lock_settlement: !formik.values.race.settle_by_feed,
    };

    setRaceFollowFeed(id, status, false);

    formik.values.markets.forEach((x, i) => {
      setMarketStatus(i, status);
    });
  };

  const setRaceFollowFeed = (
    id: string,
    status: EGeneralStatus,
    value: boolean
  ) => {
    formik.setFieldValue('race.follow_feed', value);
    apiData.race = apiData.race || { id };
    apiData.race.lock_status = !value;

    formik.values.markets.forEach((x, i) => {
      setMarketFollowFeed(i, status, value);
    });
  };

  const setRaceSettleByFeed = (id: string, value: boolean) => {
    formik.setFieldValue('race.settle_by_feed', value);
    apiData.race = apiData.race || { id };
    apiData.race.lock_settlement = !value;

    formik.values.markets.forEach((x, i) => {
      setMarketSettleByFeed(i, value);
    });
  };

  const setMarketStatus = (idx: number, status: EGeneralStatus) => {
    const id = formik.values.markets[idx].id;
    formik.setFieldValue(`markets[${idx}].status`, status);
    apiData.markets = apiData.markets || {};
    apiData.markets[id] = {
      id,
      status: status,
      lock_settlement: !formik.values.markets[idx].settle_by_feed,
    };

    setMarketFollowFeed(idx, status, false);
  };

  const setMarketFollowFeed = (
    idx: number,
    status: EGeneralStatus,
    value: boolean
  ) => {
    const id = formik.values.markets[idx].id;
    formik.setFieldValue(`markets[${idx}].follow_feed`, value);
    apiData.markets = apiData.markets || {};
    apiData.markets[id] = apiData.markets[id] || { id };

    if (status == EGeneralStatus.Suspended) {
      delete apiData.markets[id].lock_status;
      apiData.markets[id].lock_price = !value;
    } else apiData.markets[id].lock_status = !value;
  };

  const setMarketSettleByFeed = (idx: number, value: boolean) => {
    const id = formik.values.markets[idx].id;
    formik.setFieldValue(`markets[${idx}].settle_by_feed`, value);

    apiData.markets = apiData.markets || {};
    apiData.markets[id] = apiData.markets[id] || { id };
    apiData.markets[id].lock_settlement = !value;
  };

  return {
    formik,
    apiData,
    setRaceStatus,
    setRaceFollowFeed,
    setRaceSettleByFeed,
    setMarketStatus,
    setMarketFollowFeed,
    setMarketSettleByFeed,
  };
};
