import React from 'react';
import { Box } from 'grommet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  BackButton,
  LoadingSpinner,
  TabNavLink,
} from '../../../../../../common/components';
import { queryRaceNumbers } from '@/api/tradeManager/raceDetails/raceNumbers/raceNumbers';
import { useQuery } from 'react-query';
import { keys } from '@/api/api.keys';
import { Image } from '@chakra-ui/react';
import moneyBack from '@/assets/icons/sports/money-back.svg';

export default function RaceNumbers() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const raceId = params.get('id') ?? '';

  const { data, isLoading } = useQuery([keys.raceNumbers], () =>
    queryRaceNumbers({ race_id: raceId })
  );

  return (
    <Box
      direction="row"
      pad={{ top: '28px', horizontal: '28px', bottom: '15px' }}
      gap="15px"
      align="center"
      style={{ position: 'relative', minWidth: '1250px' }}
    >
      {isLoading && <LoadingSpinner />}
      <BackButton onClick={() => navigate(-1)} />
      {data?.data && (
        <Box wrap direction="row" align="center">
          {data?.data.map((x) => (
            <TabNavLink
              to={`?id=${x.race_id}`}
              className="small no-margin-bottom"
              isActive={x.race_id === raceId}
              key={x.race_id}
            >
              {`Race ${x.race_number}`}{' '}
              {!!x.promotions.length && (
                <Image
                  sx={{ pos: 'absolute', right: 0, fill: 'red' }}
                  src={moneyBack}
                />
              )}
            </TabNavLink>
          ))}
        </Box>
      )}
    </Box>
  );
}
