import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TUpdatePropositionBody } from './updateProposition.types';

const updatePropositionURL =
  '/bookie/trade-manager/market-creation/update-proposition';

// Trade Manager - Update Proposition

export const mutateUpdateProposition = (body: TUpdatePropositionBody) =>
  api
    .post<TMarketCreationResponse>(updatePropositionURL, body)
    .then((res) => res.data);
