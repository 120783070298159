import api from '@/api/api';
import { TSGMPropositionsParams } from './propositions.types';

// SGM - Propositions
const querySGMPropositionsURL = '/bookie/trade-manager/sgmultis/propositions';
export const querySGMPropositions = (params: TSGMPropositionsParams) =>
  api
    .get<Api['SGMultisPropositionStatsResponse']>(querySGMPropositionsURL, {
      params,
    })
    .then((res) => res.data);
