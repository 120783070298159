import React from 'react';
import { TColumnConfig } from '@/common/components/TableChakra/TableChakra.types';
import { TBet } from '@/api/regulator/bets/bets.types';
import { FormattedMessage } from 'react-intl';
import {
  TextTableCell,
  TextTableHeader,
} from '@/views/Regulator/Regulator.styles';

export const useColumns = () => {
  const columns: TColumnConfig<TBet>[] = [
    {
      property: 'sequence_number',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.sequencenumber" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.sequence_number}</TextTableCell>,
    },
    {
      property: 'bookie_name',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.bookiename" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.bookie_name}</TextTableCell>,
    },
    {
      property: 'full_name',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.clientname" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.full_name}</TextTableCell>,
    },
    {
      property: 'email',
      header: <TextTableHeader>Client ID</TextTableHeader>,
      render: (data) => <TextTableCell>{data.email}</TextTableCell>,
    },
    {
      property: 'punter_location',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.punterlocation" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.punter_location}</TextTableCell>,
    },
    {
      property: 'ip_address',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.ipaddress" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.ip_address}</TextTableCell>,
    },
    {
      property: 'channel',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.channel" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.channel}</TextTableCell>,
    },
    {
      property: 'channel_name',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.channelname" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.channel_name}</TextTableCell>,
    },
    {
      property: 'bet_id',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.betid" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.bet_id}</TextTableCell>,
    },
    {
      property: 'bet_description',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.betdescription" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.bet_description}</TextTableCell>,
    },
    {
      property: 'stake',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.stake" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.stake}</TextTableCell>,
    },
    {
      property: 'odds',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.odds" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.odds}</TextTableCell>,
    },
    {
      property: 'status',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.status" />
        </TextTableHeader>
      ),
      render: (data) => (
        <TextTableCell>{data.status?.replaceAll('_', ' ')}</TextTableCell>
      ),
    },
    {
      property: 'status_time',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.statustime" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.status_time}</TextTableCell>,
    },
    {
      property: 'timezone',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.timezone" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.timezone}</TextTableCell>,
    },
  ];

  return { columns };
};
