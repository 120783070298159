import api from '../../../api';
import { TProtestOverrideBody } from './fptpProtestOverride.types';

export const protestOverrideURL =
  '/bookie/trade-manager/race-details/fptp-protest-override';

// Post: Protest Override
export const mutateProtestOverride = (body: TProtestOverrideBody) =>
  api
    .post<TProtestOverrideBody>(protestOverrideURL, body)
    .then((res) => res.data);
