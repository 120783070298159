import api from '../../api';
import { TRaceIDQuery } from './evenshot.types';

const evenshotBetsURL = '/bookie/trade-manager/even-shot';

// Trade Manager - Evenshot Bets
export const queryEvenShotBets = (params: TRaceIDQuery) =>
  api
    .get<Api['EvenShotRaceBetStats']>(evenshotBetsURL, { params })
    .then((res) => res.data);
