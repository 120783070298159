import api from '@/api/api';
import { TGetCompetitionParams, TCompetition } from './getCompetition.types';

const getCompetitionURL =
  '/bookie/trade-manager/market-creation/get-competition';

// Trade Manager - Get Competition

export const queryGetCompetition = (params: TGetCompetitionParams) =>
  api
    .get<TCompetition[]>(getCompetitionURL, { params })
    .then((res) => res.data);
