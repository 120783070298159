import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TUpdateMatchBody } from './updateMatch.types';

const updateMatchURL = '/bookie/trade-manager/market-creation/update-match';

// Trade Manager - Update Match

export const mutateUpdateMatch = (body: TUpdateMatchBody) =>
  api
    .post<TMarketCreationResponse>(updateMatchURL, body)
    .then((res) => res.data);
