import api from '../../../api';
import { TRaceResultResponse, TRaceResultParams } from './raceResult.types';

export const raceResultURL = '/bookie/trade-manager/race-details/race-result';

// Race Result
export const queryRaceResult = (params: TRaceResultParams) =>
  api
    .get<TRaceResultResponse>(raceResultURL, { params })
    .then((res) => res.data);
