/* eslint-disable camelcase */

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetRequest } from '../../../../../../lib/api/api';
import { setCompetitions, setEventsResponse } from './slices';

import { AppThunkDispatch } from '../../../../../../app/types';

import { TCompetition, TMatchQuery } from './types';
import { TSport, TEventsResponse } from '../../types';

export const getCompetitionsTournamentsForSport = createAsyncThunk<
  TCompetition[] | undefined,
  TSport,
  { dispatch: AppThunkDispatch }
>(
  'sports/getCompetitionsTournamentsForSport',
  async (sport, thunkAPI): Promise<TCompetition[] | undefined> => {
    try {
      const competitionsTournaments = await apiGetRequest<TCompetition[]>(
        'bookie/trade-manager/by-sport/competitions-tournaments-for-sport',
        {
          sport_id: sport.sport_id,
        }
      );
      thunkAPI.dispatch(setCompetitions(competitionsTournaments));
      return competitionsTournaments;
    } catch (error) {
      return undefined;
    }
  }
);

export const getMatches = createAsyncThunk<
  Promise<TEventsResponse | undefined>,
  TMatchQuery,
  { dispatch: AppThunkDispatch }
>(
  'sports/getMatches',
  async (matchQuery, thunkAPI): Promise<TEventsResponse | undefined> => {
    try {
      const matchesData = await apiGetRequest<TEventsResponse>(
        'bookie/trade-manager/by-sport',
        {
          sport_id: matchQuery.sport_id,
          competition_id: matchQuery.competition_id,
          tournament_id: matchQuery.tournament_id,
          resulted: matchQuery.resulted,
          period_start: matchQuery.period_start,
          period_duration: matchQuery.period_duration,
          sort_by: matchQuery.sort_by,
          sort_direction: matchQuery.sort_direction,
          offset: matchQuery.offset,
          limit: matchQuery.limit,
        }
      );
      thunkAPI.dispatch(setEventsResponse(matchesData));
      return matchesData;
    } catch (error) {
      return undefined;
    }
  }
);
