import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useEffect, useState } from 'react';
import { useFormik, FormikHelpers, FormikErrors } from 'formik';
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  indexedDBLocalPersistence,
} from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { Heading, Paragraph } from 'grommet';

import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { LoadingSpinner } from '../../../../common/components';

import { useAuthContext } from '../../../../lib/firebase/AuthProvider';
import { recordUserAction } from '../../../settings/pages/Settings/actions';

import { FormContainer, ForgotPasswordLink, StyledInput } from './Styles';

import loginSchema, { LoginSchema } from './validators';
import { AppThunkDispatch } from '../../../../app/types';
import {
  BOOKIE_ACTIVITY_LOG_TYPE,
  ACTIVITY_LOG_SUMMARY,
} from '../../../../lib/Constants';
import { useNewRelic } from '../../../../common/hooks/useNewRelic';
import { ENewRelicActions } from '../../../../constants/newRelicActions';
import { keys } from '../../../../api/api.keys';
import { useQueryAuthToken } from '@/api/auth/authentication.hooks';

export const Login: FC = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const dispatch: AppThunkDispatch = useDispatch();
  const { currentUser } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const newRelicLog = useNewRelic();
  const client = useQueryClient();
  const query = useQueryAuthToken();

  useEffect(() => {
    if (currentUser) navigate('/dashboard');
  }, [currentUser, navigate]);

  const handleFormSubmit = async (
    email: string,
    password: string,
    { setErrors }: FormikHelpers<LoginSchema>,
    errors: FormikErrors<LoginSchema>
  ) => {
    setLoading(true);
    await setPersistence(auth, indexedDBLocalPersistence).then(() =>
      signInWithEmailAndPassword(auth, email, password)
        .catch((error) => {
          newRelicLog(ENewRelicActions.Login, { error: JSON.stringify(error) });
          setLoading(false);
          if (
            error.code === 'auth/user-not-found' ||
            error.code === 'auth/wrong-password'
          ) {
            setErrors({
              ...errors,
              email: intl.formatMessage({ id: 'loginpage.errors.generic' }),
              password: intl.formatMessage({ id: 'loginpage.errors.generic' }),
            });
          }
        })
        .then(async (user) => {
          await client.invalidateQueries([keys.firebaseAuth]);

          if (user) {
            // TODO: create const with action summaries
            // Will do this in separate PR along with updating separation between punter profile and admin platform activity logging
            await dispatch(
              recordUserAction({
                action_type: BOOKIE_ACTIVITY_LOG_TYPE.LOGIN,
                action_summary:
                  ACTIVITY_LOG_SUMMARY[BOOKIE_ACTIVITY_LOG_TYPE.LOGIN],
              })
            );
            query.refetch();

            newRelicLog(ENewRelicActions.Login, {
              login: true,
              bookie: user.user.email,
            });
          }
        })
    );
  };

  const { values, handleSubmit, handleChange, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validateOnChange: false,
      validateOnBlur: true,
      validationSchema: loginSchema,
      onSubmit: async ({ email, password }, formHelpers) => {
        await handleFormSubmit(email, password, formHelpers, errors);
      },
    });

  return (
    <>
      <Heading
        margin={{
          top: '0',
          horizontal: '0',
          bottom: '8px',
        }}
      >
        <FormattedMessage id="loginpage.header" />
      </Heading>

      <Paragraph
        margin={{
          top: '0',
          horizontal: '0',
          bottom: '55px',
        }}
      >
        <FormattedMessage id="loginpage.subheader" />
      </Paragraph>

      <FormContainer>
        {loading && <LoadingSpinner />}
        <form onSubmit={handleSubmit}>
          <StyledInput
            id="email"
            name="email"
            type="text"
            labelText="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={!!errors.email && touched.email}
            errorText={touched.email ? errors.email : ''}
            dataCy="loginEmail"
            border
          />
          <StyledInput
            id="password"
            name="password"
            type="password"
            labelText="Password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={!!errors.password && touched.password}
            errorText={touched.password ? errors.password : ''}
            dataCy="loginPassword"
            border
          />

          <ForgotPasswordLink to="/forgot-password">
            <FormattedMessage id="loginpage.forgotpassword" />
          </ForgotPasswordLink>

          <Button
            type="submit"
            data-cy="loginSubmit"
            size="lg"
            sx={{
              w: 'full',
              borderRadius: 'lg',
              bgGradient: 'linear(to-r, brand.300, brand.600)',
              '&:active, &:hover': {
                bgGradient: 'linear(to-r, brand.300, brand.600)',
              },
            }}
          >
            <FormattedMessage id="generic.submit" />
          </Button>
        </form>
      </FormContainer>
    </>
  );
};
