import React, { useEffect, useState } from 'react';
import { Card, CardBody, Grid, Box, Text, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { useQuery } from 'react-query';
import {
  formatDashboardQueryParams,
  normalizePayload,
  normalizeTransactionResponse,
} from './utils/utils';
import {
  getDashboardData,
  queryPunterStats,
  queryTransactionStats,
} from './api/api';
import Datepicker from '@/common/components/FormElements/Datepicker/Datepicker';
import {
  BET_TYPES_SELECT_LIST,
  CODE_SELECT_LIST,
  PUNTER_LIST_SELECT_LIST_V2,
  RACING_TYPE_SELECT_LIST,
  REGION_SELECT_LIST,
} from './constants';
import { RealMoneyAndBonusBets } from './components/RealMoneyAndBonusBets';
import { PunterCategoryStats } from './components/PunterCategoryStats';
import { MultiSelect } from 'react-multi-select-component';
import { TransactionStats } from './components/TransactionsStats';
import { querySportList } from '@/features/tradeManager/pages/TradeManager/actions';

export type TDashboardFilterStates = {
  startTimestamp: string;
  endTimestamp: string;
  punterCategory?: string[];
  betType: string[];
  eventType: string[];
  raceType: string[];
  region: string[];
  code: string[];
  sportId: string[];
};

export function Dashboard() {
  const [filterStates, setFilterStates] = useState<TDashboardFilterStates>({
    startTimestamp: dayjs(Date.now()).format('YYYY-MM-DD'),
    endTimestamp: dayjs(Date.now()).format('YYYY-MM-DD'),
    punterCategory: [],
    betType: [],
    eventType: [],
    raceType: [],
    region: [],
    code: [],
    sportId: [],
  });

  const { data: sportList } = useQuery(['sportList'], async () =>
    querySportList()
  );

  const { data, refetch } = useQuery(
    ['dashboard/bet-stats/', filterStates],
    async () => {
      const queryParams = formatDashboardQueryParams(filterStates, true);
      const payload = (await getDashboardData(queryParams)).data;

      return normalizePayload(payload);
    }
  );

  const { data: punterStats, isLoading: isPunterStatsLoading } = useQuery(
    ['dashboard.punter.stats', filterStates],
    async () => {
      const queryParams = formatDashboardQueryParams(filterStates, false);

      return queryPunterStats(queryParams);
    },
    { enabled: true, refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const { data: transactionStats, isLoading: isTransactionStatsLoading } =
    useQuery(
      ['dashboard.transaction.stats', filterStates],
      async () => {
        const queryParams = formatDashboardQueryParams(filterStates, true);
        const payload = (await getDashboardData(queryParams)).data;
        const stats = (await queryPunterStats(queryParams)).data;

        const sum = stats?.PunterCategoryStats.reduce(
          (acc, item) => acc + item.stats.value,
          0
        );

        const response = (await queryTransactionStats(queryParams)).data;

        const momo = {
          ...response,
          new_punters: sum ?? 0,
          average_bet_stake: {
            value: payload.real_money.average_bet_stake.value,
            percentage_change:
              payload.real_money.average_bet_stake.percentage_change,
          },
        };

        return normalizeTransactionResponse(momo);
      },
      { enabled: true, refetchOnMount: false, refetchOnWindowFocus: false }
    );

  // Sort the data alphabetically by category
  const sortedPunterStats = punterStats?.data.PunterCategoryStats
    ? [...punterStats?.data.PunterCategoryStats].sort((a, b) =>
        a.category.localeCompare(b.category)
      )
    : undefined;

  useEffect(() => {
    refetch();
  }, [filterStates, refetch]);

  const shouldShowRacingTypeFilter = filterStates.eventType.includes('Racing');
  const shouldShowSportTypeFilter = filterStates.eventType.includes('Sport');

  const numColumns =
    shouldShowSportTypeFilter && shouldShowRacingTypeFilter
      ? 8
      : shouldShowSportTypeFilter || shouldShowRacingTypeFilter
      ? 7
      : 6;

  return (
    <main>
      <Flex direction="column" gap="3">
        <Card
          w={'full'}
          boxShadow={
            '0px 10px 20px 0px #000001, 0px 0px 5px 1px #094C8B inset,0px 0px 0px 1px #36C4FF inset'
          }
          bg="transparent"
          borderRadius="5px"
        >
          <CardBody p={'12px 16px 16px'}>
            <Grid templateColumns={`repeat(${numColumns}, 1fr)`} gap={2}>
              <Box>
                <Text color="white">Start date</Text>
                <Datepicker
                  value={dayjs(filterStates.startTimestamp).toDate()}
                  onChange={(e) => {
                    if (!e) return;
                    setFilterStates((prev) => ({
                      ...prev,
                      startTimestamp: dayjs(e.toString()).format('YYYY-MM-DD'),
                    }));
                  }}
                  wrapperProps={{
                    h: '10',
                    fontSize: 'sm',
                  }}
                />
              </Box>
              <Box>
                <Text color="white">End date</Text>
                <Datepicker
                  value={dayjs(filterStates.endTimestamp).toDate()}
                  onChange={(e) => {
                    if (!e) return;
                    setFilterStates((prev) => ({
                      ...prev,
                      endTimestamp: dayjs(e.toString()).format('YYYY-MM-DD'),
                    }));
                  }}
                  wrapperProps={{
                    h: '10',
                    fontSize: 'sm',
                  }}
                />
              </Box>
              <Box maxW={255}>
                <Text color="white">Punter Categories</Text>
                <MultiSelect
                  options={PUNTER_LIST_SELECT_LIST_V2}
                  value={filterStates.punterCategory.map((category) => ({
                    value: category,
                    label: category,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setFilterStates({
                      ...filterStates,
                      punterCategory: selectedValues,
                    });
                  }}
                  labelledBy="Punter Categories"
                />
              </Box>
              <Box maxW={255}>
                <Text color="white">Region</Text>
                <MultiSelect
                  options={REGION_SELECT_LIST}
                  value={filterStates.region.map((category) => ({
                    value: category,
                    label: category,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setFilterStates({
                      ...filterStates,
                      region: selectedValues,
                    });
                  }}
                  labelledBy="Region"
                />
              </Box>

              <Box maxW={255}>
                <Text color="white">Bet Type</Text>
                <MultiSelect
                  options={BET_TYPES_SELECT_LIST}
                  value={filterStates.betType.map((category) => ({
                    value: category,
                    label: category,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setFilterStates({
                      ...filterStates,
                      betType: selectedValues,
                    });
                  }}
                  labelledBy="Bet Type"
                />
              </Box>

              <Box>
                <Text color="white">Event Type</Text>
                <MultiSelect
                  options={CODE_SELECT_LIST}
                  value={filterStates.eventType.map((category) => ({
                    value: category,
                    label: category,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setFilterStates({
                      ...filterStates,
                      eventType: selectedValues,
                    });
                  }}
                  labelledBy="Type"
                />
              </Box>

              {shouldShowRacingTypeFilter && (
                <Box>
                  <Text color="white">Race Type</Text>
                  <MultiSelect
                    options={RACING_TYPE_SELECT_LIST}
                    value={filterStates.raceType.map((category) => ({
                      value: category,
                      label: category.replaceAll('_', ' '),
                    }))}
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map(
                        (option) => option.value
                      );
                      setFilterStates({
                        ...filterStates,
                        raceType: selectedValues,
                      });
                    }}
                    labelledBy="Type"
                  />
                </Box>
              )}

              {shouldShowSportTypeFilter && (
                <Box maxW="300px">
                  <Text color="white">Sport ID</Text>

                  <MultiSelect
                    options={sportList?.map((sport) => ({
                      label: sport.display_name,
                      value: sport.sport_id,
                    }))}
                    value={filterStates.sportId.map((category) => ({
                      value: category,
                      label:
                        sportList?.find((sport) => sport.sport_id === category)
                          ?.display_name || category,
                    }))}
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map(
                        (option) => option.value
                      );
                      setFilterStates({
                        ...filterStates,
                        sportId: selectedValues,
                      });
                    }}
                    labelledBy="Type"
                  />
                </Box>
              )}
            </Grid>
          </CardBody>
        </Card>

        <TransactionStats
          isLoading={isTransactionStatsLoading}
          transactions={transactionStats}
        />

        <PunterCategoryStats
          stats={sortedPunterStats}
          isLoading={isPunterStatsLoading}
        />
        {data && (
          <RealMoneyAndBonusBets
            realMoney={data.realMoney}
            bonusBet={data.bonusBets}
          />
        )}
      </Flex>
    </main>
  );
}
