import { getURLBasedOnLocation } from '@/common/utils';
import api from '../../api';
import { TAddPromoParams, TUploadMediaParams } from './promotions.types';

export const addPromotionURL = 'bookie/promotions/state';

// update the banner json
export const mutateAddPromotion = (body?: TAddPromoParams) =>
  api.put<TAddPromoParams>(addPromotionURL, body).then((res) => res.data);

// add images & get public url
export const mutateMedia = (body?: TUploadMediaParams) =>
  api
    .put<TUploadMediaParams>(`bookie/promotions/images`, body)
    .then((res) => res.data);

export const queryPromotions = async (platform: string) => {
  const response = await fetch(
    `https://storage.googleapis.com/assets.${getURLBasedOnLocation(
      platform
    )}/promotions/state.json?time=${new Date().getTime()}`
  );
  const data = await response.json();
  return data;
};
