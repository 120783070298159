import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TAddMarketBody } from './addMarket.types';

const addMarketURL = '/bookie/trade-manager/market-creation/add-market';

// Trade Manager - Add Market

export const mutateAddMarket = (body: TAddMarketBody) =>
  api.post<TMarketCreationResponse>(addMarketURL, body).then((res) => res.data);
