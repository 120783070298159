export const INITIAL_FORM_VALUES = {
  code: undefined,
  competition: undefined,
  match: '',
  date: new Date(),
  time: new Date(),
  market_type: '',
  market_group_name: '',
  market_name: '',
  max_bet: 0,
  allow_multis: false,
  propositions: [{ id: '', name: '', odds: 1.01, tickIndex: 0 }],
};
