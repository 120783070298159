import api from '@/api/api';
import { TSGMBetsParams, TSGMPunterCombinations } from './combination.types';

// SGM - Bets
const querySGMCombinationsURL = '/bookie/trade-manager/sgmultis/combinations';
export const querySGMCombinations = (params: TSGMBetsParams) =>
  api
    .get<Api['SGMultisCombinationStatsResponse']>(querySGMCombinationsURL, {
      params,
    })
    .then((res) => res.data);

// SGM - Punters
const querySGMCombinationPuntersURL =
  '/bookie/trade-manager/sgmultis/combination-punters';
export const querySGMCombinationPunters = (params: TSGMPunterCombinations) =>
  api
    .get<Api['SEMultisCombinationPunterStatsResponse']>(
      querySGMCombinationPuntersURL,
      {
        params,
      }
    )
    .then((res) => res.data);
