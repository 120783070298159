import React, { useState } from 'react';
import { Text, Center, TextProps, Badge } from '@chakra-ui/react';
import { Refund } from '@styled-icons/remix-fill';
import { FormattedMessage } from 'react-intl';
import { useLocation, Link, Path } from 'react-router-dom';
import { EBetStatus } from '../../../../../lib/DBModels';
import {
  betReturnCalculator,
  centsToDollars,
  getStrings,
} from '../../../../../common/utils';
import { useVoidBetModal } from '../../../../bets/modals/VoidBetModal/Services/Services.VoidBet';
import BetStatusBadge from '../../../../../common/components/BetStatusBadge';
import BetsTablePopover from '../../../../../common/components/BetsTablePopover';
import ExoticBetSelection from '../../../../../common/components/ExoticsBetSelection';
import { TColumnConfig } from '../../../../../common/components/TableChakra/TableChakra.types';
import { TMarketBet } from '@/api/tradeManager/trademanager.types';
import { useQueryBetsForExotics } from '@/api/tradeManager/raceDetails/betsForExotics/betsForExotics.hooks';
import { useQueryBetsForRunner } from '@/api/tradeManager/raceDetails/betsForRunner/betsForRunner.hooks';
import { ERaceMarketType } from '@/features/tradeManager/pages/RaceDetails/Services/RaceDetails.types';
import { OddsBoostTooltip } from '@/features/bets/components/OddsBoostTooltip';

/* This hook handles fetching the TMarketBet data for either a race or match */
/* We store this in redux */
export const useData = (
  type: 'race' | 'match',
  raceId?: string,
  raceRunnerId?: string,
  priceType?: ERaceMarketType
) => {
  const [offset, setOffset] = useState(0);
  const limit = 20;
  const isExotics = !!(type === 'race' && raceId);

  const {
    data: exoticsBets,
    isLoading,
    refetch: refetchExotics,
  } = useQueryBetsForExotics({
    params: { race_id: raceId, offset, limit },
    options: {
      refetchInterval: !offset && isExotics ? 60 * 1000 : 0, // polling every minute on first page
      enabled: isExotics,
    },
  });

  const {
    bets,
    next,
    previous,
    previousDisabled,
    nextDisabled,
    isLoading: runnerBetsLoading,
    hidePagination,
  } = useRunnerData({ raceRunnerId, limit, priceType });

  // is last data is only used in exotic bets
  const isLastData = (exoticsBets?.length ?? 0) < limit;

  return {
    bets,
    exoticsBets,
    loading: runnerBetsLoading || isLoading,
    loadData: refetchExotics,
    isExotics,
    offset,
    setOffset,
    isLastData,
    next,
    previous,
    previousDisabled,
    nextDisabled,
    hidePagination,
  };
};

type UserRunnerDataArgs = {
  raceRunnerId?: string;
  limit: number;
  priceType?: ERaceMarketType;
};

function useRunnerData({ raceRunnerId, limit, priceType }: UserRunnerDataArgs) {
  const [pageIndex, setPageIndex] = useState(0);
  const [offset, setOffset] = useState('');
  const [offsetHistory, setOffsetHistory] = useState<string[]>([]);
  const { data, isLoading } = useQueryBetsForRunner({
    params: {
      race_runner: raceRunnerId,
      limit,
      ...(priceType ? { price_type: priceType } : {}),
      ...(offset !== '' ? { offset } : {}),
    },
    options: {
      refetchInterval: 60_000, // 1 min refresh rate
      enabled: !!raceRunnerId,
      keepPreviousData: true,
      onSuccess(data) {
        // needs to add the new offset it its exceeds the pageIndex otherwise we update it
        if (pageIndex === offsetHistory.length - 1)
          setOffsetHistory([...offsetHistory, data.paging.next_offset]);
        else
          setOffsetHistory((prevHistory) =>
            prevHistory.map((o, i) =>
              i === pageIndex ? data.paging.next_offset : o
            )
          );
      },
    },
  });

  function next() {
    if (!data) return;
    setOffset(data.paging.next_offset);
    setPageIndex((prevIdx) => ++prevIdx);
  }

  function previous() {
    if (pageIndex === 0) return;
    setOffset(offsetHistory[pageIndex]);
    setPageIndex((prevIdx) => --prevIdx);
  }
  const hidePagination = pageIndex === 0 && data?.paging.next_offset === '';

  /**
   * if we're on the last page
   * and there isnt a token on the last data
   * we disable the next button
   */

  const nextDisabled = data?.paging.next_offset === '';

  return {
    bets: data?.items || [],
    next,
    previous,
    previousDisabled: pageIndex === 0,
    nextDisabled,
    isLoading,
    hidePagination,
  };
}

export const useColumns = () => {
  const { showVoidBetFromMarketBet } = useVoidBetModal();

  const [
    {
      BetTablePopover: BetsTablePopoverStrings,
      TradeManagerPage: { BetsForRunner: Strings },
    },
  ] = getStrings();

  const location = useLocation();

  const tableCellStyles: TextProps = {
    fontSize: 'xs',
    padding: '3',
    align: 'center',
  };

  const headerStyleProps: TextProps = {
    fontSize: 'xs',
    padding: '3',
    align: 'center',
    casing: 'uppercase',
    fontWeight: 'normal',
  };

  const columns: TColumnConfig<TMarketBet>[] = [
    {
      property: 'punter',
      header: (
        <Text {...headerStyleProps}>
          <FormattedMessage id="trademanagerpage.betsforrunner.punter" />
        </Text>
      ),
      render: (bet) => (
        <>
          <Text {...tableCellStyles} align="left">
            <Link
              to={
                {
                  pathname: `/punter/${bet?.bet_punter_id}`,
                  state: { from: location },
                } as unknown as Path
              }
            >
              {bet?.bet_punter_name ?? ''}
            </Link>

            {bet?.exotic_selection && (
              <ExoticBetSelection
                fontSize="xs"
                selection={bet?.exotic_selection}
              />
            )}
          </Text>
        </>
      ),
    },
    {
      property: 'bet_type',
      header: (
        <Text {...headerStyleProps}>
          <FormattedMessage id="trademanagerpage.betsforrunner.bet" />
        </Text>
      ),
      render: (bet) => {
        const isTote = bet?.price_type === 'tote_single_mid';
        const isEvenShot = bet?.price_type === 'even_shot';
        return (
          <Text {...tableCellStyles}>
            {bet?.proposition}
            {isTote ? ' Tote' : ''}
            {isEvenShot && (
              <Badge ml="2" mb="0.5" colorScheme="blackAlpha">
                Even Shot
              </Badge>
            )}
            {bet.price_type === 'starting' && (
              <Badge colorScheme="blue">
                <FormattedMessage id="generic.startingPriceAcronym" />
              </Badge>
            )}
          </Text>
        );
      },
    },
    {
      property: 'bet_status',
      header: (
        <Text {...headerStyleProps}>
          <FormattedMessage id="trademanagerpage.betsforrunner.betstatus" />
        </Text>
      ),
      render: (bet) => {
        const oddsBoost = bet.promos?.find(
          (promo) => promo?.token_type === 'odds-boost'
        );

        return (
          <Center padding="3" gap="1">
            <BetStatusBadge status={bet.bet_status} />
            {bet.is_bonus_bet && (
              <Badge
                variant="solid"
                bg="black"
                borderRadius="lg"
                ml="1"
                px="2"
                py="1"
              >
                {Strings.BB}
              </Badge>
            )}
            {!!oddsBoost && <OddsBoostTooltip />}
          </Center>
        );
      },
    },
    {
      property: 'odds',
      header: (
        <Text {...headerStyleProps}>
          <FormattedMessage id="trademanagerpage.betsforrunner.odds" />
        </Text>
      ),
      render: (bet) => {
        const oddsBoost = bet.promos?.find(
          (promo) => promo?.token_type === 'odds-boost'
        );
        const renderOdds = () => {
          if (bet.price_type === 'tote_single_best')
            return <Badge colorScheme="blue">BT</Badge>;
          if (
            (bet.price_type === 'starting' ||
              bet.price_type === 'tote_single_mid') &&
            bet.bet_status !== EBetStatus.Settled
          ) {
            return (
              <Badge colorScheme="blue">
                {bet.price_type === 'starting' ? 'SP' : 'MD'}
              </Badge>
            );
          }

          return `@${bet?.bet_odds?.toFixed(2) ?? ''}`;
        };

        const oddsBoostOdds = !!oddsBoost && (
          <Text as="span" opacity={0.8} textDecoration="line-through">
            @{oddsBoost.original_odds?.toFixed(2)}
          </Text>
        );

        return (
          <Text {...tableCellStyles}>
            {renderOdds()} {oddsBoostOdds}
          </Text>
        );
      },
    },
    {
      property: 'stake',
      header: (
        <Text {...headerStyleProps}>
          <FormattedMessage id="trademanagerpage.betsforrunner.stake" />
        </Text>
      ),
      render: (bet) => (
        <Text {...tableCellStyles}>{centsToDollars(bet?.bet_stake, true)}</Text>
      ),
    },
    {
      property: 'potential_return',
      header: (
        <Text {...headerStyleProps}>
          <FormattedMessage id="trademanagerpage.betsforrunner.potentialreturn" />
        </Text>
      ),
      render: (bet) => {
        const oddsBoost = bet.promos?.find(
          (promo) => promo?.token_type === 'odds-boost'
        );
        const renderPotentialReturn = () => {
          if (
            bet.price_type === 'starting' ||
            bet.price_type === 'tote_single_mid' ||
            bet.price_type === 'tote_single_best'
          ) {
            return <FormattedMessage id="generic.na" />;
          }

          if (
            bet.bet_status === EBetStatus.Voided ||
            bet.bet_status === EBetStatus.Cancelled
          ) {
            return <BetStatusBadge status={EBetStatus.Voided} />;
          }

          return centsToDollars(
            betReturnCalculator(bet?.bet_stake, bet?.bet_odds ?? 0)
          );
        };

        const oddsBoostReturn = !!oddsBoost && (
          <Text as="span" opacity={0.8} textDecoration="line-through">
            {centsToDollars(
              betReturnCalculator(bet?.bet_stake, oddsBoost.original_odds ?? 0)
            )}
          </Text>
        );

        return (
          <Text {...tableCellStyles}>
            {renderPotentialReturn()} {oddsBoostReturn}
          </Text>
        );
      },
    },
    {
      property: 'actual_return',
      header: (
        <Text {...headerStyleProps}>
          <FormattedMessage id="trademanagerpage.betsforrunner.actualreturn" />
        </Text>
      ),
      render: (bet) => {
        const oddsBoost = bet.promos?.find(
          (promo) => promo?.token_type === 'odds-boost'
        );

        const oddsBoostReturn = !!oddsBoost && bet.bet_won && (
          <Text as="span" opacity={0.8} textDecoration="line-through">
            {centsToDollars(
              betReturnCalculator(bet?.bet_stake, oddsBoost.original_odds ?? 0)
            )}
          </Text>
        );

        return (
          <Text {...tableCellStyles}>
            {centsToDollars(bet?.actual_return ?? 0, true)} {oddsBoostReturn}
          </Text>
        );
      },
    },
    {
      property: '',
      size: '1',
      render: (datum) => (
        <BetsTablePopover
          rows={[
            {
              onClick: () => showVoidBetFromMarketBet(datum),
              title: BetsTablePopoverStrings.Void,
              disabled:
                datum?.bet_status === EBetStatus.Voided ||
                datum?.bet_status === EBetStatus.Cancelled,
              icon: Refund,
            },
          ]}
        />
      ),
    },
  ];

  return { columns };
};
