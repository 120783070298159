import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
} from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const ExampleCSVBonusBetTable = () => {
  return (
    <TableContainer>
      <Table size="sm">
        <VisuallyHidden as={TableCaption}>
          <FormattedMessage id="promomanagerpage.examplecsvtablecaption" />
        </VisuallyHidden>
        <Thead>
          <Tr>
            <Th textTransform="none">punter_id</Th>
            <Th textTransform="none" isNumeric>
              amount
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>7f2bfa01&hellip;</Td>
            <Td isNumeric>100</Td>
          </Tr>
          <Tr>
            <Td>330a6c89&hellip;</Td>
            <Td isNumeric>200</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
