import React from 'react';
import { TPunterCategory } from '../api/api';
import { Box, Flex, Text, Image, Skeleton } from '@chakra-ui/react';

import Chart from 'react-apexcharts';
import { EPlayerType } from '@/lib/DBModels';
import { Motion } from '@/common/components';
import { generateDonutChartData, getPlayerIcon } from './config';
import { PercentageBadge } from './PercentageBadge';

interface IPunterCategoryStatsProps {
  stats?: TPunterCategory[];
  isLoading: boolean;
}
export function PunterCategoryStats({
  stats,
  isLoading,
}: IPunterCategoryStatsProps) {
  if (isLoading)
    return (
      <Box padding="6" h="400px" boxShadow="lg" bg="blackAlpha.600">
        <Skeleton />
      </Box>
    );

  if (!stats && !isLoading) return null;

  const { chartData, donutOptions } = generateDonutChartData(stats ?? []);

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow:
          '0px 10px 20px 0px #000000, 0px 0px 5px 1px #094C8B inset, 0px 0px 0px 1px #36C4FF inset',
        span: { color: 'white !important' },
        '.apexcharts-legend': { justifyContent: 'center !important' },
      }}
    >
      <Chart
        options={donutOptions}
        series={chartData.series}
        type="donut"
        width="500"
        height="300"
      />
      <Flex
        w="60%"
        gap="2"
        ml="8"
        flexWrap="wrap"
        mt="8"
        alignItems="flex-start"
      >
        {stats?.map((stat, i) => (
          <Flex
            key={stat.category}
            sx={{ px: '3', py: '2', color: 'white', flex: '1 0 20%' }}
          >
            <Motion
              w="100%"
              initial={{ translateY: -40, opacity: 0 }}
              animate={{ translateY: 0, opacity: 1 }}
              transition={{
                duration: 0.3,
                ...(i < 20 && { delay: i * 0.2 }),
              }}
            >
              <Flex flexDir="row" gap="2">
                <Image
                  src={getPlayerIcon(
                    (stat.category as EPlayerType) ?? EPlayerType.Newbie
                  )}
                  sx={{
                    boxSize: '14',
                    p: '1',
                  }}
                />
                <Box>
                  <Text
                    sx={{
                      fontWeight: '500',
                      fontSize: '16px',
                      mb: '1.5',
                      color: '#ccc',
                    }}
                  >
                    {stat.category}
                  </Text>
                  {stat.stats.value === 0 ? (
                    <Text>0</Text>
                  ) : (
                    <Flex alignItems="center">
                      <Text fontWeight="bold">{stat.stats.value}</Text>

                      {stat.stats.percentage_change !== 0 && (
                        <PercentageBadge
                          value={stat.stats.percentage_change}
                          sx={{ ml: '2' }}
                        />
                      )}
                    </Flex>
                  )}
                </Box>
              </Flex>
            </Motion>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
