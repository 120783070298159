import React, { FC, useState, useEffect, useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import { Path, useLocation, useNavigate } from 'react-router-dom';
import { Column } from '@tanstack/react-table';
import { TEvent } from '../../types';
import { getStrings, logError } from '../../../../../../common/utils';
import { Separator, BetsTable } from '../../../../../../common/components';
import { useColumns } from './NextToJump.hooks';
import { TypeSelector } from './NextToJump.styles';

import { getNextToJumpEvents } from './api';

export const NextToJump: FC = () => {
  const eventTypeOptions = ['All', 'Race', 'Match'];
  const [events, setEvents] = useState<TEvent[]>();
  const [latestEventTime, setLatestEventTime] = useState<string>();
  const [selectedEventType, setSelectedEventType] = useState(
    eventTypeOptions[0]
  );
  const [loadingEvents, setLoadingEvents] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [
    {
      TradeManagerPage: { Common },
    },
  ] = getStrings();

  const columns = useColumns();

  const handleScroll = useCallback(() => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;
    if (!bottom) return;
    if (!selectedEventType) return;
    (async () => {
      setLoadingEvents(true);
      const eventData = await getNextToJumpEvents(
        selectedEventType,
        latestEventTime
      );
      if (!eventData || eventData.length === 0) {
        setLoadingEvents(false);
        if (events && events?.length >= 0) {
          setLatestEventTime(
            events[events.length - 1]?.event_start_time || undefined
          );
        }
        return;
      }

      // set latest event time for paginated results if scrolled to bottom
      const latestEventStart =
        eventData[eventData.length - 1]?.event_start_time || undefined;
      setLatestEventTime(latestEventStart);

      const newEvents = events ? [...events, ...eventData] : eventData;
      setEvents(newEvents);
      setLoadingEvents(false);
    })().catch(logError);
  }, [selectedEventType, latestEventTime, events]);

  // Setup onScroll listener 1 time, cleanup in return
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    (async () => {
      setLoadingEvents(true);

      const eventData = await getNextToJumpEvents(selectedEventType, undefined);
      const latestEventStart =
        eventData[eventData.length - 1]?.event_start_time || undefined;
      // set latest event time for paginated results if scrolled to bottom
      setLatestEventTime(latestEventStart);

      setEvents(eventData);

      setLoadingEvents(false);
    })().catch(logError);
  }, [selectedEventType]);

  useEffect(() => {
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const goToDetails = (e: TEvent) => {
    navigate({
      pathname: `/trade-manager/${e?.event_type?.toLowerCase()}-details`,
      search: `?id=${e.event_id}`,
      state: { from: location },
    } as unknown as Path);
  };

  // FIXME: Move below to the api layer
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const interval = setInterval(async () => {
      const eventData = await getNextToJumpEvents(selectedEventType, undefined);
      const latestEventStart =
        eventData[eventData.length - 1]?.event_start_time || undefined;
      setLatestEventTime(latestEventStart);

      setEvents(eventData);
    }, 10000); // re-poll every 10 secs
    return () => clearInterval(interval);
  }, [selectedEventType]);

  return (
    <>
      <Flex p="3" maxW="175px">
        <TypeSelector
          name="eventType"
          placeholder={Common.SelectEvent}
          isFormik={false}
          onChange={(e) => setSelectedEventType(e.target.value)}
        >
          {eventTypeOptions.map((el) => (
            <option value={el} key={el}>
              {el}
            </option>
          ))}
        </TypeSelector>
      </Flex>
      <Separator />
      {!!events && (
        <BetsTable
          columns={columns as unknown as Column<TEvent>[]}
          data={events as unknown as TEvent[]}
          loading={loadingEvents}
          onClickRow={(row: TEvent) => goToDetails(row)}
        />
      )}
    </>
  );
};
