import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TUpdateSportBody } from './updateSport.types';

const updateSportURL = '/bookie/trade-manager/market-creation/update-sport';

// Trade Manager - Update Sport

export const mutateUpdateSport = (body: TUpdateSportBody) =>
  api
    .post<TMarketCreationResponse>(updateSportURL, body)
    .then((res) => res.data);
