import api from '@/api/api';
import { TIgnoreWinterSettlementBody } from './setIgnoreWinterSettlement.types';

export const ignoreWinterSettlementURL =
  '/bookie/trade-manager/sport-details/set-ignore-winter-settlement';

// Post: Ignore Winter Settlement
export const mutateIgnoreWinterSettlement = (
  body: TIgnoreWinterSettlementBody
) =>
  api
    .post<TIgnoreWinterSettlementBody>(ignoreWinterSettlementURL, body)
    .then((res) => res.data);
