import { Box, Divider, Text } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { BetsTable } from '../../../common/components';
import { useBulkBonusAwards } from '../hooks/BulkBonusAwards.hooks';
import { Filters } from './Filters';
import { ImportCSVModalForm } from '@/features/promoManager/pages/PromoManager/tabs/DepositMatch/components/ImportCSVModalForm/ImportCSVModalForm';
import {
  TMutatePromosBody,
  TPromoPunter,
} from '@/api/bulkPromoUpload/bulkPromoUpload.types';
import { array, object, string, number, ValidationError } from 'yup';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { ExampleCSVBonusBetTable } from '@/features/promoManager/pages/PromoManager/tabs/DepositMatch/components/ExampleCSVTable/ExampleCSVBonusBetTable';
import { PaginationV3 } from '@/common/components/Pagination/PaginationV3';
import { ColumnDef } from '@tanstack/react-table';
import DownloadCSVButton from '@/features/promoManager/pages/PromoManager/tabs/DepositMatch/components/DownloadCSVButton/DownloadCSVButton';

export const BulkBonusAwards: React.FC = () => {
  const {
    clearFilters,
    filters,
    isLoading,
    isLastFetch,
    offset,
    setOffset,
    data,
    setFilters,
    isFetching,
  } = useBulkBonusAwards();
  const [history, setHistory] = useState<string[]>([]);
  const intl = useIntl();

  const columns = useMemo<ColumnDef<Api['PromoSummaryResponseItem']>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: () => (
          <FormattedMessage id="promomanagerpage.nametableheader" />
        ),
      },
      {
        id: 'num_of_punters_credited',
        accessorKey: 'num_of_punters_credited',
        header: () => <Text>No. of punters credited</Text>,
        cell: ({ row }) => <Text>{row.original.total_punters}</Text>,
      },
      {
        id: 'total_amount_credited',
        accessorKey: 'total_amount_credited',
        header: () => <Text>Total amount credited</Text>,
        cell: ({ row }) => (
          <Text>
            {intl.formatNumber(row.original.max_value / 100, {
              style: 'currency',
              currency: 'AUD',
            })}
          </Text>
        ),
      },
      {
        id: 'promotion_date',
        accessorKey: 'promotion_date',
        header: () => <Text>Date</Text>,
        cell: (info) => (
          <time dateTime={info.getValue<string>()}>
            <FormattedDate value={info.getValue<string>()} />
          </time>
        ),
      },
      {
        accessorKey: 'promo_id',
        header: () => <Text>Actions</Text>,
        cell: ({ row }) => {
          const { promo_id, file_name } = row.original;
          return <DownloadCSVButton promoId={promo_id} filename={file_name} />;
        },
      },
    ],
    [intl]
  );

  const parsePromos = (values: TPromoPunter[]): TPromoPunter[] =>
    values.map((punter) => ({
      ...punter,
      amount: (punter.amount ?? 0) * 100,
    }));

  const validate = async (values: TMutatePromosBody) => {
    const errors: Partial<Record<keyof TMutatePromosBody, string>> = {};

    try {
      const schema = array(
        object({
          punter_id: string().required().uuid(),
          amount: number().required().min(1).integer(),
        })
      ).min(1);
      await schema.validate(values.promo_punters);
    } catch ({ errors: [error] }) {
      errors.promo_punters = typeof error === 'string' ? error : undefined;
    }

    try {
      const schema = object({
        name: string().required('Name is required'),
        reason: string()
          .required('Reason is required')
          .min(1, 'Reason is required'),
      });

      await schema.validate(values, { abortEarly: false });
    } catch (err) {
      const error = err as ValidationError;
      if (error.inner) {
        error.inner.forEach((err) => {
          if (err.path) {
            errors[err.path] = err.message;
          }
        });
      }
    }

    return errors;
  };

  return (
    <Box width="full">
      <Filters
        filters={filters}
        setFilters={setFilters}
        clearFilters={clearFilters}
      />
      <Divider />
      <BetsTable
        data={data?.data.promotions ?? []}
        columns={columns}
        loading={isLoading || isFetching}
      />
      <PaginationV3
        nextDisabled={isLastFetch}
        offset={offset}
        onPageChange={(newOffset) => setOffset(newOffset)}
        history={history}
        setHistory={setHistory}
        useHistory
        nextOffsetId={!isLastFetch ? data?.data.paging?.next_offset : undefined}
      />
      <ImportCSVModalForm
        promoType="bonus_bet"
        parsePromos={parsePromos}
        validate={validate}
      >
        <ExampleCSVBonusBetTable />
      </ImportCSVModalForm>
    </Box>
  );
};
