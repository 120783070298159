import React, { FC, useState } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { Heading, Paragraph } from 'grommet';

import { useNavigate } from 'react-router-dom';
import { sendResetPasswordLink } from '../../actions';
import { getStrings } from '../../../../common/utils';
import { recordResetPasswordAction } from '../../../settings/pages/Settings/actions';

import { LoadingSpinner } from '../../../../common/components';
import {
  FormContainer,
  SubmitButton,
  LoginLink,
  BackButton,
  StyledInput,
} from './Styles';

import {
  AuthErrorCode,
  BOOKIE_ACTIVITY_LOG_TYPE,
  ACTIVITY_LOG_SUMMARY,
  FIREBASE_CONFIG,
} from '../../../../lib/Constants';
import forgotPasswordSchema, {
  ForgotPasswordSchema,
} from '../../validators/forgotPasswordSchema';
import { ArrowLeftNavy } from '../../../../assets/icons';
import { AnyAction } from '@reduxjs/toolkit';

const ForgotPassword: FC = () => {
  const [{ ForgotPassword: Strings, AuthErrorMessages }] = getStrings();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [sentAddress, setSentAddress] = useState('');

  const handleFormSubmit = async (
    email: string,
    { setErrors }: FormikHelpers<ForgotPasswordSchema>
  ) => {
    await sendResetPasswordLink(email)
      .then(({ success }) => {
        if (success) {
          setIsFormSubmitting(false);
          setEmailSuccess(success);
          setSentAddress(email);
          dispatch(
            recordResetPasswordAction({
              action_type: BOOKIE_ACTIVITY_LOG_TYPE.RESET_PASSWORD,
              action_summary:
                ACTIVITY_LOG_SUMMARY[BOOKIE_ACTIVITY_LOG_TYPE.RESET_PASSWORD],
              email,
              firebase_project_name: FIREBASE_CONFIG.projectId,
            }) as unknown as AnyAction
          );
        }
      })
      .catch((error) => {
        setIsFormSubmitting(false);
        switch (error.code) {
          case AuthErrorCode.INVALID_EMAIL:
          default:
            setErrors({ email: AuthErrorMessages.UserNotFound });
            break;
          case AuthErrorCode.USER_DISABLED:
            setErrors({ email: AuthErrorMessages.UserDisabled });
            break;
          case AuthErrorCode.RESET_PASSWORD_EXCEED_LIMIT:
            setErrors({ email: AuthErrorMessages.ExceededResetLimit });
            break;
        }
      });
  };

  const { values, handleSubmit, handleChange, errors, handleBlur, touched } =
    useFormik({
      validateOnChange: false,
      validateOnBlur: true,
      initialValues: {
        email: '',
      },
      validationSchema: forgotPasswordSchema,
      onSubmit: async ({ email }, formHelpers) => {
        setIsFormSubmitting(true);
        await handleFormSubmit(email, formHelpers);
      },
    });

  return (
    <>
      <BackButton onClick={() => navigate(-1)}>
        <ArrowLeftNavy />
      </BackButton>
      <Heading
        margin={{
          top: '0',
          horizontal: '0',
          bottom: '8px',
        }}
      >
        {Strings.Heading}
      </Heading>
      <Paragraph
        margin={{
          top: '0',
          horizontal: '0',
          bottom: '55px',
        }}
      >
        {Strings.SubHeading}
      </Paragraph>
      {emailSuccess ? (
        <>
          <Paragraph>
            {Strings.SuccessText}
            {sentAddress}
          </Paragraph>
          <LoginLink to="/login" data-cy="forgotPasswordLoginLink">
            {Strings.LoginLink}
          </LoginLink>
        </>
      ) : (
        <FormContainer>
          {isFormSubmitting && <LoadingSpinner />}
          <form onSubmit={handleSubmit}>
            <StyledInput
              id="email"
              name="email"
              type="text"
              labelText="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={!!errors.email && touched.email}
              errorText={touched.email ? errors.email : ''}
              dataCy="forgotPasswordEmail"
            />
            <SubmitButton type="submit" data-cy="forgotPasswordSubmit">
              {isFormSubmitting ? Strings.ButtonSubmitting : Strings.Button}
            </SubmitButton>
          </form>
        </FormContainer>
      )}
    </>
  );
};

export default ForgotPassword;
