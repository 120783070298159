import api from '../../../api';
import { TVoidPropositionBody } from './voidProposition.types';

export const voidPropositionURL =
  '/bookie/trade-manager/sport-details/void-proposition';

// Post: Void Proposition
export const mutateVoidProposition = (body: TVoidPropositionBody) =>
  api
    .post<TVoidPropositionBody>(voidPropositionURL, body)
    .then((res) => res.data);
