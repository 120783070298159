import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetRequest, apiPatchRequest } from '../../../../../lib/api/api';
import {
  TGetPropositions,
  TMarket,
  TProposition,
} from '../../../../../lib/DBModels';
import { TSuspendMarketInput } from '../../RaceDetails/components/ModalConfigure/Status/Status.types';
import { setMatchMarkets, setSelectedMarketPropositions } from '../slices';
import { AppThunkDispatch } from '../../../../../app/types';
import { TMarketParams } from '../types';

export const getMarkets = createAsyncThunk<
  TMarket[],
  TMarketParams,
  { dispatch: AppThunkDispatch }
>(
  'matchDetails/getMatch',
  async ({ matchId, marketGroupId, isUpdate, isFocus }, thunkAPI) => {
    const markets = await apiGetRequest<TMarket[]>(
      `/bookie/trade-manager/sport-details/markets?match_id=${matchId}${
        (marketGroupId &&
          marketGroupId !== 'FOCUS' &&
          `&market_group_id=${marketGroupId}`) ||
        ''
      }${
        (isFocus && `&is_focus=${isFocus}`) ||
        (marketGroupId && marketGroupId === 'FOCUS' && `&is_focus=${true}`) ||
        ''
      }${isUpdate ? `&t=${new Date().valueOf()}` : ''}`
    );

    thunkAPI.dispatch(setMatchMarkets(markets));

    return markets;
  }
);

export const getPropositionsForMarket = createAsyncThunk<
  TProposition[],
  TGetPropositions,
  { dispatch: AppThunkDispatch }
>('matchDetails/getMatch', async (params, thunkAPI) => {
  const { propositions } = await apiGetRequest<{
    propositions: TProposition[];
  }>(
    `/bookie/trade-manager/sport-details/proposition-for-market?market_id=${
      params.marketId
    }${params.isUpdate ? `&t=${new Date().valueOf()}` : ''}`
  );

  thunkAPI.dispatch(setSelectedMarketPropositions(propositions));

  return propositions;
});

export const suspendMatchMarkets = async (
  payload: TSuspendMarketInput
): Promise<void> =>
  apiPatchRequest<void>(
    `/bookie/trade-manager/sport-details/suspend-match`,
    payload
  );

export const suspendMarketPropositions = async (
  payload: TSuspendMarketInput
): Promise<void> =>
  apiPatchRequest<void>(
    `/bookie/trade-manager/sport-details/suspend-proposition`,
    payload
  );
