import api from '@/api/api';
import { TGetSportMarketParams, TSportMarket } from './getSportMarket.types';

const getSportMarketURL =
  '/bookie/trade-manager/market-creation/get-sport-market';

// Trade Manager - Get SportMarket

export const queryGetSportMarket = (params: TGetSportMarketParams) =>
  api.get<TSportMarket>(getSportMarketURL, { params }).then((res) => res.data);
