import api from '../../../api';
import { TMutatePriceTypesBody } from './updatePriceTypes.types';

export const updatePriceTypesEndpoint =
  '/bookie/trade-manager/race-details/update-price-types';

export async function mutatePriceTypes(body: TMutatePriceTypesBody) {
  const res = await api.post(updatePriceTypesEndpoint, body);
  return res;
}
