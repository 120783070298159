import { AgeRange } from '@/api/promos/promo/promotion.types';
import { TMoneyBackFormikTypes } from './MoneyBack.types';
import { RacingType } from '@/lib/Constants';

export const paybackPositions = [2, 3, 4];

export const AGE_RANGES: { [key: string]: string } = {
  [AgeRange.All]: 'All',
  [AgeRange.YoungAdult]: '18-24',
  [AgeRange.PrimeAdult]: '24-49',
  [AgeRange.MatureAdult]: '50-64',
  [AgeRange.SeniorAdult]: '65+',
};

export const RACE_TYPES = [
  { type: RacingType.HARNESS },
  { type: RacingType.HORSE_RACING },
  { type: RacingType.GREYHOUNDS },
];

export const initialValues: TMoneyBackFormikTypes = {
  name: '',
  promo_description: '',
  promo_type: 'money_back',
  start_time: undefined,
  expiry: undefined,
  audience_conditions: {
    punter_categories: null,
    age_ranges: null,
    address_locations: null,
    affiliates: null,
    min_balance: null,
    max_balance: null,
    min_ebc: null,
    max_ebc: null,
    min_last_bet_days_ago: null,
  },
  parameters: {
    percentage: 1,
    max_amount: null,
    max_payout_position: 3,
  },
  race_ids: [],
};
