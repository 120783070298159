import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { StartTimeCalculator } from '../../../../../../common/components';
import {
  centsToDollars,
  getIconAssetPath,
  getStrings,
} from '../../../../../../common/utils';
import { EGeneralStatus, TPromotion } from '../../../../../../lib/DBModels';
import { StatusLight } from '../../../../../../common/components/StatusLight/StatusLight';
import IconSvg from '@/components/IconSvg/IconSvg';
import { useNavigate } from 'react-router-dom';

export const useColumns = () => {
  const navigate = useNavigate();
  const [
    {
      TradeManagerPage: { Common },
    },
  ] = getStrings();

  const columns = [
    {
      accessorKey: 'event_title',
      header: Common.Event,
      omit: true,
      sortable: false,
      cell: (props: {
        row: {
          original: {
            event_icon: string;
            event_title: string;
            event_subtitle: string;
            event_status: string;
            event_id: string;
            event_type: string;
            start_time: string;
            event_start_time: string;
            promotions?: TPromotion[];
          };
        };
        getValue: () => string | undefined;
      }) => {
        const promotions = props.row.original?.promotions?.filter(
          (promotion) => promotion.type === 'money_back'
        );

        return (
          <Box>
            <Flex alignItems="center" mb="1">
              <StatusLight
                status={props.row.original?.event_status as EGeneralStatus}
                size="md"
              />
              <Box boxSize="9" mx="3">
                <IconSvg
                  name={getIconAssetPath(
                    'sports',
                    props.row.original?.event_icon ?? ''
                  )}
                  sx={{ boxSize: '8' }}
                />
              </Box>
              <Flex alignItems="start" flexDirection="column" gap="2">
                <Flex flexDir="row" gap="4">
                  {!!props.row.original?.event_status && (
                    <StartTimeCalculator
                      status={
                        props.row.original?.event_status as EGeneralStatus
                      }
                      date={props.row.original?.event_start_time}
                    />
                  )}

                  <Flex flexDir="row" gap="2">
                    {!!promotions &&
                      promotions.map((promotion) => (
                        <Box
                          key={promotion.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/promo-manager/money-back/promotion?id=${promotion.id}}`
                            );
                          }}
                        >
                          <IconSvg
                            name={getIconAssetPath('sports', 'money-back')}
                            sx={{ boxSize: '6', cursor: 'pointer' }}
                          />
                        </Box>
                      ))}
                  </Flex>
                </Flex>
                <Flex>
                  <Text fontWeight="bold" mr="1">
                    {props.row.original?.event_subtitle ?? ''}
                  </Text>
                  -<Text ml="1"> {props.row.original?.event_title ?? ''} </Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        );
      },
    },
    {
      accessorKey: 'total_bets',
      header: Common.PlaceBets,
      cell: (props: { getValue: () => number | undefined }) => (
        <Text size="sm">{props.getValue()}</Text>
      ),
    },
    {
      accessorKey: 'cash_out_turnover',
      header: 'Cash Out Turnover',
      cell: (props: { getValue: () => number | undefined }) => (
        <Text fontSize="sm">{centsToDollars(props.getValue(), true)}</Text>
      ),
    },
    {
      accessorKey: 'turnover',
      header: Common.Turnover,
      cell: (props: { getValue: () => number | undefined }) => (
        <Text fontSize="sm">{centsToDollars(props.getValue(), true)}</Text>
      ),
    },
    {
      accessorKey: 'biggest_bet',
      header: Common.BiggestBet,
      cell: (props: { getValue: () => number | undefined }) => (
        <Text fontSize="sm">{centsToDollars(props.getValue(), true)}</Text>
      ),
    },
    {
      accessorKey: 'exposure',
      header: Common.Exposure,
      cell: (props: { getValue: () => number | undefined }) => (
        <Text fontSize="sm">{centsToDollars(props.getValue(), true)}</Text>
      ),
    },
  ];

  return columns;
};
