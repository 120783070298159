import { Button, Flex, FormControl, FormLabel, Switch } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { useOfferings } from './services/SettingsOfferings.hooks';
import { Formik, Form, FormikHelpers } from 'formik';
import { TOfferings } from '@/api/settings/system/offerings/offerings.types';
import { FEATURE_FLAGS } from '@/constants/featureFlags';

export default function SettingsOfferingsWrapper() {
  const {
    isSPEnabled,
    isSGMEnabled,
    isBlendedEnabled,
    isToteSingleEnabled,
    isCashoutEnabled,
    isMysteryBetRolloverEnabled,
    isMoMEnabled,
    isLoading,
    onChange,
  } = useOfferings();

  const handleSubmit = (
    values: TOfferings,
    { setSubmitting }: FormikHelpers<TOfferings>
  ) => {
    onChange(values);
    setSubmitting(false);
  };

  if (isLoading) return <></>;

  const initialOfferingValues: TOfferings = {
    starting_price: isSPEnabled,
    sgm: isSGMEnabled,
    blended: isBlendedEnabled,
    tote_single: isToteSingleEnabled,
    cashout: isCashoutEnabled,
    mystery_bet_rollover_enabled: isMysteryBetRolloverEnabled,
    multi_of_multis_enabled: isMoMEnabled,
  };

  return (
    <>
      <Flex borderBottom="1px" borderColor="gray.100" px="4" pl="0">
        <Formik<TOfferings>
          initialValues={initialOfferingValues}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, dirty, isSubmitting }) => (
            <Form>
              <Flex py="12" w="full" gap="2">
                <FormControl
                  isDisabled={isLoading}
                  alignItems="center"
                  display="flex"
                  px="8"
                  w="auto"
                  bg="gray.100"
                  borderRadius="sm"
                >
                  <FormLabel mb="0">
                    <FormattedMessage id="settingsPage.offerings.spEnabled" />
                  </FormLabel>
                  <Switch
                    isChecked={values.starting_price}
                    onChange={(e) => {
                      setFieldValue('starting_price', e.target.checked);
                    }}
                    _disabled={{
                      '.chakra-switch__track': { cursor: 'wait' },
                    }}
                  />
                </FormControl>

                {FEATURE_FLAGS.IS_SGM_ENABLED && (
                  <FormControl
                    isDisabled={isLoading}
                    alignItems="center"
                    display="flex"
                    p="4"
                    w="auto"
                    bg="gray.100"
                    borderRadius="sm"
                  >
                    <FormLabel mb="0">SGM Enabled</FormLabel>
                    <Switch
                      isChecked={values.sgm}
                      onChange={(e) => {
                        setFieldValue('sgm', e.target.checked);
                      }}
                      _disabled={{
                        '.chakra-switch__track': { cursor: 'wait' },
                      }}
                    />
                  </FormControl>
                )}

                {FEATURE_FLAGS.IS_BLENDED_ENABLED && (
                  <FormControl
                    isDisabled={isLoading}
                    alignItems="center"
                    display="flex"
                    p="4"
                    w="auto"
                    bg="gray.100"
                    borderRadius="sm"
                  >
                    <FormLabel mb="0">Blended Enabled</FormLabel>
                    <Switch
                      isChecked={values.blended}
                      onChange={(e) => {
                        setFieldValue('blended', e.target.checked);
                      }}
                      _disabled={{
                        '.chakra-switch__track': { cursor: 'wait' },
                      }}
                    />
                  </FormControl>
                )}

                <FormControl
                  isDisabled={isLoading}
                  alignItems="center"
                  display="flex"
                  p="4"
                  w="auto"
                  bg="gray.100"
                  borderRadius="sm"
                >
                  <FormLabel mb="0">Tote Single Enabled</FormLabel>
                  <Switch
                    isChecked={values.tote_single}
                    onChange={(e) => {
                      setFieldValue('tote_single', e.target.checked);
                    }}
                    _disabled={{
                      '.chakra-switch__track': { cursor: 'wait' },
                    }}
                  />
                </FormControl>

                <FormControl
                  isDisabled={isLoading}
                  alignItems="center"
                  display="flex"
                  p="4"
                  w="auto"
                  bg="gray.100"
                  borderRadius="sm"
                >
                  <FormLabel mb="0">Mystery Bet Rollover Enabled</FormLabel>
                  <Switch
                    isChecked={values.mystery_bet_rollover_enabled}
                    onChange={(e) => {
                      setFieldValue(
                        'mystery_bet_rollover_enabled',
                        e.target.checked
                      );
                    }}
                    _disabled={{
                      '.chakra-switch__track': { cursor: 'wait' },
                    }}
                  />
                </FormControl>

                <FormControl
                  isDisabled={isLoading}
                  alignItems="center"
                  display="flex"
                  p="4"
                  w="auto"
                  bg="gray.100"
                  borderRadius="sm"
                >
                  <FormLabel mb="0">Cash Out Enabled</FormLabel>
                  <Switch
                    isChecked={values.cashout}
                    onChange={(e) => {
                      setFieldValue('cashout', e.target.checked);
                    }}
                    _disabled={{
                      '.chakra-switch__track': { cursor: 'wait' },
                    }}
                  />
                </FormControl>
                {FEATURE_FLAGS.IS_MOM_ENABLED && (
                  <FormControl
                    isDisabled={isLoading}
                    alignItems="center"
                    display="flex"
                    p="4"
                    w="auto"
                    bg="gray.100"
                    borderRadius="sm"
                  >
                    <FormLabel mb="0">Multi of Multi Enabled</FormLabel>
                    <Switch
                      isChecked={values.multi_of_multis_enabled}
                      onChange={(e) => {
                        setFieldValue(
                          'multi_of_multis_enabled',
                          e.target.checked
                        );
                      }}
                      _disabled={{
                        '.chakra-switch__track': { cursor: 'wait' },
                      }}
                    />
                  </FormControl>
                )}

                {dirty && (
                  <Button
                    type="submit"
                    isDisabled={!dirty || isSubmitting}
                    alignSelf="end"
                  >
                    Save
                  </Button>
                )}
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>

      <Outlet />
    </>
  );
}
