import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box } from 'grommet';
import { Column } from '@tanstack/react-table';
import { Checkbox, Flex } from '@chakra-ui/react';
import { useQueryClient } from 'react-query';
import InfoBetModal from '../../../../modals/InfoBetModal/Modal';
import { TBet } from '../../../../../../lib/DBModels';
import {
  Separator,
  Select as BCSelect,
  BetsTable,
} from '../../../../../../common/components';
import { StyledInput } from './Upcoming.style';
import { getStrings } from '../../../../../../common/utils';
import {
  useColumns,
  useResultedBets,
  useUpcomingBetsData,
} from '../../Services/Hooks.PlacedBets';
import { setFilter, reset } from './Services/Upcoming.slice';
import VoidBetModal from '../../../../modals/VoidBetModal';
import { eventTypeFilterOptions } from '../../Services/Config.PlaceBets';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../common/hooks/useRedux';
import { EUpcomingEventType } from './Services/Upcoming.types';
import IconSvg from '@/components/IconSvg/IconSvg';
import { keys } from '../../../../../../api/api.keys';
import { Pagination } from '../../../../../../common/components/Pagination/Pagination';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { PaginationV2 } from '@/common/components/Pagination/PaginationV2';
import { EBetTypes } from '@/common/components/BetsTable/components/MarketTableItem';
import { useFormatParamsForFiltering } from '../../PlacedBets.utils';
import api from '@/api/api';
import { ENV } from '@/lib/Constants';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
// import toast from 'react-hot-toast';
import { FilterOptionsStore } from '../store/FilterOptionsStore';
import Select from 'react-select';
import { punterCategoryOptions } from './Services/Upcoming.config';

export type TFilterOptions = Partial<{
  punterName: string;
  eventType: string;
  raceSportsCode: string;
  punterCategories: string[];
  betType: string;
  maxOdds: boolean;
  minExposure: number;
  minStake: number;
  liabilityOrStake: string;
  liabilityOrStakeValue: string;
  startDate: string;
  endDate: string;
}>;

export const Upcoming: FC = () => {
  const isMounted = useRef(false);
  const [filterOptions, setFilterOptions] = useState<TFilterOptions>({});
  const params = useFormatParamsForFiltering(filterOptions);
  const hasConnection = useRef(false);
  const handleFilterChange = useCallback(
    (filter: TFilterOptions) =>
      setFilterOptions((prevState) => ({ ...prevState, ...filter })),
    []
  );

  const {
    data: pendingBets,
    isLoading: isPendingLoading,
    next,
    nextDisabled,
    previous,
    previousDisabled,
    hidePagination,
    handleUpdateBetFromSocket,
  } = useResultedBets('pending', params);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FilterOptionsStore.getState().actions.setState(params as any);

    () => FilterOptionsStore.getState().actions.clearState();
  }, [params]);

  useEffect(() => {
    isMounted.current = true;
    let hbu: HubConnection | null = null;
    (async () => {
      if (!isWincore) return;
      if (hasConnection.current) return;

      try {
        const { data } = await api.get<{ url: string; access_token: string }>(
          `${ENV.REACT_APP_API_URL}/bookie/global-trading/connection-details/placed-bets`
        );

        if (data?.url) {
          hbu = new HubConnectionBuilder()
            .withUrl(data?.url ?? '', {
              accessTokenFactory: () => data.access_token,
            })
            .configureLogging(LogLevel.Information)
            .build();

          hbu.on('SendMessage', async (message: TBet) => {
            const fo = FilterOptionsStore.getState();
            handleUpdateBetFromSocket(message, fo, true);
          });

          await hbu.start();
          hasConnection.current = true;

          hbu.onclose(async () => {
            if (!isMounted.current) return;
            try {
              // toast.error('Upcoming - Connection lost. Reconnecting...');
              hasConnection.current = false;
              await hbu?.start();
              hasConnection.current = true;
              // toast.success('Upcoming - Connection reestablished.');
            } catch (e) {
              // toast.error(
              //   'Upcoming - Connection lost. Please refresh the page.'
              // );
              hasConnection.current = false;
            }
          });

          await api
            .post(
              `/bookie/global-trading/add-connection/placed-bets?connection=${hbu.connectionId}`,
              undefined
            )
            .then((res) => res.data);
        }
      } catch (e) {}
    })();
    return () => {
      if (hbu) {
        isMounted.current = false;
        hbu.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryClient = useQueryClient();
  const [
    {
      PlaceBetsPage: { Common },
    },
  ] = getStrings();

  const { data, isLoading, isLastFetch, setOffset, offset } =
    useUpcomingBetsData();
  const columns = useColumns(true);

  const {
    filter: { event_type },
  } = useAppSelector((state) => state.upcomingBets);

  const dispatch = useAppDispatch();

  // 👇 Below will reset the filters on a view & tab change.
  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  return (
    <>
      <InfoBetModal />
      <Separator />
      <Box
        direction="row"
        pad={{ vertical: '15px', horizontal: '5px' }}
        gap="10px"
      >
        <StyledInput
          placeholder={Common.PunterSearch}
          icon={<IconSvg name="search" />}
          onChange={(e) => {
            if (isWincore)
              return handleFilterChange({
                punterName: e.target.value,
              });
            else dispatch(setFilter({ punter_name: e.target.value }));
          }}
        />
        {/* // TODO: remove when BE is ready */}
        {false && (
          <BCSelect
            id="event_type"
            name="event_type"
            placeholder={Common.EventType}
            options={eventTypeFilterOptions}
            value={event_type}
            onChange={({ option }: { option: EUpcomingEventType }) => {
              dispatch(
                setFilter({
                  event_type:
                    option === eventTypeFilterOptions[0] ? '' : option,
                })
              );
            }}
            width="100%"
            disabled
          />
        )}
        {false && (
          <BCSelect
            id="race_sports_code"
            name="race_sports_code"
            placeholder={'Race/Sports Code'}
            options={['Horse Racing', 'Greyhounds', 'Harness Racing']}
            value={filterOptions.raceSportsCode}
            onChange={({ option }: { option: string }) => {
              setFilterOptions((prevState) => ({
                ...prevState,
                raceSportsCode: option,
              }));
            }}
            width="100%"
            disabled
          />
        )}
        {isWincore && (
          <Flex gap="10px">
            <Select
              isSearchable
              isMulti
              name="colors"
              options={punterCategoryOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Punter Category"
              styles={{
                control: (provided) => ({
                  ...provided,
                  cursor: 'pointer',
                  minWidth: '200px',
                  minHeight: '54px',
                  border: '1px solid #e2e8f0',
                }),
                multiValue: (base) => ({
                  ...base,
                  fontSize: '12px',
                  cursor: 'pointer',
                }),
              }}
              onChange={(selectedOptions) => {
                if (selectedOptions !== null) {
                  const selectedCategories = selectedOptions.map(
                    (option) => option.label
                  );
                  handleFilterChange({
                    punterCategories: selectedCategories,
                  });
                }
              }}
            />

            <BCSelect
              id="event_type"
              name="event_type"
              placeholder="Event Type"
              options={['All', 'Race', 'Match']}
              value={filterOptions.eventType}
              onChange={({ option }: { option: string }) =>
                handleFilterChange({
                  eventType: option,
                })
              }
            />
            <BCSelect
              id="bet_type"
              name="bet_type"
              placeholder={'Bet Type'}
              options={['All', 'Multi', 'SGMulti', 'SRMulti']}
              value={filterOptions.betType}
              onChange={({ option }: { option: string }) => {
                handleFilterChange({
                  betType: option as EBetTypes,
                });
              }}
              width="100%"
            />
            <Checkbox
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFilterChange({
                  maxOdds: e.target.checked,
                })
              }
            >
              {'Odds < 1.20'}
            </Checkbox>
            <BCSelect
              id="liability_or_stake"
              name="liability_or_stake"
              placeholder={'Liability/Stake'}
              options={['Liability', 'Stake']}
              value={filterOptions.liabilityOrStake}
              onChange={({ option }: { option: string }) =>
                handleFilterChange({
                  liabilityOrStake: option,
                })
              }
              width="100%"
            />
            <BCSelect
              id="liability_or_stake_value"
              name="liability_or_stake_value"
              placeholder={'Amount'}
              options={[
                'ALL',
                '$100',
                '$250',
                '$500',
                '$1000',
                '$5000',
                '$10000',
              ]}
              value={filterOptions.liabilityOrStakeValue}
              onChange={({ option }: { option: string }) => {
                handleFilterChange({
                  liabilityOrStakeValue: option,
                });
              }}
              width="100%"
            />
            {/* <BCSelect
          id="start_status"
          name="start_status"
          placeholder={UpcomingTab.StartStatus}
          options={[]}
          labelKey="label"
          valueKey="value"
          // value={selectedStatus}
          // onChange={({ option }: { option: StatusType }) => setSelectedStatus(option)}
          width="100%"
        /> */}
          </Flex>
        )}
        {!isWincore && (
          <Checkbox
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(
                setFilter({ cashout_only: e.target.checked || undefined })
              );
            }}
          >
            Cash Out Only
          </Checkbox>
        )}
      </Box>
      <Separator />

      {/* We 'refresh' the data after a void! */}
      <VoidBetModal
        onVoided={async () => queryClient.invalidateQueries([keys.placedBets])}
      />

      <Box height={{ min: '700px' }}>
        <BetsTable
          columns={columns as unknown as Column<TBet>[]}
          data={
            isWincore ? (pendingBets as TBet[]) ?? [] : (data as TBet[]) ?? []
          }
          loading={isLoading || isPendingLoading}
        />
      </Box>

      {!isWincore && (
        <Pagination
          nextDisabled={isLastFetch}
          offset={offset}
          onPageChange={(newOffset) => setOffset(newOffset)}
        />
      )}
      {isWincore && (
        <PaginationV2
          onNext={next}
          onPrev={previous}
          hidePagination={hidePagination}
          nextDisabled={nextDisabled}
          previousDisabled={previousDisabled}
        />
      )}
    </>
  );
};

export default Upcoming;
