import React from 'react';
import {
  Modal as BCModal,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalContent,
  Text,
  SimpleGrid,
  Box,
  Flex,
  Badge,
  Icon,
} from '@chakra-ui/react';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { CircleWithCross } from '@styled-icons/entypo/CircleWithCross';
import { FormattedMessage } from 'react-intl';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../common/hooks/useRedux';
import {
  centsToDollars,
  getStrings,
  getPlaceFromNumber,
  getIconAssetPath,
  oddsFormatted,
  getPositionValue,
} from '../../../../common/utils';

import { setModalInfoBet } from '../../../punters/pages/Bets/Services/Slice.Bets';
import BetStatusBadge from '../../../../common/components/BetStatusBadge';
import { formatDateTime24 } from '../../../../lib/Time';
import BetReturnText from '../../../../common/components/BetReturnText';
import { EBetStatus } from '../../../../lib/DBModels';
import { HeadingText } from './Modal.styles';
import {
  getFlexi,
  getStakePerCombo,
  getValidCombos,
} from '../../../punters/pages/Bets/Services/Bets.utils';
import IconSvg from '@/components/IconSvg/IconSvg';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { OddsBoostTooltip } from '../../components/OddsBoostTooltip';

const RACE_PLACEMENT = {
  Top1: 'Win',
  Top2: 'Top 2',
  Top3: 'Top 3',
  Top4: 'Top 4',
};

export default function Modal() {
  const dispatch = useAppDispatch();
  const { modalInfoBet: bet } = useAppSelector((state) => state.punterBets);

  const [
    {
      PunterPage: {
        Bets: {
          Modal: { Labels },
        },
      },
    },
  ] = getStrings();

  const validCombos = getValidCombos(bet);
  const stakePerCombo = getStakePerCombo(bet);
  const flexi = getFlexi(bet);

  if (!bet) return null;

  const isMoM = bet?.bet_legs?.some((leg) => !!leg?.legs);

  const totalMoMLegs = bet?.bet_legs?.reduce(
    (count, bet_leg) =>
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      count +
      (bet_leg?.bet_description === 'Blended' || !bet_leg?.legs
        ? 1
        : bet_leg?.legs.length ?? 0),
    0
  );

  const firstLeg = bet.bet_legs && bet.bet_legs[0];

  const oddsBoost = bet.promo_tokens?.find(
    (promo) => promo?.token_type === 'odds-boost'
  );

  const hasMoneyBack = bet.promotions?.some(
    (promo) => promo?.type === 'money_back' && !!promo.bonus_amount
  );

  const stake = bet?.bonus_stake ? bet?.bonus_stake : bet?.stake;
  let selection;

  selection =
    bet?.bet_legs && bet.bet_type !== 'Single'
      ? `${bet?.bet_legs.length} ${Labels.Legs}`
      : isWincore
      ? firstLeg?.event_subtitle
      : bet.event_subtitle;

  if (isMoM) selection = `${totalMoMLegs} Legs`;

  const renderMarket = () => {
    if (isWincore) {
      const betLeg = bet.bet_legs?.at(0);

      switch (bet.bet_type) {
        case 'Single': {
          if (betLeg?.event_type === 'Race') {
            if (betLeg?.price_type === 'even_shot') return 'Even Shot';
            return betLeg?.bet_description;
          } else return betLeg?.market_name;
        }
        case 'SRMulti': {
          return 'Single Race Multi';
        }
        case 'Blended': {
          return 'Blended';
        }
        case 'SGMulti': {
          return 'Same Game Multi';
        }
        case 'Exotics': {
          if (betLeg?.bet_description === 'FirstFour') return 'First Four';
          return betLeg?.bet_description;
        }
        case 'Multi': {
          return 'Multi';
        }
      }
    } else {
      const isMysteryBet = bet?.price_type === 'mystery_bet';

      switch (bet.bet_type) {
        case 'Single': {
          if (bet?.event_type === 'Race') {
            if (bet?.price_type === 'even_shot') return 'Even Shot';
            return bet?.bet_description;
          } else return bet?.market_name;
        }
        case 'SRMulti':
        case 'Blended': {
          return bet?.bet_description;
        }
        case 'SGMulti': {
          return 'Same Game Multi';
        }
        case 'Exotics': {
          if (bet.bet_description === 'FirstFour') return 'First Four';
          return bet?.bet_description;
        }
        case 'Multi': {
          if (isMysteryBet) return `Mystery Bet with Rollover`;
          return 'Multi';
        }
      }
    }
  };

  const renderOdds = () => {
    if (!isWincore && bet.exotic_selections) {
      return <FormattedMessage id="generic.na" />;
    }

    const isFixedOdds =
      bet.price_type !== 'tote_single_mid' &&
      bet.price_type !== 'tote_single_best' &&
      bet.price_type !== 'starting' &&
      firstLeg?.price_type !== 'tote_single_mid' &&
      firstLeg?.price_type !== 'tote_single_best' &&
      firstLeg?.price_type !== 'starting';

    const renderType = () => {
      switch (isWincore ? firstLeg?.price_type : bet.price_type) {
        case 'tote_single_mid':
          return 'MD';
        case 'starting':
          return 'SP';
        case 'tote_single_best':
          return 'BT';
        default:
          if (
            bet?.odds !== null &&
            bet?.odds !== undefined &&
            !isNaN(bet?.odds) &&
            bet?.odds !== 0
          ) {
            return oddsFormatted(bet.odds) !== ''
              ? oddsFormatted(bet.odds)
              : 'N/A';
          }
          return 'N/A';
      }
    };

    return isFixedOdds ? (
      oddsFormatted(bet.odds) === '' ? (
        'N/A'
      ) : (
        <Flex flexDirection="row">
          <Flex>
            {oddsFormatted(bet.odds)}{' '}
            {oddsBoost && (
              <Text
                as="span"
                textDecoration="line-through"
                opacity="0.8"
                marginLeft="1"
              >
                {oddsFormatted(oddsBoost.original_odds)}
              </Text>
            )}
          </Flex>

          {!!oddsBoost && <OddsBoostTooltip />}
        </Flex>
      )
    ) : (
      <Badge colorScheme="blue">{renderType()}</Badge>
    );
  };
  return (
    <BCModal size="xl" isOpen onClose={() => dispatch(setModalInfoBet())}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{bet?.punter_name ?? ''}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir="column" gap="4">
            <>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <HeadingText>{Labels.TransactionId}</HeadingText>
                  <Text fontSize="sm">{bet?.bet_id ?? ''}</Text>
                </Box>

                <Box>
                  <HeadingText>{Labels.BetPlaced}</HeadingText>
                  <Text fontSize="sm">{formatDateTime24(bet?.created_at)}</Text>
                </Box>

                <Box>
                  <HeadingText>{Labels.EventTime}</HeadingText>
                  <Text fontSize="sm">
                    {formatDateTime24(
                      isWincore ? firstLeg?.event_start : bet?.event_start
                    )}
                  </Text>
                </Box>
                <Box />
              </SimpleGrid>

              <SimpleGrid
                columns={2}
                spacing={4}
                borderTop="1px solid"
                borderTopColor="gray.200"
                pt="4"
              >
                {!bet.bet_legs && (
                  <Box>
                    <HeadingText>{Labels.Event}</HeadingText>
                    <Box>
                      <Text fontSize="sm" textTransform="capitalize">
                        {bet?.event_title?.toLowerCase() ?? ''}
                      </Text>
                    </Box>
                  </Box>
                )}
                {isWincore &&
                  bet.bet_type !== 'Multi' &&
                  bet.bet_legs &&
                  bet.bet_legs[0]?.event_title && (
                    <Box>
                      <HeadingText>{Labels.Event}</HeadingText>
                      <Box>
                        <Text fontSize="sm" textTransform="capitalize">
                          {(bet.bet_legs &&
                            bet.bet_legs[0].event_title.toLowerCase()) ??
                            ''}
                        </Text>
                      </Box>
                    </Box>
                  )}

                <Box>
                  <HeadingText>{Labels.Market}</HeadingText>
                  <Text fontSize="sm">{renderMarket()}</Text>
                </Box>

                <Box>
                  <HeadingText>{Labels.Stake}</HeadingText>
                  <Text fontSize="sm">
                    {centsToDollars(Number(stake) ?? '')}
                    {bet?.bonus_stake ? (
                      <Badge
                        variant="solid"
                        bg="black"
                        borderRadius="lg"
                        ml="1"
                        px="2"
                        py="1"
                      >
                        {Labels.BB}
                      </Badge>
                    ) : null}
                  </Text>
                </Box>

                <Box>
                  <HeadingText>{Labels.Return}</HeadingText>
                  <Text fontSize="sm">
                    <BetReturnText
                      as="span"
                      status={bet?.status}
                      payout={bet?.payout}
                    />
                    {oddsBoost && bet?.is_won && (
                      <BetReturnText
                        as="span"
                        status={bet?.status}
                        payout={
                          oddsBoost.original_odds && bet.stake
                            ? oddsBoost.original_odds * bet.stake
                            : 0
                        }
                        opacity="0.8"
                        textDecoration="line-through"
                        ml={1}
                      />
                    )}
                  </Text>
                </Box>

                {((!isWincore && !bet?.exotic_selections) || isWincore) && (
                  <>
                    {bet?.bet_type !== 'Exotics' && (
                      <Box>
                        <HeadingText>{Labels.Selection}</HeadingText>
                        <Text fontSize="sm">{selection}</Text>
                      </Box>
                    )}

                    <Box>
                      <HeadingText>{Labels.Odds}</HeadingText>
                      <Text fontSize="sm">{renderOdds()}</Text>
                    </Box>

                    {bet?.cashout_value && (
                      <Box>
                        <HeadingText>Cash Out Value</HeadingText>
                        <Text fontSize="sm">
                          {centsToDollars(bet?.cashout_value)}
                        </Text>
                      </Box>
                    )}

                    {hasMoneyBack && bet?.status === EBetStatus.Settled && (
                      <Box>
                        <HeadingText>Money Back Bonus </HeadingText>
                        <Text fontSize="sm">
                          {centsToDollars(
                            bet?.promotions[0]?.bonus_amount ?? 0
                          )}
                        </Text>
                      </Box>
                    )}
                  </>
                )}

                <Box>
                  <HeadingText>{Labels.Result}</HeadingText>
                  <BetStatusBadge status={bet?.status} />
                </Box>
              </SimpleGrid>
              {((bet?.bet_type === 'Exotics' &&
                !isWincore &&
                Number(bet?.exotic_selections?.length ?? 0) > 0) ||
                (bet?.bet_type === 'Exotics' &&
                  isWincore &&
                  Number(bet?.selection?.length ?? 0) > 0)) && (
                <SimpleGrid
                  columns={2}
                  spacing={4}
                  borderTop="1px solid"
                  borderTopColor="gray.200"
                  pt="4"
                >
                  <Box mb="8">
                    <HeadingText>{Labels.NumberOfCombo}</HeadingText>
                    <Text fontSize="sm">{validCombos?.length}</Text>
                  </Box>

                  <Box>
                    <HeadingText>{Labels.Selections}</HeadingText>

                    {(isWincore ? bet?.selection : bet?.exotic_selections)?.map(
                      (combo, index) => (
                        <Flex key={index} width="100%">
                          <Text fontSize="sm" mb="4" width={50}>
                            {getPlaceFromNumber(index + 1)}
                          </Text>
                          <Box mb="4">
                            {!isWincore
                              ? combo?.exotic_combos?.map((runner) => (
                                  <Text
                                    key={`combo-${runner?.runner_name}`}
                                    fontSize="sm"
                                    mb="2"
                                    textTransform="capitalize"
                                  >
                                    {runner?.runner_number}.{' '}
                                    {runner?.runner_name?.toLowerCase() ?? ''}
                                  </Text>
                                ))
                              : (combo as unknown as number[])?.map(
                                  (runnerId, index) => (
                                    <Text
                                      key={index}
                                      fontSize="sm"
                                      mb="2"
                                      textTransform="capitalize"
                                    >
                                      {runnerId}.
                                    </Text>
                                  )
                                )}
                          </Box>
                        </Flex>
                      )
                    )}
                  </Box>

                  <Box mb="8">
                    <HeadingText my="1">{Labels.StakePerCombo}</HeadingText>
                    <Text fontSize="sm">{centsToDollars(stakePerCombo)}</Text>
                  </Box>

                  <Box mb="8">
                    <HeadingText my="1">{Labels.Flexi}</HeadingText>
                    <Text fontSize="sm">{flexi}%</Text>
                  </Box>
                </SimpleGrid>
              )}
              {bet?.bet_legs &&
                bet?.bet_type !== 'Single' &&
                bet?.bet_type !== 'Exotics' && (
                  <SimpleGrid
                    columns={1}
                    spacing={4}
                    borderTop="1px solid"
                    borderTopColor="gray.200"
                    maxH={
                      Number(bet?.bet_legs?.length ?? 0) > 4 ? '250px' : 'unset'
                    }
                    overflowY={
                      Number(bet?.bet_legs?.length ?? 0) > 4 ? 'scroll' : 'auto'
                    }
                  >
                    <Box bg="gray.50" px="2">
                      {bet?.bet_legs?.map((leg, index, arr) => {
                        let header: string | undefined;
                        let info: string | undefined;

                        let betDescription = '';
                        if (bet.bet_type === 'SRMulti') {
                          betDescription =
                            RACE_PLACEMENT[
                              leg?.bet_description as keyof typeof RACE_PLACEMENT
                            ] ?? leg?.bet_description;
                        } else if (bet.bet_type === 'Blended') {
                          betDescription = 'Racing Win';
                        } else {
                          betDescription = leg?.bet_description ?? '';
                        }

                        if (!!leg?.legs) {
                          header =
                            leg.bet_description === 'Blended'
                              ? 'Blended'
                              : `${leg.legs.length} Leg ${leg.bet_description}`;

                          info =
                            leg?.event_type === 'Race'
                              ? `${betDescription} • ${leg?.event_data?.venue_name} - ${Labels.Race} ${leg?.event_data?.race_number}`
                              : `${leg?.event_data?.match_name}`;
                        } else {
                          header =
                            leg?.event_type === 'Race'
                              ? `${
                                  leg?.event_data?.runner_number
                                }. ${leg?.event_data?.runner_name?.toLowerCase()}`
                              : leg?.event_data?.proposition_name;

                          info =
                            leg?.event_type === 'Race'
                              ? `${betDescription} • ${leg?.event_data?.venue_name} - ${Labels.Race} ${leg?.event_data?.race_number}`
                              : `${leg?.event_data?.market_name} • ${leg?.event_data?.match_name}`;
                        }

                        const legOdds = oddsFormatted(leg?.odds);
                        return (
                          <>
                            <Flex
                              key={`leg-${leg?.bet_leg_id}`}
                              alignItems="start"
                              gap="2"
                              borderBottom={
                                index !== arr.length - 1 ? '1px solid' : ''
                              }
                              borderBottomColor="gray.200"
                              py="2"
                            >
                              <IconSvg
                                name={getIconAssetPath(
                                  'sports',
                                  leg?.event_icon
                                )}
                                sx={{
                                  boxSize: '10',
                                  color: 'gray.500',
                                }}
                              />
                              <Flex
                                flexDir="column"
                                flex="1"
                                fontSize="sm"
                                alignSelf="center"
                              >
                                <Text
                                  fontWeight="semibold"
                                  textTransform="capitalize"
                                >
                                  {header}
                                </Text>
                                <Text>{info}</Text>
                              </Flex>
                              <Flex
                                flexDir="column"
                                fontSize="sm"
                                alignSelf="center"
                              >
                                {leg?.odds && (
                                  <Text
                                    fontWeight="semibold"
                                    textTransform="capitalize"
                                  >
                                    {legOdds === '' ? '' : legOdds}
                                  </Text>
                                )}

                                <Text textTransform="capitalize">
                                  {leg?.status}
                                </Text>
                                {leg?.status === EBetStatus.Settled && (
                                  <Flex alignItems="center">
                                    <Icon
                                      as={
                                        leg?.is_won
                                          ? CheckCircleFill
                                          : CircleWithCross
                                      }
                                      color={
                                        leg?.is_won ? 'green.500' : 'red.500'
                                      }
                                    />
                                    <Text ml="1">
                                      {leg?.is_won ? 'Won' : 'Lost'}
                                    </Text>
                                  </Flex>
                                )}
                              </Flex>
                            </Flex>
                            {leg?.legs?.map((leg, index, arr) => {
                              header =
                                leg?.event_type === 'Race'
                                  ? `${
                                      leg?.event_data?.runner_number
                                    }. ${leg?.event_data?.runner_name?.toLowerCase()}`
                                  : leg?.event_data?.proposition_name;

                              info =
                                leg?.event_type === 'Race'
                                  ? `${getPositionValue(
                                      leg?.event_data?.proposition_type
                                    )}`
                                  : `${leg?.event_data?.market_name}`;

                              return (
                                <Flex
                                  key={`leg-${leg?.bet_leg_id}`}
                                  alignItems="start"
                                  gap="2"
                                  borderBottom={
                                    index !== arr.length - 1 ? '1px solid' : ''
                                  }
                                  borderBottomColor="gray.200"
                                  py="2"
                                  pl="5"
                                >
                                  <IconSvg
                                    name={getIconAssetPath(
                                      'sports',
                                      leg?.event_icon
                                    )}
                                    sx={{
                                      boxSize: '10',
                                      color: 'gray.500',
                                    }}
                                  />
                                  <Flex
                                    flexDir="column"
                                    flex="1"
                                    fontSize="sm"
                                    alignSelf="center"
                                  >
                                    <Text
                                      fontWeight="semibold"
                                      textTransform="capitalize"
                                    >
                                      {header}
                                    </Text>
                                    <Text>{info}</Text>
                                  </Flex>
                                  <Flex
                                    flexDir="column"
                                    fontSize="sm"
                                    alignSelf="center"
                                  >
                                    <Text textTransform="capitalize">
                                      {leg?.status}
                                    </Text>
                                    {leg?.status === EBetStatus.Settled && (
                                      <Flex alignItems="center">
                                        <Icon
                                          as={
                                            leg?.is_won
                                              ? CheckCircleFill
                                              : CircleWithCross
                                          }
                                          color={
                                            leg?.is_won
                                              ? 'green.500'
                                              : 'red.500'
                                          }
                                        />
                                        <Text ml="1">
                                          {leg?.is_won ? 'Won' : 'Lost'}
                                        </Text>
                                      </Flex>
                                    )}
                                  </Flex>
                                </Flex>
                              );
                            })}
                          </>
                        );
                      })}
                    </Box>
                  </SimpleGrid>
                )}
            </>
          </Flex>
        </ModalBody>
      </ModalContent>

      <ModalCloseButton />
    </BCModal>
  );
}
