import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { srcDefaultRunnerSilk } from '@/assets/icons';
import EventExposure from '@/common/components/EventExposure';
import { useAppDispatch } from '@/common/hooks/useRedux';
import {
  EGeneralStatus,
  TRaceDetails,
  TRunner,
  TRunnerResult,
  TMarket,
} from '@/lib/DBModels';
import { setConfigureRunnerModal } from '../../../../../../Services/RaceDetails.slices';
import {
  isScratched,
  isRemoved,
  calculateOddsMarginPercentage,
  getPrice,
} from '../../../../../../Services/RaceDetails.utils';
import {
  BadgeOddsPercentage,
  RunnerNameContainer,
  RunnerSilk,
  tableColHeaderStyle,
} from '../RaceDetailsTable.styles';
import { centsToDollars, getNumberOrdinal } from '@/common/utils';
import toast from 'react-hot-toast';
import { TColumnConfig } from '@/common/components/TableChakra/TableChakra.types';
import {
  ERunnerConfigureOption,
  TRaceDetailsFormValues,
  TRunnerForm,
} from '../RaceDetailsTable.types';
import { parseResettlementData } from './RaceDetailsTable.utils';
import { errorMessageFromHTTPException } from '@/lib/Error';
import { useFormik } from 'formik';
import { useMutationSettleRace } from '@/api/tradeManager/raceDetails/settleRace/settleRace.hooks';

import StatusBadge from '@/common/components/StatusBadge';
import { useMutationRunnerStatus } from '@/api/tradeManager/raceDetails/runnerStatus/runnerStatus.hooks';
import { raceDetailsSchema } from './RaceDetailsTable.config';
import { TRunnerBody } from '@/api/tradeManager/raceDetails/runnerStatus/runnerStatus.types';
import { useMutationRunnerMargins } from '@/api/tradeManager/raceDetails/runnerMargins/runnerMargins.hooks';
import RunnerDetails, {
  TRunnerDetailsAction,
  TRunnerDetailsState,
} from '../components/RunnerDetails/RunnerDetails';
import { loadSwitchState } from '../../../../../ManageRaceConfig/services/ManageRaceConfig.utils';
import WinPlaceConfig from '../../WinPlaceConfig/WinPlaceConfig';
import { EStorageKey } from '@/common/localStorage/localStorage.keys';

const allowedStatus = [
  EGeneralStatus.Open,
  EGeneralStatus.Closed,
  EGeneralStatus.Scratched,
  EGeneralStatus.Removed,
];

const formatStatus = (runner: TRunner) => {
  if (
    runner.status &&
    [
      EGeneralStatus.Abandoned,
      EGeneralStatus.Scratched,
      EGeneralStatus.Voided,
    ].includes(runner.status)
  )
    return EGeneralStatus.Scratched;

  return runner.status;
};

const getInitialValues = (runner?: TRunner) => {
  const rawScratchTime =
    runner?.win_proposition?.scratch_time ??
    runner?.win_proposition?.winter_scratch_time;
  const scratchTime = rawScratchTime ? new Date(rawScratchTime) : null;

  return {
    runner_number: runner?.number ?? 0,
    runner_id: runner?.race_runner_id,
    isOpen: !!(
      runner?.status === EGeneralStatus.Open &&
      !(
        runner?.win_proposition?.lock_status &&
        runner?.win_proposition?.status === EGeneralStatus.Closed
      ) &&
      !(
        runner?.place_proposition?.lock_status &&
        runner?.place_proposition?.status === EGeneralStatus.Closed
      )
    ),
    status: formatStatus(runner ?? {}),
    winterStatus: runner?.winter_status,
    allowedStatus: allowedStatus,
    follow_feed: !runner?.lock_status && !runner?.lock_price,
    isSuspended: runner?.proposition_is_suspended,
    winterIsSuspended: runner?.winter_is_suspended,
    isScratched: !!(
      runner?.status === EGeneralStatus.Scratched ||
      (runner?.lock_status && runner?.status === EGeneralStatus.Voided)
    ),
    date: scratchTime ?? new Date(),
    time:
      scratchTime == null
        ? ''
        : `${scratchTime.getHours()}:${scratchTime.getMinutes()}`,
    win:
      (runner?.win_proposition?.deductions &&
        runner.win_proposition.deductions[0]) ??
      0,
    p2:
      (runner?.place_proposition?.deductions &&
        runner.place_proposition.deductions[0]) ??
      0,
    p3:
      (runner?.place_proposition?.deductions &&
        runner.place_proposition.deductions[1]) ??
      0,
  };
};

export type TPriceModifierReducerState = Record<string, TRunnerDetailsState>;

const getPriceModifierInitialValues = (runners: TRunner[]) =>
  runners.reduce((agg, runner) => {
    if (isScratched(runner)) return agg;
    const runnerId = runner.race_runner_id ?? '';

    agg[runnerId] = {
      race_runner_id: runnerId,
      winModifier: runner.win_proposition?.proposition_modifier?.modifier ?? 0,
      placeModifier:
        runner.place_proposition?.proposition_modifier?.modifier ?? 0,
      winPrice: getPrice(
        runner.win_proposition?.return_amount ?? 0,
        runner.win_proposition?.proposition_modifier?.modifier ?? 0
      ),
      placePrice: getPrice(
        runner.place_proposition?.return_amount ?? 0,
        runner.place_proposition?.proposition_modifier?.modifier ?? 0
      ),
    };

    return agg;
  }, {} as TPriceModifierReducerState);

const priceModifierReducer = (
  state: TPriceModifierReducerState,
  action: TRunnerDetailsAction
) => ({
  ...state,
  [action.race_runner_id]: {
    ...state[action.race_runner_id],
    ...action,
  },
});

export const useRaceTableForm = (
  raceData: TRaceDetails,
  raceResults?: TRunnerResult[]
) => {
  const dispatch = useAppDispatch();

  const [configureOption, setConfigureOption] = useState(
    ERunnerConfigureOption.DisplayOnly
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUpdateButtonEnabled, setIsUpdateButtonEnabled] = useState(false);

  const apiModel: Record<string, TRunnerBody> = useMemo(() => ({}), []);

  const manuallyManageToggleState = loadSwitchState(EStorageKey.ManuallyManage);

  const { mutateAsync: mutateSettleRace } = useMutationSettleRace();
  const { mutateAsync: mutateRunnerStatus } = useMutationRunnerStatus();
  const { mutateAsync: mutateRunnerMargins } = useMutationRunnerMargins();

  const onClickRow = (raceRunner: TRunner) => {
    if (!raceRunner?.number) return;

    const { values, setFieldValue } = formik;

    const initialValues = getInitialValues(raceRunner);

    setFieldValue('runners', {
      ...values.runners,
      [raceRunner.number]: initialValues,
    });

    delete apiModel[raceRunner.race_runner_id ?? ''];
  };

  /* Submit runner configuration and resettle race */
  const onSubmitResettle = async (values: TRaceDetailsFormValues) => {
    try {
      if (!raceData.race_id) return;

      const parsedResettlementData = parseResettlementData({
        raceId: raceData.race_id,
        values,
        raceResults,
      });

      if (!parsedResettlementData) return;

      await mutateSettleRace(parsedResettlementData);
    } catch (error) {
      toast.error(errorMessageFromHTTPException(error));
    }
  };

  /* Entry point for the RunnerConfigureModal onSubmit */
  const onSubmitSettled = async (values?: TRaceDetailsFormValues) => {
    setIsSubmitting(true);
    if (!configureOption || !values) return;

    if (configureOption === ERunnerConfigureOption.Resettle) {
      await onSubmitResettle(values);
    }
    setIsSubmitting(false);
    dispatch(
      setConfigureRunnerModal({
        isOpen: false,
      })
    );
  };

  const sortedRunners = [...((raceData.runners || []) as TRunner[])].sort(
    (a, b) => (a?.number ?? 0) - (b?.number ?? 0)
  );

  const [priceModifierState, priceModifiersDispatch] = useReducer(
    priceModifierReducer,
    sortedRunners,
    getPriceModifierInitialValues
  );

  const onSubmit = async (values: TRaceDetailsFormValues) => {
    const runners = Object.values(apiModel);

    /* If the race has already been settled we give the bookie the option to either resettle or scratch the runner for display only */
    if (raceData.status === EGeneralStatus.Settled) {
      dispatch(setConfigureRunnerModal({ isOpen: true, values }));
      return;
    }

    try {
      if (runners.length != 0) {
        await mutateRunnerStatus(runners);
      }

      const modifierState = Object.values(priceModifierState);
      if (
        modifierState.some((x) => (x as TRunnerDetailsAction).type == 'update')
      ) {
        await mutateRunnerMargins(
          modifierState.map((x: TRunnerDetailsState) => ({
            race_runner_id: x.race_runner_id,
            win_modifier: x.winModifier,
            place_modifier: x.placeModifier,
          }))
        );
      }

      toast.success('Successfully updated runners');
      setIsUpdateButtonEnabled(false);
    } catch (err) {
      toast.error(errorMessageFromHTTPException(err));
    }
  };

  const initialFormikValues = useMemo(
    () => ({
      runners: sortedRunners.reduce<Record<string, TRunnerForm>>(
        (acc, r) => ({ ...acc, [r?.number ?? 0]: getInitialValues(r) }),
        {}
      ),
    }),
    [sortedRunners]
  );

  const formik = useFormik<TRaceDetailsFormValues>({
    initialValues: initialFormikValues,
    validationSchema: raceDetailsSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setIsUpdateButtonEnabled(false);
      await onSubmit(values).finally(() => setSubmitting(false));
    },
  });

  /*
   * This useEffect adds and cleans up keyboard listener.
   */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      /* Update on Enter */
      if (event.key === 'Enter') {
        event.preventDefault();
        formik.submitForm();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [formik]);

  return {
    formik,
    data: sortedRunners,
    configureOption,
    setConfigureOption,
    isManuallyManaged: (
      manuallyManageToggleState as (string | undefined)[]
    ).includes(raceData.race_id),
    isSubmitting,
    onSubmitSettled,
    onClickRow,
    apiModel,
    priceModifierState,
    priceModifiersDispatch,
    isUpdateButtonEnabled,
    setIsUpdateButtonEnabled,
  };
};

export const useColumns = (
  runners: TRunner[],
  priceModifierState: TPriceModifierReducerState,
  priceModifiersDispatch: React.Dispatch<TRunnerDetailsAction>,
  isManuallyManaged: boolean,
  raceMarkets?: TMarket[],
  onMarginChange?: () => void
) => {
  const [selectedModifier, setSelectedModifier] = useState('');

  const runnerStates = isManuallyManaged
    ? priceModifierState
    : getPriceModifierInitialValues(runners);

  const winMarginPercentage = calculateOddsMarginPercentage(
    Object.values(runnerStates).map((x) => x.winPrice)
  );

  const placeMarginPercentage = calculateOddsMarginPercentage(
    Object.values(runnerStates).map((x) => x.placePrice)
  );

  const placeMarket = raceMarkets?.filter(
    (m) => m.market_type === 'Racing Place'
  )[0];

  const winMarket = raceMarkets?.filter(
    (m) => m.market_type === 'Racing Win'
  )[0];

  const getBestResult = (winResult: number) => {
    if (winResult === 0) return 'N/A';

    const filteredBestResult = runners
      .filter((el) => el?.win_result !== 0)
      .map((el) => el?.win_result)
      .sort((a, b) => (b ?? 0) - (a ?? 0));

    const uniqueBestResult: number[] = [];
    const findBestResult = filteredBestResult
      .filter((element) => {
        const isDuplicate = uniqueBestResult.includes(element ?? 0);

        if (!isDuplicate) uniqueBestResult.push(element ?? 0);

        return !isDuplicate;
      })
      .findIndex((p) => p === winResult);

    return getNumberOrdinal(findBestResult + 1);
  };

  const columns: TColumnConfig<TRunner>[] = [
    {
      property: 'display_name',
      render: (datum) => {
        const isSuspended =
          datum.status == EGeneralStatus.Open && datum.is_suspended;

        return (
          <Flex gap="4" alignItems="center">
            <RunnerSilk
              src={datum?.silk_url ?? srcDefaultRunnerSilk}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = srcDefaultRunnerSilk;
              }}
            />

            <RunnerNameContainer>
              <Text
                sx={{
                  whiteSpace: 'nowrap',
                  fontSize: 'md',
                  fontWeight: 'medium',
                  ...((isScratched(datum) || isRemoved(datum)) && {
                    textDecor: 'line-through',
                  }),
                }}
              >
                {`${datum?.number ?? ''}. ${datum?.display_name ?? ''} (${
                  datum?.barrier_number ?? ''
                })`}
              </Text>
            </RunnerNameContainer>
            {datum.status &&
              (datum.status !== EGeneralStatus.Open || isSuspended) && (
                <StatusBadge
                  size="sm"
                  status={datum.status}
                  isSuspended={isSuspended}
                  sx={{ mt: '1' }}
                />
              )}
          </Flex>
        );
      },
      plain: true,
    },
    {
      property: 'runner_details',
      header: (
        <Flex justifyContent="space-between" direction="column">
          <Flex direction="row" justifyContent="space-between">
            <BadgeOddsPercentage>
              {winMarginPercentage + '%'}
            </BadgeOddsPercentage>
            <BadgeOddsPercentage>
              {placeMarginPercentage + '%'}
            </BadgeOddsPercentage>
          </Flex>
          <Flex direction="row" justifyContent="space-between" m="5">
            {isManuallyManaged && winMarket && (
              <WinPlaceConfig raceMarket={winMarket} />
            )}
            {isManuallyManaged && placeMarket && (
              <WinPlaceConfig raceMarket={placeMarket} />
            )}
          </Flex>
        </Flex>
      ),
      render: (data) => (
        <RunnerDetails
          data={data}
          dispatch={priceModifiersDispatch}
          state={priceModifierState[data.race_runner_id ?? '']}
          isManuallyManaged={isManuallyManaged}
          selectedModifier={selectedModifier}
          setSelectedModifier={setSelectedModifier}
          onMarginChange={onMarginChange}
        />
      ),
    },
    {
      property: 'win_bets',
      size: '1',
      header: (
        <Text style={tableColHeaderStyle}>
          <FormattedMessage id="trademanagerpage.common.winbets" />
        </Text>
      ),
      render: (datum) => (
        <Text size="small" px="0">
          {datum?.win_bets ?? ''}
        </Text>
      ),
      sortable: false,
      align: 'center',
    },
    {
      property: 'win_hold',
      size: '1',
      header: (
        <Text style={tableColHeaderStyle}>
          <FormattedMessage id="trademanagerpage.common.winhold" />
        </Text>
      ),
      render: (datum) => (
        <Text size="small" px="0">
          {centsToDollars(datum?.win_hold, true)}
        </Text>
      ),
      sortable: false,
      align: 'center',
    },
    {
      property: 'place_hold',
      size: '1',
      header: (
        <Text style={tableColHeaderStyle}>
          <FormattedMessage id="trademanagerpage.common.placehold" />
        </Text>
      ),
      render: (datum) => (
        <Text size="small" px="0">
          {centsToDollars(datum?.place_hold, true)}
        </Text>
      ),
      sortable: false,
      align: 'center',
    },
    {
      property: 'avg_win_price',
      size: '1',
      header: (
        <Text style={tableColHeaderStyle}>
          <FormattedMessage id="trademanagerpage.common.avgwinprice" />
        </Text>
      ),
      render: (datum) => (
        <Text size="small" px="0">{`${datum?.avg_win_price ?? ''}`}</Text>
      ),
      sortable: false,
      align: 'center',
    },
    {
      property: 'place_bets',
      size: '1',
      header: (
        <Text style={tableColHeaderStyle}>
          <FormattedMessage id="trademanagerpage.common.placebets" />
        </Text>
      ),
      render: (datum) => (
        <Text size="small" px="0">
          {datum?.place_bets ?? ''}
        </Text>
      ),
      sortable: false,
      align: 'center',
    },
    {
      property: 'place_liability',
      size: '1',
      header: (
        <Text style={tableColHeaderStyle}>
          <FormattedMessage id="trademanagerpage.common.placeliability" />
        </Text>
      ),
      render: (datum) => (
        <Text size="small" px="0">
          {centsToDollars(datum?.place_liability, true)}
        </Text>
      ),
      sortable: false,
      align: 'center',
    },
    {
      property: 'biggest_win_bet',
      size: '1',
      header: (
        <Text style={tableColHeaderStyle} px="0" sx={{ '&&': { px: '0' } }}>
          <FormattedMessage id="trademanagerpage.common.biggestwinbet" />
        </Text>
      ),
      render: (datum) => (
        <Text size="small" px="0">
          {centsToDollars(datum?.biggest_win_bet, true)}
        </Text>
      ),
      sortable: false,
      align: 'center',
    },
    {
      property: 'best_result',
      size: '1',
      header: (
        <Text style={tableColHeaderStyle}>
          <FormattedMessage id="trademanagerpage.common.bestresult" />
        </Text>
      ),
      render: (datum) =>
        datum.status === EGeneralStatus.Scratched ? (
          <Text size="small" px="0">
            <FormattedMessage id="trademanagerpage.common.na" />
          </Text>
        ) : (
          <Text size="small" px="0">
            {getBestResult(datum?.win_result ?? 0)}
          </Text>
        ),
      sortable: false,
      align: 'center',
    },
    {
      property: 'win_result',
      size: '1',
      header: (
        <Text style={tableColHeaderStyle}>
          <FormattedMessage id="trademanagerpage.common.winresult" />
        </Text>
      ),
      render: (datum) =>
        datum.status === EGeneralStatus.Scratched ? (
          <Text size="small" px="0">
            <FormattedMessage id="trademanagerpage.common.na" />
          </Text>
        ) : (
          <EventExposure exposure={datum?.win_result} />
        ),
      sortable: false,
      plain: true,
      align: 'center',
    },
  ];

  return {
    columns,
  };
};
