import { TBetsData } from '@/api/punters/punters.types';
import api from '../../api';
import { TMultiBetsParams } from './multi.types';

const multiBetsURL = '/bookie/trade-manager/multi';

// Trade Manager - Multi Bets

export const queryMultiBets = (params: TMultiBetsParams) =>
  api.get<TBetsData[]>(multiBetsURL, { params }).then((res) => res.data);
