export enum EPositions {
  FirstPlaces = 'first_places',
  SecondPlaces = 'second_places',
  ThirdPlaces = 'third_places',
  ForthPlaces = 'forth_places',
  Scratched = 'scratched',
}

export type TSettleRaceBody = {
  race_id: string;
  [EPositions.FirstPlaces]?: string[];
  [EPositions.SecondPlaces]?: string[];
  [EPositions.ThirdPlaces]?: string[];
  [EPositions.ForthPlaces]?: string[];
  [EPositions.Scratched]: string[];
};
