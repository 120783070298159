/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { Box, Divider } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { BetsTable } from '../../../../../../../common/components';
import CounterBetModal from '../modals/CounterBet';
import RejectBetModal from '../modals/RejectBet';
import {
  usePendingRequestsData,
  useApprovedColumns,
} from '../../Services/BetApprovals.hooks';
import {
  centsToDollars,
  getStrings,
  oddsFormatted,
} from '../../../../../../../common/utils';
import { PaginationV3 } from '@/common/components/Pagination/PaginationV3';
import {
  CommonResponse,
  normalizeBetApproval,
} from '../../Services/BetApprovals.utils';
import { isWincore } from '..';
import { usePendingApprovalsStore } from '@/app/components/Providers/ApproveRaces/store';
import { useQuery } from 'react-query';
import api from '@/api/api';
import { ENV } from '@/lib/Constants';
import { TRequestBet } from '../../Services/BetApprovals.types';
import Select from '@/common/components/FormElements/Select';
import { useNavigate } from 'react-router-dom';

export type PunterSchema = {
  cell: string;
  data: CommonResponse;
  render?: boolean;
};

export type ActionSchema = {
  cell: string;
  data: CommonResponse;
};

export type TStickyNoteResponse = {
  punter_id: string;
  punter_name: string;
  punter_storm_score: number;
  sticky_note: {
    updated_at: string;
    updated_by: string;
    text: string;
  };
};

/**
 * Global List wrapper.
 */
export const ListGlobalWrapper = () => {
  const { pendingApprovals, addBulkPendingApprovals } =
    usePendingApprovalsStore();

  useQuery(
    ['betApprovals', 'pending'],
    () =>
      api.get<{
        bet_requests: TRequestBet[];
        paging: { next_offset: string | null };
      }>('/bookie/bet-approvals/global'),
    {
      async onSuccess({ data }) {
        if (!data || data.bet_requests.length === 0) return;
        const requests = await Promise.all(
          data.bet_requests.map(async (r) => {
            const { data: notes } = await api.get<TStickyNoteResponse[]>(
              `${ENV.REACT_APP_API_URL}/bookie/bet-approvals/global/punter-details?punter_ids=${r.punter_id}&source_url=${r.source_api_url}`
            );
            r.sticky_note = notes[0].sticky_note;
            return r;
          })
        );
        addBulkPendingApprovals(requests.map((r) => normalizeBetApproval(r)));
      },
      staleTime: Infinity,
    }
  );

  const columns = useMemo<ColumnDef<CommonResponse>[]>(
    () => [
      {
        header: 'Punter',
        accessorFn: (props) => {
          const punterData: PunterSchema = {
            cell: 'punter',
            data: props,
            render: true,
          };

          return punterData;
        },
      },
      {
        header: 'Market',
        accessorFn: (props) => {
          const market = {
            cell: 'market',
            data: props,
            render: true,
          };
          return market;
        },
      },
      {
        header: 'Notes',
        accessorFn: (props) => {
          return {
            cell: 'notes',
            data: props.stickyNote?.text,
            render: true,
          };
        },
      },
      {
        header: 'Type',
        accessorFn: (props) => {
          const market = {
            cell: 'type',
            data: props,
            render: true,
          };
          return market;
        },
      },
      {
        header: 'Odds',
        accessorFn: (props) => {
          const isRace =
            props.betType === 'Single' &&
            props.betLegs[0].event_type === 'Race';
          let data = oddsFormatted(props.requestedOdds);
          if (isRace) {
            const priceType = isRace && props.betLegs[0].price_type;

            if (priceType === 'tote_single_mid') {
              data = 'MD';
            }

            if (priceType === 'tote_single_best') {
              data = 'BT';
            }

            if (priceType === 'starting') {
              data = 'SP';
            }
          }

          return {
            cell: 'odds',
            data,
            render: true,
          };
        },
      },
      {
        header: 'Stake',
        accessorFn: (props) => ({
          cell: 'stake',
          data: centsToDollars(
            props.requestType === 'Settlement'
              ? props.stake
              : props.requestedStake
          ),
          render: true,
        }),
      },
      {
        header: 'Exposure',
        accessorFn: (props) => {
          const market = {
            cell: 'exposure',
            data: props,
            render: true,
          };
          return market;
        },
      },
      {
        header: 'Return',
        accessorFn: (props) => {
          const actionsData = {
            cell: 'return',
            data: props,
            render: true,
          };

          return actionsData;
        },
      },

      {
        header: 'Action',
        accessorFn: (props) => {
          const actionsData = {
            cell: 'action',
            data: props,
            render: true,
          };

          return actionsData;
        },
      },
    ],
    []
  );

  return <List columns={columns} data={pendingApprovals} isLoading={false} />;
};

// ---

/**
 * Bookie specific list wrapper
 */
export const ListBookieWrapper = () => {
  const addColumns = useApprovedColumns();
  const { isLoading, isLastFetch, data, setOffset, offset, type, setType } =
    usePendingRequestsData();

  return (
    <List
      columns={addColumns}
      isLoading={isLoading}
      isLastFetch={isLastFetch}
      data={data ?? []}
      setOffset={setOffset}
      offset={offset}
      type={type}
      setType={setType}
    />
  );
};

// ---

/**
 * TODO: Fix types
 */
type ListProps = {
  columns: any;
  data: any;
  isLoading: boolean;
  isLastFetch?: any;
  setOffset?: any;
  offset?: any;
  type?: any;
  setType?: any;
};

/**
 * List component
 */
export const List = ({
  columns,
  data,
  isLoading,
  isLastFetch,
  setOffset,
  offset,
}: ListProps) => {
  const navigate = useNavigate();
  const searchFilter = new URLSearchParams(window.location.search).get(
    'filter'
  );

  const filteredData = useMemo(() => {
    if (searchFilter === 'All' || searchFilter === null) return data;
    return ((data.bet_requests || data) ?? []).filter(
      (d: any) =>
        (d.request_type || d.requestType).toLowerCase() ===
        searchFilter?.toLowerCase()
    );
  }, [data, searchFilter]);

  const [
    {
      BetApprovalsPage: { Table: Strings },
    },
  ] = getStrings();

  const [history, setHistory] = useState<string[]>([]);

  return (
    <>
      <CounterBetModal />

      <RejectBetModal />

      <Divider />

      <Box width="full" minH="70vh">
        <Box py="2">
          <Select
            isFormik={false}
            onChange={(e) => {
              if (e.target.value === 'All') {
                navigate('');
              } else {
                navigate('?filter=' + e.target.value);
              }
            }}
            name="request_type"
            value={searchFilter ?? 'All'}
            w="200px"
          >
            <option value="All">All</option>
            <option value="Placement">Placement</option>
            <option value="Settlement">Settlement</option>
          </Select>
        </Box>

        <BetsTable
          columns={columns}
          data={
            Array.isArray(filteredData)
              ? filteredData
              : filteredData?.bet_requests ?? []
          }
          loading={isLoading}
          isApprovalsTable
          placeHolder={Strings.EmptyPending}
        />

        {!isWincore && (
          <PaginationV3
            nextDisabled={isLastFetch}
            offset={offset}
            onPageChange={(newOffset) => setOffset(newOffset)}
            history={history}
            setHistory={setHistory}
            useHistory
            nextOffsetId={!isLastFetch ? data?.paging?.next_offset : undefined}
          />
        )}
      </Box>
    </>
  );
};
