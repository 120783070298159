import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { SwitchConfig, TextConfig } from './styles/ManageRaceConfig.styles';
import { useRaceConfig } from './services/ManageRaceConfig.hooks';
import { EStorageKey } from '@/common/localStorage/localStorage.keys';

export default function ManageRaceConfig() {
  const { manualManage, toggle, openConfigModal } = useRaceConfig();

  return (
    <>
      <Flex flexDir="column" flexWrap="wrap">
        <Flex flex="50%">
          <TextConfig>
            <FormattedMessage id="trademanagerpage.racedetails.manuallymanage" />
          </TextConfig>

          <SwitchConfig
            isChecked={manualManage}
            onChange={() =>
              toggle({ value: manualManage, key: EStorageKey.ManuallyManage })
            }
          />
        </Flex>
      </Flex>
      <Button ml="2" onClick={() => openConfigModal()}>
        <FormattedMessage id="trademanagerpage.racedetails.configure" />
      </Button>
    </>
  );
}
