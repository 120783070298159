import { centsToDollars } from '@/common/utils';
import { TDashboardFilterStates } from '../Dashboard';
import {
  TDashboardDataResponse,
  TDashboardQueryParams,
  TTransactionStatsResponse,
} from '../api/api';
import { StyledIcon } from 'styled-icons/types';
import { Users } from '@styled-icons/heroicons-solid/Users';
import { Bank } from '@styled-icons/remix-line/Bank';
import { DollarCircle } from '@styled-icons/boxicons-solid/DollarCircle';
import { ReceiptMoney } from '@styled-icons/fluentui-system-regular/ReceiptMoney';
import { HandCoin } from '@styled-icons/remix-line/HandCoin';
import { Newspaper } from '@styled-icons/remix-fill/Newspaper';
import { UserCircle } from '@styled-icons/heroicons-solid/UserCircle';
import { PeopleMoney } from '@styled-icons/fluentui-system-regular/PeopleMoney';

export function formatDashboardQueryParams(
  queryParams: TDashboardFilterStates,
  withPC: boolean
): TDashboardQueryParams {
  return {
    start_time: queryParams.startTimestamp,
    end_time: queryParams.endTimestamp,
    ...(queryParams.punterCategory[0] !== 'All' && withPC
      ? { punter_category: queryParams.punterCategory }
      : {}),
    ...(queryParams.betType[0] !== 'All'
      ? { bet_type: queryParams.betType }
      : {}),
    ...(queryParams.eventType[0] !== 'All'
      ? { event_type: queryParams.eventType }
      : {}),
    ...(queryParams.region[0] !== 'All' ? { region: queryParams.region } : {}),
    ...(queryParams.code[0] !== 'All' ? { code: queryParams.code } : {}),
    ...(queryParams.raceType[0] !== 'All'
      ? { race_type: queryParams.raceType }
      : {}),
    ...(queryParams.sportId[0] !== 'All'
      ? { sport_id: queryParams.sportId }
      : {}),
  };
}

export type TRealMoneyAndBonusBetsItem = {
  title: string;
  icon?: StyledIcon;
  colours?: string[];
  count: number | null;
  percentageCount?: number | null;
  value: string[] | string | number | null;
  percentageValue: number | null;
};

export type TRealMoneyAndBonusBets = {
  realMoney: TRealMoneyAndBonusBetsItem[];
  bonusBets: TRealMoneyAndBonusBetsItem[];
};

export function normalizePayload(
  payload: TDashboardDataResponse
): TRealMoneyAndBonusBets {
  return {
    realMoney: [
      {
        title: 'Bets Placed',
        count: null,
        percentageCount: null,
        value: payload.real_money.bets_placed_count.value.toString(),
        percentageValue: payload.real_money.bets_placed_count.percentage_change,
      },
      {
        title: 'Active Bets',
        count: payload.real_money.active_bets_count.value,
        percentageCount: payload.real_money.active_bets_count.percentage_change,
        value: centsToDollars(payload.real_money.active_bets_stake.value).split(
          '.'
        ),
        percentageValue: payload.real_money.active_bets_stake.percentage_change,
      },
      {
        title: 'Settled Bets',
        count: payload.real_money.bets_settled_count.value,
        percentageCount:
          payload.real_money.bets_settled_count.percentage_change,
        value: centsToDollars(
          payload.real_money.bets_settled_payout.value
        ).split('.'),
        percentageValue:
          payload.real_money.bets_settled_payout.percentage_change,
      },
      {
        title: 'Current Balance',
        count: null,
        percentageCount: null,
        value: null,
        percentageValue: null,
      },
      {
        title: 'Profit',
        count: null,
        percentageCount: null,
        value: centsToDollars(payload.real_money.profit.value).split('.'),
        percentageValue: payload.real_money.profit.percentage_change,
      },
      {
        title: 'Margin',
        count: null,
        percentageCount: null,
        value: `${payload.real_money.margin.value}%`,
        percentageValue: payload.real_money.margin.percentage_change,
      },
    ],
    bonusBets: [
      {
        title: 'Bets Placed',
        count: null,
        percentageCount: null,
        value: payload.bonus_bet.bets_placed_count.value.toString(),
        percentageValue: payload.bonus_bet.bets_placed_count.percentage_change,
      },
      {
        title: 'Active Bets',
        count: payload.bonus_bet.active_bets_count.value,
        percentageCount: payload.bonus_bet.active_bets_count.percentage_change,
        value: centsToDollars(payload.bonus_bet.active_bets_stake.value).split(
          '.'
        ),
        percentageValue: payload.bonus_bet.active_bets_stake.percentage_change,
      },
      {
        title: 'Settled Bets',
        count: payload.bonus_bet.bets_settled_count.value,
        percentageCount: payload.bonus_bet.bets_settled_count.percentage_change,
        value: centsToDollars(
          payload.bonus_bet.bets_settled_payout.value
        ).split('.'),
        percentageValue:
          payload.bonus_bet.bets_settled_payout.percentage_change,
      },
      {
        title: 'Current Balance',
        count: null,
        percentageCount: null,
        value: null,
        percentageValue: null,
      },
      {
        title: 'Profit',
        count: null,
        percentageCount: null,
        value: centsToDollars(payload.bonus_bet.profit.value).split('.'),
        percentageValue: payload.bonus_bet.profit.percentage_change,
      },
      {
        title: 'Margin',
        count: null,
        percentageCount: null,
        value: `${payload.bonus_bet.margin.value}%`,
        percentageValue: payload.bonus_bet.margin.percentage_change,
      },
    ],
  };
}

export function normalizeTransactionResponse(
  response?: TTransactionStatsResponse
): TRealMoneyAndBonusBetsItem[] {
  return [
    {
      title: 'New Punters',
      colours: ['#28c76f', '#324b50'],
      count: null,
      icon: Users,
      value: response?.new_punters.toString() ?? '',
      percentageValue: 0,
    },
    {
      title: 'Average Bet',
      icon: Newspaper,
      count: null,
      value: centsToDollars(response?.average_bet_stake?.value),
      percentageValue: response?.average_bet_stake?.percentage_change ?? null,
    },
    {
      title: 'Deposits',
      colours: ['#00bad1', '#30495f'],
      count: response?.deposit_count.value ?? null,
      icon: Bank,
      value: response?.deposit_amount.value ?? 0,
      percentageValue: response?.deposit_amount.percentage_change ?? null,
    },
    {
      title: 'First Deposits',
      colours: ['#ff9f43', '#51454b'],
      icon: DollarCircle,
      count: response?.first_deposit_count.value ?? null,
      value: centsToDollars(response?.first_deposit_amount.value ?? 0),
      percentageValue: response?.first_deposit_amount.percentage_change ?? null,
    },
    {
      title: 'Active Punters',
      colours: ['#00bad1', '#30495f'],
      icon: UserCircle,
      count: null,
      value: '2100',
      percentageValue: 100,
    },
    {
      title: 'Withdrawals',
      colours: ['#ff9f43', '#51454b'],
      icon: ReceiptMoney,
      count: response?.withdrawal_count.value ?? null,
      value: response?.withdrawal_amount.value ?? 0,
      percentageValue: response?.withdrawal_amount.percentage_change ?? null,
    },
    {
      title: 'Average Deposit',
      colours: ['#28c76f', '#324b50'],
      icon: PeopleMoney,
      count: null,
      value: centsToDollars(response?.average_deposit_amount.value),
      percentageValue:
        response?.average_deposit_amount.percentage_change ?? null,
    },
    {
      title: 'First Bets',
      icon: HandCoin,
      count: null,
      value: '$110.00',
      percentageValue: 100,
    },
  ];
}
