import styled from 'styled-components';

export const StatsPanelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0 30px;
  min-height: 120px;
  margin: 10px 0 20px;
`;
