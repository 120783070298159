import { useMutation, UseMutationOptions, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { keys } from '../../api.keys';
import {
  mutateMarketProps,
  queryBetsForMarket,
  queryMarketPropositions,
} from './match';
import { TSettleMarketProps } from './match.types';

export const useMarketPropositions = (marketId: string) => {
  const { data, isLoading, error } = useQuery(
    [keys.riskManager.matchMarketPropositions],
    () => queryMarketPropositions({ market_id: marketId })
  );

  return { data, isLoading, error };
};

export const useBetsForMarket = (marketId: string) => {
  const location = useLocation();
  const isEnabled = location.pathname === '/trade-manager/match-details';

  const { data, isLoading, error } = useQuery(
    [keys.riskManager.matchBetsForMarket, isEnabled],
    () => queryBetsForMarket({ market_id: marketId }),
    { refetchInterval: 60000, enabled: isEnabled }
  );

  return { data, isLoading, error };
};

export const useMutationMarketProps = (
  options?: UseMutationOptions<unknown, unknown, TSettleMarketProps>
) => useMutation((data) => mutateMarketProps(data), options);
