import { readableMarketType } from '@/common/utils';
import { ERequestBetType } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.types';
import { SportTypes } from '@/features/punters/pages/Punter/types';
import { RacingType } from '@/lib/Constants';
import { EPlayerType } from '@/lib/DBModels';

export const PUNTER_LIST_SELECT_LIST = ['All', ...Object.values(EPlayerType)];

export const PUNTER_LIST_SELECT_LIST_V2 = [
  ...Object.values(EPlayerType).map((type) => ({ label: type, value: type })),
];

export const REGION_SELECT_LIST = [
  { label: 'AU & NZ', value: 'AU & NZ' },
  { label: 'International', value: 'International' },
];

export const BET_TYPES_SELECT_LIST = [
  ...Object.values(ERequestBetType).map(([str, ...rest]) => ({
    label: readableMarketType(str.toUpperCase() + rest.join('')),
    value: str.toUpperCase() + rest.join(''),
  })),
];

export const RACING_TYPE_SELECT_LIST = Object.values(RacingType).map(
  (value) => ({
    label: value.replaceAll('_', ' '),
    value: value,
  })
);

export const SPORT_TYPE_SELECT_LIST = SportTypes.map((type) => ({
  label: type,
  value: type,
}));

export const CODE_SELECT_LIST = [
  { label: 'Racing', value: 'Racing' },
  { label: 'Sport', value: 'Sport' },
];
