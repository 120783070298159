import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { centsToDollars } from '../../utils';
import { EBetStatus } from '../../../lib/DBModels';

export type TBetReturnText = TextProps & {
  status?: EBetStatus;
  payout?: number;
};

export default function BetReturnText({
  status,
  payout,
  ...props
}: TBetReturnText) {
  const isLoss = Number(payout) === 0;

  const color = () => {
    if (status === EBetStatus.Settled) {
      return isLoss ? 'red.500' : 'green.500';
    }
    return 'blue.500';
  };

  return (
    <Text color={color()} {...props}>
      {centsToDollars(payout ?? 0).replace(/\.00$/, '')}
    </Text>
  );
}
