import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Menu } from '@styled-icons/entypo/Menu';
import { MoneyDollarCircle } from '@styled-icons/remix-fill/MoneyDollarCircle';
import { Stack } from '@styled-icons/bootstrap/Stack';
import { SwapOutline } from '@styled-icons/evaicons-outline/SwapOutline';
import { Settings } from '@styled-icons/feather/Settings';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import SGMLogo from '@/assets/icons/sgm-icon.svg';
import {
  Icon,
  VStack,
  HStack,
  Button as ButtonChakra,
  Box as BoxChakra,
  Flex,
  Accordion,
  Text,
  Badge,
  Switch,
  Image,
  useDisclosure,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';

import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { centsToDollars, getStrings, logError } from '../../../../common/utils';
import {
  getMarketGroups,
  getMarkets,
  getMatchesOfSameCompetitionTournament,
} from './api';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  LoadingSpinner,
  StartTimeCalculator,
} from '../../../../common/components';
import Select from '../../../../common/components/FormElements/Select';
import {
  FullWidthCard,
  BlueStatIcon,
  GreenStatIcon,
  StatFlexWrapper,
  OrangeStatIcon,
} from './MatchDetails.styles';

import { EGeneralStatus, TMarketGroup, TMatch } from '../../../../lib/DBModels';
import { AppThunkDispatch } from '../../../../app/types';

import { EConfigureLevel, TMarketParams } from './types';
import {
  setSelectedMarketGroup,
  setMatchData,
  setSelectedMarketPropositions,
  setSelectedMarket,
} from './slices';
import MultiBets from '../RaceDetails/components/Markets/MultiBets/MultiBets';
import ModalConfigure from './components/ModalConfigure';
import VoidedMatchBanner from './components/VoidedMatchBanner';
import { useAppSelector } from '../../../../common/hooks/useRedux';
import { useModalConfigure } from './components/ModalConfigure/services/ModalConfigure.hooks';
import { EStatusOptions } from './components/ModalConfigure/components/Status/Status.types';
import { getStatus } from './components/ModalConfigure/components/Status/services/Status.utils';
import { setCustomMarketModal } from '../TradeManager/slices';
import { ECustomMarketModalMode } from '../TradeManager/types';
import CustomMarketModal from '../../components/CustomMarketModal/CustomMarketModal';
import SameGameMulti from './components/SameGameMulti/SameGameMulti';
import MarketDetails from './components/MarketDetails/MarketDetails';
import { EViewType } from './services/MatchDetails.types';
import {
  mutateMatchConfig,
  queryMatchData,
} from '@/api/tradeManager/match/match';
import { keys } from '@/api/api.keys';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import StatusBadge from '../../../../common/components/StatusBadge';
import { useQueryOfferings } from '@/api/settings/system/offerings/offerings.hooks';
import { useMatchConfig } from './services/MatchDetails.hooks';
import { getEGeneralStatus } from './services/MatchDetails.utils';

export const MatchDetails: FC = () => {
  const allowedStatus = [EGeneralStatus.Open, EGeneralStatus.Closed];
  const [params] = useSearchParams();

  const intl = useIntl();
  const [
    {
      TradeManagerPage: { MatchDetailsText },
    },
  ] = getStrings();
  const dispatch: AppThunkDispatch = useDispatch();
  const { onModalConfigureOpen } = useModalConfigure();
  const queryMatchId = params.get('id');
  const marketView = params.get('market');
  const [marketGroups, setMarketGroups] = useState<TMarketGroup[]>([
    {
      market_group_id: 'FOCUS',
      market_group_title: 'FOCUS',
    },
  ]);
  const [relatedMatches, setRelatedMatches] = useState<TMatch[]>();

  const navigate = useNavigate();

  const { matchMarkets, selectedMarketGroup, selectedMarket } = useAppSelector(
    (state) => state.matchDetails
  );

  const queryClient = useQueryClient();

  const { mutate } = useMutation(mutateMatchConfig);
  const { data: matchData } = useQuery(
    [keys.getMatch, queryMatchId],
    () => queryMatchData({ match_id: queryMatchId ?? '' }),
    {
      onSuccess(response) {
        setMatchFollowFeedState(!response.lock_status);
        setLockSettlementState(!response.ignore_winter_settlement);
        setStatusState(response.status);
      },
    }
  );
  const { updateMatch } = useMatchConfig();
  const [matchFollowFeedState, setMatchFollowFeedState] = useState(
    !matchData?.lock_status
  );
  const [lockSettlementState, setLockSettlementState] = useState(
    !matchData?.ignore_winter_settlement
  );
  const [statusState, setStatusState] = useState(matchData?.status);

  const toast = useToast();
  type TToggleType = 'sgm' | 'intercept' | 'cashout';
  const handleConfigChange = (type: TToggleType, value: boolean) => {
    const configUpdate: Api['MatchSettingsDTO'] = {};

    if (type === 'sgm') {
      configUpdate.sgm_disabled = value;
    } else if (type === 'intercept') {
      configUpdate.sgm_intercept_all = value;
    } else if (type === 'cashout') {
      configUpdate.cashout_offered = value;
    }

    mutate(
      {
        match_id: queryMatchId ?? '',
        ...configUpdate,
      },
      {
        onSuccess: async () => {
          toast({
            title: 'Successfully updated!',
            status: 'success',
            position: 'top-right',
            duration: 5000,
          });
          await queryClient.invalidateQueries([keys.getMatch]);
        },
      }
    );
  };
  const handleStatusChange = (status: string) => {
    updateMatch({
      matchUpdateData: {
        match: {
          id: matchData?.match_id || '',
          status: getEGeneralStatus(status),
          lock_status: true,
        },
      },
    });
    setMatchFollowFeedState(false);
    setStatusState(getEGeneralStatus(status));
  };

  const handleFeedChange = () => {
    updateMatch({
      matchUpdateData: {
        match: {
          id: matchData?.match_id || '',
          lock_status: matchFollowFeedState,
        },
      },
    });
    setMatchFollowFeedState((followFeed) => !followFeed);
  };

  const handlelockSettlementChange = () => {
    updateMatch({
      matchUpdateData: {
        match: {
          id: matchData?.match_id || '',
          lock_settlement: lockSettlementState,
        },
      },
    });
    setLockSettlementState((lockSettlement) => !lockSettlement);
  };

  const matchTitle = matchData?.tournament_name
    ? `${matchData.competition_name} - ${matchData.tournament_name}`
    : matchData?.competition_name;

  useEffect(() => {
    (async () => {
      if (queryMatchId) {
        const relatedMatchesData = await getMatchesOfSameCompetitionTournament(
          queryMatchId
        );
        if (!relatedMatchesData) return;
        setRelatedMatches(relatedMatchesData);
      }
    })().catch(logError);
  }, [queryMatchId]);

  useEffect(() => {
    (async () => {
      if (queryMatchId) {
        const matchMarketGroupsData = await getMarketGroups(queryMatchId);
        const focusMarketGroup = {
          market_group_id: 'FOCUS',
          market_group_title: 'FOCUS',
        };
        matchMarketGroupsData.push(focusMarketGroup);
        setMarketGroups(matchMarketGroupsData);
        dispatch(setSelectedMarketGroup(focusMarketGroup));
      }
    })().catch(logError);
  }, [queryMatchId, dispatch]);

  useEffect(() => {
    (async () => {
      if (queryMatchId && selectedMarketGroup) {
        const params: TMarketParams = {
          matchId: queryMatchId,
        };
        if (selectedMarketGroup?.market_group_id) {
          if (selectedMarketGroup?.market_group_title === 'FOCUS') {
            params.marketGroupId = marketGroups?.find(
              (el) => el?.market_group_title === 'Top Markets'
            )?.market_group_id;
            params.isFocus = true;
          } else {
            params.marketGroupId = selectedMarketGroup.market_group_id;
          }
        }
        await dispatch(getMarkets(params));
      }
    })().catch(logError);
  }, [queryMatchId, marketGroups, selectedMarketGroup, dispatch]);

  useEffect(() => {
    if (
      matchMarkets.length > 0 &&
      selectedMarketGroup?.market_group_title === 'FOCUS'
    ) {
      if (!matchMarkets[0].is_focus) {
        dispatch(
          setSelectedMarketGroup(
            marketGroups?.find(
              (el) => el.market_group_title === 'Top Markets'
            ) ?? marketGroups[0]
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchMarkets, marketGroups, dispatch]);

  const [multiView, toggleMultiView] = useState<EViewType>(
    (marketView as EViewType) || EViewType.Others
  );

  // Query Offerings for SGM toggles
  const query = useQueryOfferings({ refetchOnWindowFocus: false });
  const { data: offeringsData } = query;

  const renderMarkets = useCallback(
    () =>
      matchMarkets && (
        <Accordion allowToggle w="full">
          {matchMarkets?.map((x) => (
            <MarketDetails
              market={x}
              selectedMarket={selectedMarket}
              matchData={matchData}
              key={x.market_id}
              matchStatusState={statusState}
            />
          ))}
        </Accordion>
      ),
    [matchData, matchMarkets, selectedMarket, statusState]
  );

  const renderContent = () => {
    if (multiView === EViewType.Multis) return <MultiBets />;
    if (multiView === EViewType.SGM && FEATURE_FLAGS.IS_SGM_ENABLED)
      return <SameGameMulti />;
    if (multiView === EViewType.Others) return renderMarkets();

    return renderMarkets();
  };

  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <ModalConfigure />
      <CustomMarketModal />

      {queryMatchId === null || queryMatchId === '' ? (
        <Navigate to="/trade-manager" />
      ) : (
        <VStack spacing={6} maxWidth="full">
          {matchData?.status === EGeneralStatus.Voided && <VoidedMatchBanner />}
          <FullWidthCard title="">
            <BoxChakra
              sx={{
                background: 'white',
                position: 'sticky',
                top: '-35px',
                zIndex: 'dropdown',
              }}
            >
              <Flex p="2">
                {matchMarkets?.some((market) => market.is_manual) && (
                  <HStack>
                    <Badge colorScheme="blue">
                      <FormattedMessage id="trademanagerpage.matchdetailstext.custommarket" />
                    </Badge>
                    <ButtonChakra
                      variant="ghost"
                      onClick={() =>
                        dispatch(
                          setCustomMarketModal({
                            isOpen: true,
                            mode: ECustomMarketModalMode.Edit,
                            market_id: matchMarkets?.find((m) => m.is_manual)
                              ?.market_id,
                            market_name: matchMarkets?.find((m) => m.is_manual)
                              ?.market_name,
                          })
                        )
                      }
                    >
                      <Icon as={Edit} boxSize="6" />
                    </ButtonChakra>
                  </HStack>
                )}
              </Flex>
              {matchData ? (
                <Flex mb="5">
                  <StatFlexWrapper>
                    <Flex flexDir="column">
                      <Flex flexDir="row">
                        <Flex
                          sx={{
                            borderRadius: 'md',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            w: 'full',
                          }}
                        >
                          {matchData.status && (
                            <StatusBadge
                              status={matchData.status}
                              isManualOverride={
                                getStatus(matchMarkets) !==
                                EStatusOptions.Winter
                              }
                              isSuspended={
                                getStatus(matchMarkets) ===
                                EStatusOptions.Suspend
                              }
                              size="lg"
                              sx={{
                                lineHeight: 'inherit',
                                fontSize: 'xs',
                              }}
                              sxWrapper={{
                                flexDir: 'row',
                                w: 'full',
                                span: { w: 'fit-content', mr: '3' },
                              }}
                            />
                          )}

                          <ButtonChakra
                            p="0"
                            mr="0"
                            colorScheme="unstyled"
                            onClick={() =>
                              onModalConfigureOpen({
                                configureLevel: EConfigureLevel.Match,
                                match: matchData,
                              })
                            }
                            leftIcon={
                              <Icon
                                as={Settings}
                                boxSize="6"
                                color="gray.700"
                              />
                            }
                          />
                        </Flex>
                      </Flex>
                      <BoxChakra>
                        {relatedMatches ? (
                          <>
                            <Flex justifyContent="space-between">
                              <Text fontSize="xs">
                                {matchTitle || MatchDetailsText.FallbackTitle}
                              </Text>
                              <Text>
                                <StartTimeCalculator
                                  date={matchData.start_time}
                                  withStatus={false}
                                />
                              </Text>
                            </Flex>
                            <Select
                              name="relatedMatches"
                              width="400px"
                              fontSize="sm"
                              my="1"
                              placeholder={matchData?.display_name}
                              isFormik={false}
                              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                const relatedMatch = relatedMatches.find(
                                  (el) => el.display_name === e.target.value
                                );

                                setMatchData(relatedMatch);
                                navigate({
                                  search: `?id=${relatedMatch?.match_id ?? ''}`,
                                });
                              }}
                            >
                              {relatedMatches &&
                                relatedMatches.map((el) => (
                                  <option
                                    key={el.match_id}
                                    value={el.display_name}
                                  >
                                    {el.display_name}
                                  </option>
                                ))}
                            </Select>
                          </>
                        ) : (
                          <LoadingSpinner />
                        )}
                      </BoxChakra>
                    </Flex>
                  </StatFlexWrapper>
                  <StatFlexWrapper>
                    <SimpleGrid spacing="10px">
                      <Flex>
                        <Flex
                          sx={{
                            borderRadius: 'md',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '3',
                          }}
                        >
                          <GreenStatIcon as={Stack} />
                          <Flex flexDir="column">
                            <Text sx={{ lineHeight: '1', fontWeight: 'bold' }}>
                              {matchData?.match_stats?.total_bets ?? ''}
                            </Text>
                            <Text>Bets</Text>
                          </Flex>
                        </Flex>

                        <Flex
                          sx={{
                            borderRadius: 'md',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '3',
                          }}
                        >
                          <GreenStatIcon as={SwapOutline} />
                          <Flex flexDir="column">
                            <Text sx={{ lineHeight: '1', fontWeight: 'bold' }}>
                              {centsToDollars(
                                matchData?.match_stats?.turnover ?? 0
                              )}
                            </Text>
                            <Text>Turnover</Text>
                          </Flex>
                        </Flex>
                      </Flex>

                      <Flex>
                        <Flex
                          sx={{
                            borderRadius: 'md',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '3',
                          }}
                        >
                          <BlueStatIcon as={Stack} />
                          <Flex flexDir="column">
                            <Text sx={{ lineHeight: '1', fontWeight: 'bold' }}>
                              {matchData?.match_stats?.sgm_total_bets ?? 0}
                            </Text>
                            <Text>SGM Bets</Text>
                          </Flex>
                        </Flex>

                        <Flex
                          sx={{
                            borderRadius: 'md',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '3',
                          }}
                        >
                          <BlueStatIcon as={SwapOutline} />
                          <Flex flexDir="column">
                            <Text sx={{ lineHeight: '1', fontWeight: 'bold' }}>
                              {centsToDollars(
                                matchData?.match_stats?.sgm_turnover ?? 0
                              )}
                            </Text>
                            <Text>SGM Turnover</Text>
                          </Flex>
                        </Flex>
                      </Flex>

                      <Flex>
                        <Flex
                          sx={{
                            borderRadius: 'md',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '3',
                            minW: '180px',
                          }}
                        >
                          <OrangeStatIcon as={Stack} />
                          <Flex flexDir="column">
                            <Text sx={{ lineHeight: '1', fontWeight: 'bold' }}>
                              {matchData?.match_stats?.cashed_out_bets ?? 0}
                            </Text>
                            <Text>Cash Out Bets</Text>
                          </Flex>
                        </Flex>

                        <Flex
                          sx={{
                            borderRadius: 'md',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '3',
                            minW: '210px',
                          }}
                        >
                          <OrangeStatIcon as={SwapOutline} />
                          <Flex flexDir="column">
                            <Text sx={{ lineHeight: '1', fontWeight: 'bold' }}>
                              {centsToDollars(
                                matchData?.match_stats?.cash_out_turnover ?? 0
                              )}
                            </Text>
                            <Text>Cash Out Turnover</Text>
                          </Flex>
                        </Flex>

                        <Flex
                          sx={{
                            borderRadius: 'md',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '3',
                            minW: '170px',
                          }}
                        >
                          <OrangeStatIcon as={MoneyDollarCircle} />
                          <Flex flexDir="column">
                            <Text sx={{ lineHeight: '1', fontWeight: 'bold' }}>
                              {centsToDollars(
                                matchData?.match_stats?.cash_out_profit ?? 0
                              )}
                            </Text>
                            <Text>Cash Out P&L</Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    </SimpleGrid>
                  </StatFlexWrapper>
                  <StatFlexWrapper>
                    <Flex
                      sx={{
                        borderRadius: 'md',
                        gap: '3',
                        alignItems: 'start',
                        p: '3',
                        flexDir: 'column',
                      }}
                    >
                      <Flex flexDir="row" gap="2" alignItems="center">
                        {FEATURE_FLAGS.IS_SGM_ENABLED && (
                          <Switch
                            id="sgm-toggle"
                            defaultChecked={matchData?.sgm_disabled}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleConfigChange('sgm', e.target.checked)}
                            isDisabled={!offeringsData?.data.sgm}
                          />
                        )}
                        <Badge>SGM Disabled</Badge>
                      </Flex>

                      <Flex flexDir="row" gap="2" alignItems="center">
                        {FEATURE_FLAGS.IS_SGM_ENABLED && (
                          <Switch
                            id="all-sgms-to-approval"
                            defaultChecked={matchData?.sgm_intercept_all}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleConfigChange('intercept', e.target.checked)
                            }
                            isDisabled={!offeringsData?.data.sgm}
                          />
                        )}
                        <Badge>All SGMs to Approval</Badge>
                      </Flex>

                      <Flex flexDir="row" gap="2" alignItems="center">
                        <Switch
                          id="cashout-toggle"
                          defaultChecked={matchData?.sport_cashout_offered}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleConfigChange('cashout', e.target.checked)
                          }
                          isDisabled={!offeringsData?.data.cashout}
                        />
                        <Badge>Cash Out Enabled</Badge>
                      </Flex>
                    </Flex>
                  </StatFlexWrapper>
                  <StatFlexWrapper>
                    <Flex
                      sx={{
                        borderRadius: 'md',
                        gap: '3',
                        alignItems: 'start',
                        p: '3',
                        flexDir: 'column',
                      }}
                    >
                      <Flex flexDir="row" gap="2" alignItems="center">
                        <Switch
                          isChecked={matchFollowFeedState}
                          onChange={handleFeedChange}
                        />
                        <Text>
                          <FormattedMessage id="trademanagerpage.common.followFeedStatus" />
                        </Text>
                      </Flex>
                      <Flex flexDir="row" gap="2" alignItems="center">
                        <Switch
                          isChecked={lockSettlementState}
                          onChange={handlelockSettlementChange}
                        />
                        <Text>
                          <FormattedMessage id="trademanagerpage.common.settleByFeed" />
                        </Text>
                      </Flex>
                      <Flex width="100%">
                        <Select
                          isFormik={false}
                          name="status-dropdown"
                          value={
                            statusState && !allowedStatus.includes(statusState)
                              ? EGeneralStatus.Closed
                              : statusState
                          }
                          onChange={(e) => handleStatusChange(e.target.value)}
                          sxWrapper={{
                            maxW: '170px',
                          }}
                          sx={{
                            bg: 'white',
                            w: 'full',
                            h: 'full',
                            p: '3',
                          }}
                        >
                          {allowedStatus.map(
                            (status: EGeneralStatus, idx: number) => (
                              <option key={idx} value={status}>
                                {status}
                              </option>
                            )
                          )}
                        </Select>
                      </Flex>
                    </Flex>
                  </StatFlexWrapper>
                </Flex>
              ) : (
                <LoadingSpinner />
              )}
              <Flex sx={{ p: '2', gap: '2' }}>
                <ButtonChakra
                  colorScheme={isOpen ? 'messenger' : 'gray'}
                  rightIcon={<Icon as={Menu} />}
                  onClick={onToggle}
                >
                  {selectedMarketGroup
                    ? selectedMarketGroup.market_group_title
                    : 'Markets'}
                </ButtonChakra>
                <ButtonChakra
                  colorScheme={
                    multiView === EViewType.Multis ? 'messenger' : 'gray'
                  }
                  onClick={() =>
                    dispatch(setSelectedMarketGroup(undefined)) &&
                    toggleMultiView(EViewType.Multis)
                  }
                >
                  Multis
                </ButtonChakra>
                {!!matchData?.match_stats?.sgm_total_bets && (
                  <ButtonChakra
                    sx={{
                      img: {
                        filter:
                          multiView === EViewType.SGM &&
                          'grayscale(100%) brightness(1000%)',
                      },
                    }}
                    colorScheme={
                      multiView === EViewType.SGM ? 'messenger' : 'gray'
                    }
                    onClick={() =>
                      dispatch(setSelectedMarketGroup(undefined)) &&
                      toggleMultiView(EViewType.SGM)
                    }
                  >
                    <Image src={SGMLogo} />
                  </ButtonChakra>
                )}
              </Flex>
              {/* {marketGroups && <MarketGroups markets={marketGroups} />} */}
              {marketGroups ? (
                isOpen && (
                  <BoxChakra>
                    <BoxChakra sx={{ bg: 'white', p: '2', gap: '2' }}>
                      {marketGroups.map((x) => (
                        <ButtonChakra
                          value={x.market_group_id}
                          key={x.market_group_id}
                          colorScheme="gray"
                          textTransform="capitalize"
                          fontSize="xs"
                          p="2"
                          m="1"
                          w="260px"
                          onClick={() => {
                            dispatch(setSelectedMarketGroup(x));
                            dispatch(setSelectedMarket(undefined));
                            dispatch(setSelectedMarketPropositions([]));
                            toggleMultiView(EViewType.Others);
                            onToggle();
                          }}
                        >
                          {(x.market_group_title !== 'OTHER' &&
                            x.market_group_title?.replaceAll(/[_-]/g, ' ')) ||
                            (x.market_group_title === 'OTHER' &&
                              intl.formatMessage({
                                id: 'trademanagerpage.bysporttab.othermarkets',
                              }))}
                        </ButtonChakra>
                      ))}
                    </BoxChakra>
                  </BoxChakra>
                )
              ) : (
                <LoadingSpinner />
              )}
            </BoxChakra>
            {renderContent()}
          </FullWidthCard>
        </VStack>
      )}
    </>
  );
};

export default MatchDetails;
