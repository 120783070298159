/* eslint-disable @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-argument */
import dayjs from 'dayjs';
import STRINGS from '../localisation/Strings/en';
import {
  EInividualMarketTypes,
  FIREBASE_CONFIG,
  ordinals,
} from '../lib/Constants';

import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { TMarket, TPriceType } from '@/lib/DBModels';

export const getStrings = (): [typeof STRINGS] => [STRINGS];

dayjs.extend(utc);
dayjs.extend(advancedFormat);

export const getIconAssetPath = (
  folder: string,
  sport: string | undefined
): string =>
  `${folder}/${(sport ?? 'default').replace(' ', '-')?.toLowerCase()}`;

export const dateFormatter = (
  date: string | undefined,
  format: string
): string => {
  if (!date) return '';
  const time = dayjs.utc(date).local().format(format);
  return time;
};

export const utcToLocalTime = (date: string | undefined): string => {
  const time = dayjs.utc(date).local().format();
  return time;
};

export const sportSum = (
  globalSportModifer: number | undefined,
  sportModifier: number | undefined
): number => {
  if (globalSportModifer === undefined || sportModifier === undefined)
    throw new Error('Can not calculate sportSum - null data');
  return globalSportModifer + sportModifier;
};

// Calculate the sum for comp modifier.
export const competitionSum = (
  globalSportModifer: number | undefined,
  sportModifier: number | undefined,
  compModifier: number | undefined
): number => {
  if (
    globalSportModifer === undefined ||
    sportModifier === undefined ||
    compModifier === undefined
  )
    throw new Error('Can not calculate competitionSum - null data');

  return globalSportModifer + sportModifier + compModifier;
};

// Calculate the sum for raceType modifier.
export const raceTypeSum = (
  globalRaceModifer: number,
  raceTypeModifier: number
): number => globalRaceModifer + raceTypeModifier;

// Calculate the sum for venue modifier.
export const venueSum = (
  globalRaceModifer: number,
  raceTypeModifier: number,
  venueModifier: number
): number => globalRaceModifer + raceTypeModifier + venueModifier;

export const deepClone = (data: unknown): unknown =>
  JSON.parse(JSON.stringify(data));

// file.jpg => jpg
export const getfileExt = (fileName: string): string | undefined =>
  fileName.split('.').pop()?.toLowerCase();

// Get the icon for the file type.
export const getFileTypeIcon = (
  name: string,
  docPreviewIcon: string,
  imagePreviewIcon: string
): string => {
  const ext = getfileExt(name);

  let image = imagePreviewIcon;
  switch (ext) {
    case 'png':
    case 'jpg':
    case 'jpeg':
      image = imagePreviewIcon;
      break;
    case 'pdf':
      image = docPreviewIcon;
      break;
    default:
  }
  return image;
};

export const oddsFormatted = (odds?: number | null) =>
  odds
    ? `${odds?.toLocaleString('au-AU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`
    : '';

export const centsToDollarsNumber = (cents: number | undefined): number => {
  if (cents === undefined) return 0;
  return cents / 100;
};

export const centsToDollars = (
  cents: number | undefined, // 👀 Handles undefined as values from obj type TDeepPartial will be passed in - returns empty string if so
  withCurrencySymbol = true
): string => {
  if (cents === undefined) return '';
  const dollars = cents / 100;
  let dollarString = '';
  if (withCurrencySymbol) {
    dollarString = dollars.toLocaleString('en-AU', {
      style: 'currency',
      currency: 'AUD',
    });
  } else {
    dollarString = dollars.toLocaleString('en-AU', {
      minimumFractionDigits: 2,
    });
  }

  return dollarString;
};

export const dollarsToCents = (dollars: number | string) =>
  (parseFloat(String(dollars).replace(/,/g, '')) * 100).toString();

// Adds orginal to number value. Can take an arg of number or string and will return a string
// getNumberOrdinal('2') => '2nd', getNumberOrdinal(3) => '3rd'
export const getNumberOrdinal = (num: number | string | undefined): string => {
  // 👀 Handles undefined as values from obj type TDeepPartial will be passed in - returns empty string if so
  if (!num) return '';

  const numString = num.toString();
  const digits = numString.split('');
  const lastDigit = digits.slice(-1)[0];
  if (lastDigit === '1' || lastDigit === '2' || lastDigit === '3') {
    return `${numString}${ordinals[lastDigit]}`;
  }
  return `${numString}${ordinals.other}`;
};

export const logError = (error: Error | string | unknown): void => {
  // eslint-disable-next-line no-console
  console.log(error);
};

export const getPlaceFromNumber = (place: number): string => {
  if (place === 1) return '1st';
  if (place === 2) return '2nd';
  if (place === 3) return '3rd';
  return `${place}th`;
};

export const betReturnCalculator = (
  stake: number | undefined,
  odds: number | undefined,
  isBonus?: boolean
): number => {
  if (!(stake && odds)) return 0;
  return isBonus ? stake * odds - stake : stake * odds;
};

export const resultedBetReturnCalculator = (
  status: string | undefined,
  is_won: boolean | undefined,
  stake: number | undefined,
  odds: number | undefined,
  is_bonus: boolean | undefined,
  bonus_stake: number | undefined
): number => {
  if (status?.toLocaleLowerCase() === 'voided') return stake ?? 0;
  if (is_won === false || !(stake && odds)) return 0;
  if (is_bonus) return (bonus_stake ?? 0) * (odds ? odds - 1 : 0);
  return stake * odds;
};

export const exposureCalculator = (
  stake: number | undefined,
  odds: number | undefined
): number => {
  if (!(stake && odds)) return 0;
  return stake * odds - stake;
};

export const potentialReturnCalculator = (
  status?: string,
  is_won?: boolean,
  stake?: number,
  bonus_bet?: boolean,
  odds?: number
): number => {
  if (status?.toLocaleLowerCase() === 'voided') return stake ?? 0;
  if (!is_won) return 0;
  if (!(stake && odds)) return 0;
  if (bonus_bet) return stake * odds - stake;
  return stake * odds;
};

export const isEventCurrent = (eventStartDate: string | undefined): boolean => {
  if (eventStartDate !== undefined) {
    const today = new Date();
    const raceTime = new Date(eventStartDate);
    return today < raceTime;
  }
  return true;
};

const projectId = FIREBASE_CONFIG.projectId?.split('-')[0].toLowerCase();

export const getURLBasedOnLocation = (platform: string) => {
  if (FEATURE_FLAGS.IS_DEV) {
    // Return local URL
    return 'dev.betcloud.dev/dev';
  }
  if (FEATURE_FLAGS.IS_QAT) {
    // Return qat URL
    return 'qat.betcloud.dev/qat';
  }
  if (FEATURE_FLAGS.IS_STAGING) {
    if (projectId?.includes('wbstag')) {
      return `staging.release.betcloud.dev/${projectId}`;
    } else {
      return `staging.hotfix.betcloud.dev/${projectId}`;
    }
    // Return qat URL
  }
  if (FEATURE_FLAGS.IS_PERF) {
    // Return perf URL
    return 'perf.betcloud.dev/perf';
  }
  if (FEATURE_FLAGS.IS_BAT) {
    // Return bat URL
    return 'bat.betcloud.dev/bat';
  }
  // Default PROD URL if none of the conditions match
  return `${platform}.com.au/${platform}`;
};

export function downloadFile(file: File) {
  const $link = document.createElement('a');

  $link.href = URL.createObjectURL(file);
  $link.download = file.name;
  $link.style.display = 'none';

  document.body.appendChild($link);
  $link.click();

  // Clean-up
  URL.revokeObjectURL($link.href);
  $link.parentNode?.removeChild($link);
}

export const getMarketByType = (
  markets: TMarket[],
  marketType: EInividualMarketTypes
) => {
  const market = markets.find(({ market_type }) => market_type === marketType);
  return market;
};

export const getPrettyPriceType = (priceType?: TPriceType): string => {
  if (!priceType) return '';

  return priceType.split('_').join(' ').toUpperCase();
};

export const getPositionValue = (position: string): string | undefined => {
  const POSITION_MAP: Record<string, string> = {
    Win: 'Win',
    Top1: 'Win',
    Top2: 'Top 2',
    Top3: 'Top 3',
    Top4: 'Top 4',
  };

  return POSITION_MAP[position];
};

export const readableMarketType = (type: string) => {
  // Switch statement to handle different market types
  switch (type) {
    case 'SRMulti':
      return 'Same Race Multi';
    case 'Blended':
      return 'Blended';
    case 'SGMulti':
      return 'Same Game Multi';
    case 'Multi':
      return 'Multi';
    default:
      return type;
  }
};
