import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Select,
  Stack,
  useToast,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FormikErrors, useFormik } from 'formik';
import Papa from 'papaparse';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOutletContext } from 'react-router';
import { useMutationPromos } from '@/api/bulkPromoUpload/bulkPromoUpload.hooks';
import {
  TMutatePromosBody,
  TPromoPunter,
} from '@/api/bulkPromoUpload/bulkPromoUpload.types';
import DateTimePicker from '@/common/components/FormElements/DateTimePicker/DateTimePicker';
import { Dropzone } from '../Dropzone/Dropzone';
import { TPromoType } from '@/api/promos/promo/promotion.types';

type TImportCSVModalFormProps = {
  promoType: TPromoType;
  validate: (
    val: TMutatePromosBody
  ) => void | object | Promise<FormikErrors<TMutatePromosBody>>;
  parsePromos: (val: TPromoPunter[]) => TPromoPunter[];
  children: React.ReactNode;
  onSuccess?: () => void;
};

export const ImportCSVModalForm = ({
  promoType,
  parsePromos,
  onSuccess,
  validate,
  children,
}: TImportCSVModalFormProps) => {
  const intl = useIntl();
  const { isOpen, onClose } = useOutletContext<ModalProps>();
  const toast = useToast();

  const mutation = useMutationPromos({
    onError: ({ response }) => {
      const { user_msg } = response?.data.detail ?? {};

      if (!user_msg) {
        return;
      }

      toast.closeAll();
      toast({
        title: intl.formatMessage({
          id: 'generic.errorTitle',
        }),
        description: user_msg,
        status: 'error',
        position: 'top',
        duration: 9_000,
      });
    },
    onSuccess: () => {
      toast({
        title: intl.formatMessage({
          id: 'promomanagerpage.importcsvsuccesstitle',
        }),
        description: intl.formatMessage({
          id: 'promomanagerpage.importcsvsuccessdescription',
        }),
        status: 'success',
        position: 'top',
        duration: 9_000,
      });
      onSuccess?.();
    },
    onSettled: () => {
      handleClose();
    },
  });

  const reasonOptions: string[] = [
    'Deposit Match',
    'Bonus Back for 2nd',
    'Loyalty Bonus',
  ];

  const {
    errors,
    getFieldProps,
    handleBlur,
    handleSubmit,
    isSubmitting,
    resetForm,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
  } = useFormik<TMutatePromosBody>({
    initialValues: {
      name: '',
      file_name: '',
      reason: '',
      promo_type: promoType,
      promo_punters: [],
      expiry: undefined,
    },
    validate,
    onSubmit: (values) => {
      const parsedValues = {
        ...values,
        promo_punters: parsePromos(values.promo_punters),
      };
      mutation.mutate(parsedValues);
    },
  });

  // State-derived values
  const { promo_punters } = values;
  const totalPunters = promo_punters.length;

  function getRawMaxValue(items: TPromoPunter[]): number {
    return items.reduce((acc, item) => {
      if (item.amount && 'amount' in item) {
        return acc + item.amount;
      } else if (item.max_amount) {
        return acc + item.max_amount;
      }
      return acc;
    }, 0);
  }

  const maxValue = intl.formatNumber(getRawMaxValue(promo_punters), {
    style: 'currency',
    currency: 'AUD',
  });

  const handleClose = () => {
    onClose();
    resetForm();
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        Papa.parse(file, {
          complete: ({ data }) => {
            setFieldValue('file_name', file.name);
            // ! Touched must be set before value
            setFieldTouched('promo_punters');
            setFieldValue('promo_punters', data);
          },
          header: true,
          skipEmptyLines: true,
          transform: (value, field) =>
            field === 'percentage' ||
            field === 'max_amount' ||
            field === 'amount'
              ? Number(value)
              : value,
        });
      });
    },
    [setFieldTouched, setFieldValue]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>
            <FormattedMessage id="promomanagerpage.importcsv" />
          </ModalHeader>
          <ModalCloseButton onClick={handleClose} />
          <Stack as={ModalBody} spacing={4}>
            <FormControl
              isInvalid={!!touched.promo_punters && !!errors.promo_punters}
            >
              <VisuallyHidden as={FormLabel}>
                <FormattedMessage id="promomanagerpage.filelabel" />
              </VisuallyHidden>
              <Dropzone
                accept=".csv"
                maxFiles={1}
                multiple={false}
                onDrop={handleDrop}
              />
              <FormErrorMessage>
                {typeof errors.promo_punters === 'string' &&
                  errors.promo_punters}
              </FormErrorMessage>
              <Stack as={FormHelperText}>
                {!!touched.promo_punters && !errors.promo_punters ? (
                  <Alert status="info">
                    <AlertIcon />
                    <FormattedMessage
                      id="promomanagerpage.potentialvaluealert"
                      values={{ totalPunters, maxValue }}
                    />
                  </Alert>
                ) : (
                  <>
                    <div>
                      <FormattedMessage id="promomanagerpage.filehelpertext" />
                    </div>
                    {children}
                  </>
                )}
              </Stack>
            </FormControl>
            <FormControl isInvalid={touched.name && !!errors.name}>
              <FormLabel>
                <FormattedMessage id="promomanagerpage.namelabel" />
              </FormLabel>
              <Input {...getFieldProps('name')} />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            {promoType === 'deposit_match' && (
              <FormControl isInvalid={touched.expiry && !!errors.expiry}>
                <FormLabel>
                  <FormattedMessage id="promoManagerPage.expiryLabel" />
                </FormLabel>
                <DateTimePicker
                  disableClock
                  minDate={new Date()}
                  name="expiry"
                  value={values.expiry ? new Date(values.expiry) : undefined}
                  wrapperProps={{ h: '10' }}
                  onCalendarClose={() => handleBlur('expiry')}
                  onCalendarOpen={() => setFieldTouched('expiry')}
                  onChange={(value) =>
                    setFieldValue('expiry', value === null ? undefined : value)
                  }
                />
                <FormErrorMessage>{errors.expiry}</FormErrorMessage>
              </FormControl>
            )}
            {promoType === 'bonus_bet' && (
              <FormControl isInvalid={touched.reason && !!errors.reason}>
                <FormLabel>Reason</FormLabel>
                <Select
                  value={values.reason}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setFieldValue(
                      'reason',
                      e.target.value === null ? undefined : e.target.value
                    )
                  }
                >
                  <option disabled></option>
                  {reasonOptions.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.reason}</FormErrorMessage>
              </FormControl>
            )}
          </Stack>
          <ModalFooter>
            <Button variant="ghost" mr="3" onClick={handleClose}>
              <FormattedMessage id="promomanagerpage.cancelbutton" />
            </Button>
            <Button type="submit" isLoading={isSubmitting}>
              <FormattedMessage id="promomanagerpage.confirmbutton" />
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
