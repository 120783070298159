import api from '../../../api';
import { TSuspendPropositionBody } from './suspendProposition.types';

export const suspendPropositionURL =
  '/bookie/trade-manager/sport-details/suspend-proposition';

// Patch: Suspend Proposition
export const mutateSuspendProposition = (body: TSuspendPropositionBody) =>
  api
    .patch<TSuspendPropositionBody>(suspendPropositionURL, body)
    .then((res) => res.data);
