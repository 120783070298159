import React from 'react';
import {
  AbsoluteCenter,
  Box,
  Text,
  Divider,
  Flex,
  Icon,
  Progress,
  chakra,
  Skeleton,
} from '@chakra-ui/react';
import { ReceiptMoney } from '@styled-icons/fluentui-system-regular/ReceiptMoney';
import { Bank } from '@styled-icons/remix-line/Bank';

import { TRealMoneyAndBonusBetsItem } from '../utils/utils';
import { centsToDollars } from '@/common/utils';
import { PercentageBadge } from './PercentageBadge';

interface ITransactionStatsProps {
  transactions?: TRealMoneyAndBonusBetsItem[];
  isLoading: boolean;
}

export function TransactionStats({
  isLoading,
  transactions,
}: ITransactionStatsProps) {
  const deposits = transactions?.find((trans) => trans.title === 'Deposits');
  const withdrawals = transactions?.find(
    (trans) => trans.title === 'Withdrawals'
  );

  const cashflow: number = withdrawals?.value + deposits?.value;
  const profit: number = deposits?.value - withdrawals?.value;

  const percentage =
    (parseFloat(deposits?.value ?? 0) / parseFloat(cashflow)) * 100;

  if (!transactions && !isLoading) return null;

  return (
    <Flex gap="3" flexWrap="wrap" w="full" px="0" py="3">
      {isLoading ? (
        <Flex
          sx={{
            minWidth: '450px',
            h: '270px',
            borderRadius: 'sm',
            boxShadow:
              '0px 10px 20px 0px #000000, 0px 0px 5px 1px #094C8B inset, 0px 0px 0px 1px #36C4FF inset',
            transition: 'all 0.3s ease-in-out',
          }}
        >
          <Skeleton
            height="full"
            w="full"
            startColor="#0c264c"
            endColor="#01060d"
          />
        </Flex>
      ) : (
        <Box
          bg="transparent"
          color="white"
          p="3"
          py="4"
          sx={{
            minWidth: '450px',
            boxShadow:
              '0px 10px 20px 0px #000000, 0px 0px 5px 1px #094C8B inset, 0px 0px 0px 1px #36C4FF inset',
          }}
        >
          <Flex pos="relative">
            <Box>
              <Text
                sx={{
                  fontWeight: '500',
                  fontSize: '16px',
                  mb: '1.5',
                  color: '#ccc',
                }}
              >
                Cashflow
              </Text>
              {isLoading ? (
                <Skeleton h="25px" mb="4" w="full" />
              ) : (
                <Text
                  mb="4"
                  fontSize="25px"
                  color={
                    profit > 0 ? '#29c770' : profit === 0 ? '#ccc' : '#d90e0e'
                  }
                >
                  {centsToDollars(profit)}
                </Text>
              )}
            </Box>
            <PercentageBadge
              value={percentage}
              sx={{ pos: 'absolute', right: '5px' }}
            />
          </Flex>

          <Flex justifyContent="space-between">
            <Box>
              <Flex my="2" alignItems="end">
                <Icon
                  as={Bank}
                  sx={{
                    color: '#28c76f',
                    bg: '#324b50',
                    p: '1',
                    boxSize: '8',
                    borderRadius: 'md',
                    mr: '2',
                  }}
                />
                <Text fontSize="16px">Deposits ({deposits?.count})</Text>
              </Flex>
              <Text>{centsToDollars(Number(deposits?.value))}</Text>
              <PercentageBadge value={deposits?.percentageValue ?? 0} />
            </Box>
            <Box position="relative" padding="10">
              <Divider
                colorScheme="blackAlpha"
                orientation="vertical"
                pos="absolute"
                h="100%"
                top="0"
                borderColor="#4261ee"
              />
              <AbsoluteCenter
                bg="origin.500"
                borderRadius="full"
                color="white"
                p="1"
                textAlign="center"
                boxSize="20px"
                fontSize="10px"
                lineHeight="1"
              >
                vs
              </AbsoluteCenter>
            </Box>
            <Box>
              <Flex my="2" textAlign="right" alignItems="end">
                <Text fontSize="16px">
                  Withdrawals{' '}
                  {`${
                    withdrawals?.count !== 0 ? `(${withdrawals?.count})` : ''
                  }`}
                </Text>
                <Icon
                  as={ReceiptMoney}
                  sx={{
                    color: '#d90e0e',
                    bg: '#51454b',
                    p: '1',
                    boxSize: '8',
                    borderRadius: 'md',
                    ml: '2',
                  }}
                />
              </Flex>
              <Text textAlign="right">
                {centsToDollars(Number(withdrawals?.value))}{' '}
              </Text>
              <PercentageBadge
                sx={{ ml: 'auto', mr: '0' }}
                value={withdrawals?.percentageValue ?? 0}
              />
            </Box>
          </Flex>
          <Progress
            isAnimated
            bg="#d90e0e"
            mt="8"
            borderRadius="sm"
            value={percentage}
            sx={{ '& > div': { bg: '#28c76f' } }}
          />
        </Box>
      )}
      <Flex gap="3" flexWrap="wrap" flex="1">
        {isLoading &&
          Array.from({ length: 6 }, (_, i) => (
            <Flex
              key={i}
              sx={{
                flex: '1 0 30%',
                w: 'fit-content',
                borderRadius: 'sm',
                boxShadow:
                  '0px 10px 20px 0px #000000, 0px 0px 5px 1px #094C8B inset, 0px 0px 0px 1px #36C4FF inset',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <Skeleton
                height="full"
                w="full"
                startColor="#0c264c"
                endColor="#01060d"
              />
            </Flex>
          ))}
        {transactions
          ?.filter(
            ({ title }) => title !== 'Withdrawals' && title !== 'Deposits'
          )
          .map(({ title, icon, count, value, percentageValue, colours }) => {
            return (
              <Flex
                key={title}
                sx={{
                  flex: '1 0 30%',
                  p: '4',
                  w: 'fit-content',
                  borderRadius: 'sm',
                  boxShadow:
                    '0px 10px 20px 0px #000000, 0px 0px 5px 1px #094C8B inset, 0px 0px 0px 1px #36C4FF inset',
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                <Flex alignItems="start" flexDir="column" gap="3" w="full">
                  <Box color="white">
                    <Text
                      sx={{
                        fontWeight: '500',
                        fontSize: '16px',
                        mb: '1.5',
                        color: '#ccc',
                      }}
                    >
                      {title}{' '}
                      <chakra.span fontSize="12px">
                        {`${count ? `(${count})` : ''}`}
                      </chakra.span>
                    </Text>
                  </Box>
                  <Flex alignItems="center" pos="relative" w="full">
                    <Icon
                      as={icon ?? Bank}
                      sx={{
                        color: colours?.[0] ?? '#7367f0',
                        bg: colours?.[1] ?? '#3a3c64',
                        p: '1',
                        boxSize: '10',
                        borderRadius: 'md',
                        mr: '4',
                      }}
                    />
                    <Text color="white" fontSize="25px">
                      {value}
                    </Text>
                    <PercentageBadge
                      value={percentageValue}
                      sx={{ pos: 'absolute', right: '5px' }}
                    />
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
      </Flex>
    </Flex>
  );
}
