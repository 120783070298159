import { chakra, ChakraComponent, InputProps } from '@chakra-ui/react';
import styled from 'styled-components';

export const InputContainer = styled.div`
  font-size: 14px;
`;

export const LabelContainer = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
export const StyledLabel = styled.label<{ error?: boolean }>`
  color: ${({ theme, error }: { theme: any; error?: boolean }) =>
    error ? theme.global.colors.inputError : theme.global.colors.labelText};
  font-size: 12px;
  font-weight: 300;
`;
/* eslint-enable @typescript-eslint/no-explicit-any */

export const ErrorLabel = styled.span`
  color: ${({ theme }) => theme.global.colors.inputError};
  font-size: 12px;
  font-weight: 400;
`;

export const StyledInput = chakra('input', {
  shouldForwardProp: (prop) => !['error'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ disabled, error }: any) => ({
    lineHeight: '56px',
    bg: disabled ? 'gray.50' : 'white',
    width: '100%',
    fontWeight: 'light',
    color: error ? 'red' : 'black',
    px: '4',
    outline: 'none',
  }),
}) as ChakraComponent<'input', InputProps & { error?: boolean }>;

/* eslint-disable @typescript-eslint/no-explicit-any */
export const InputWrapper = styled.div<{ error?: boolean; border?: boolean }>`
  height: 56px;
  display: flex;
  line-height: 56px;
  width: 100%;
  border-radius: var(--bc-radii-lg);
  background-color: ${({ theme }) => theme.global.colors.inputBackground};
  font-weight: 300;
  outline: none;
  overflow: hidden;

  ${({ theme, border, error }: any) => {
    if (error) {
      return `border: 1px solid ${theme.global.colors.inputError}`;
    }
    if (border) {
      return `border: 1px solid ${theme.global.colors.inputBorder}`;
    }
    return `border: 1px solid var(--bc-colors-gray-200)`;
  }}
`;
/* eslint-enable @typescript-eslint/no-explicit-any */

export const PasswordToggle = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 10%;
  background-color: transparent;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  width: 18px;
  border: none;
`;
