import { RaceSettingsDTO, TSRMParams } from './srm.types';
import api from '../api';

// Same Game Multi
const srmURL = '/bookie/trade-manager/srmultis';
export const querySameRaceMulti = (params: TSRMParams) =>
  api
    .get<Api['SRMultisMonitorResponse']>(srmURL, { params })
    .then((res) => res.data);

export const raceSettingsURL = '/bookie/trade-manager/race-details/races/';

// Post: Update Race Settings
export const mutateRaceSettings = (raceId: string, body: RaceSettingsDTO) =>
  api
    .patch<RaceSettingsDTO>(raceSettingsURL + raceId + '/settings', body)
    .then((res) => res.data);
