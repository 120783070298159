import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TAddCompetitionBody } from './addCompetition.types';

const addCompetitionURL =
  '/bookie/trade-manager/market-creation/add-competition';

// Trade Manager - Add Competition

export const mutateAddCompetition = (body: TAddCompetitionBody) =>
  api
    .post<TMarketCreationResponse>(addCompetitionURL, body)
    .then((res) => res.data);
