import React, { FC } from 'react';
import { Text } from 'grommet';
import dayjs from 'dayjs';

import EventDateTimeExposureProps from './types';
import { EventDateTimeExposureContainer, DateTimeContainer } from './Styles';
import { StartTimeCalculator } from '../../../../common/components';
import { getStrings } from '../../../../common/utils';

export const EventDateTimeExposure: FC<EventDateTimeExposureProps> = ({
  dateTime,
  status,
  isManualOverride = false,
  isSuspended = false,
  isProtestOverride = false,
  ...rest
}) => {
  const [
    {
      Generic,
      TradeManagerPage: { Common },
    },
  ] = getStrings();

  if (!dateTime || !status) return null;

  return (
    <EventDateTimeExposureContainer {...rest}>
      <DateTimeContainer>
        <Text size="xsmall" weight="bold">
          {Generic.Date}
        </Text>
        <Text size="xsmall">{dayjs(dateTime).format('DD/MM, hh:mma')}</Text>
      </DateTimeContainer>
      <DateTimeContainer>
        <Text size="xsmall" weight="bold">
          {Common.Status}
        </Text>
        <StartTimeCalculator
          status={status}
          date={dateTime}
          withStatus
          isManualOverride={isManualOverride}
          isSuspended={isSuspended}
          isProtestOverride={isProtestOverride}
        />
      </DateTimeContainer>
      {/* TODO: Exposure temporarily removed */}
      {/* <ExposureContainer>
        <Text size="xsmall" weight="bold">{Generic.Exposure}</Text>
        <AlertTag variant="warning">{exposure}</AlertTag>
      </ExposureContainer> */}
    </EventDateTimeExposureContainer>
  );
};
