import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TUpdateCompetitionBody } from './updateCompetition.types';

const updateCompetitionURL =
  '/bookie/trade-manager/market-creation/update-competition';

// Trade Manager - Update Competition

export const mutateUpdateCompetition = (body: TUpdateCompetitionBody) =>
  api
    .post<TMarketCreationResponse>(updateCompetitionURL, body)
    .then((res) => res.data);
