import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Text,
  Icon,
  Flex,
  useToast,
  Badge as ChakraBadge,
  VStack,
} from '@chakra-ui/react';

import { Time } from '@styled-icons/boxicons-regular/Time';
import { UserCircle } from '@styled-icons/boxicons-regular/UserCircle';
import dayjs from 'dayjs';

import { EBetStatus, TPriceType } from '../../../../../../lib/DBModels';
import {
  centsToDollars,
  getStrings,
  logError,
  oddsFormatted,
  exposureCalculator,
} from '../../../../../../common/utils';
import {
  EBetRequestRejectReasons,
  EBetRequestStatusInputAction,
  TBetRequestHistoryQuery,
  TBetRequestStatusInput,
  TRequestBet,
  TStickyNote,
} from './BetApprovals.types';
import { setModalCounterBet, setModalRejectBet } from './BetApprovals.slices';
import EventMarketInfo from '../../../../../../common/components/EventMarketInfo';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../common/hooks/useRedux';
import Badge from '../../../../../../common/components/BetStatusBadge';
import notification from '@/assets/sounds/notification.mp3';
import { PunterWrapper } from '../components/Punter';
import {
  useMutationApproval,
  useQueryPendingApprovals,
} from '../../../../../../api/betApprovals/betApprovals.hooks';
import { useQueryHistoryApprovals } from '../../../../../../api/betApprovals/history/history.hooks';
import { useQueryProfile } from '@/api/profile/profile.hooks';
import { useNewRelic } from '@/common/hooks/useNewRelic';
import { ENewRelicActions } from '@/constants/newRelicActions';
import RaainLogo from '@/assets/betApprovals/raain-logo.png';
import { RaainBoxWrapper, RaainLogoImage } from './BetApprovals.styles';
import { CommonResponse, normalizeBetApproval } from './BetApprovals.utils';
import { TPendingApprovalsParams } from '@/api/betApprovals/betApprovals.types';
import { logoFromBookieName } from '@/common/components/BetsTable/components/utils';
import { isWincore } from '../Approvals';
import { ActionWrapper } from '@/common/components/BetsTable/BetsTable';

/* Handles fetching data & filtering for actioned bet requests */
export const useHistoryRequestData = () => {
  const [params, setParams] = useState<TBetRequestHistoryQuery>({});

  const [offset, setOffset] = useState<string>('');

  const limit = 20;

  const setFilters = (newFilter: TBetRequestHistoryQuery) =>
    setParams({ ...newFilter });

  const clearFilters = () => setParams({});

  const { data, isLoading } = useQueryHistoryApprovals({
    params: { offset, ...params },
  });
  return {
    offset,
    setOffset,
    filters: params,
    setFilters,
    clearFilters,
    data,
    isLoading,
    isLastFetch: (data?.bet_requests?.length ?? 0) < limit,
  };
};

/* Handles pending requests */
export const usePendingRequestsData = () => {
  const { data: bookie } = useQueryProfile();
  const [offset, setOffset] = useState<string>('');
  const [type, setType] = useState<'Placement' | 'Settlement' | null>(null);
  const [isEnabled, setIsEnabled] = useState<boolean>(
    bookie?.permissions?.includes('bet_approvals_approvals_view') || false
  );
  const limit = offset ? 20 : 50;

  const params: TPendingApprovalsParams = {
    offset,
    limit,
    sort_direction: 'desc',
    sort_by: 'created_at',
  };

  if (type !== null) {
    params.request_type = type;
  }

  /* refetch if user is on the first page */
  const shouldRefetch = offset === '';
  const { data, isLoading } = useQueryPendingApprovals({
    params,
    options: {
      enabled: isEnabled,
      refetchInterval: shouldRefetch ? 5000 : 0, // polling every 5 sec
      refetchIntervalInBackground: shouldRefetch,
      onError: () => setIsEnabled(false),
    },
  });

  return {
    data,
    isLoading,
    isLastFetch: (data?.bet_requests?.length ?? 0) < limit,
    setOffset,
    offset,
    setType,
    type,
  };
};

export const useApprovalsNotification = (
  newData: TRequestBet[],
  newOffset: string
) => {
  const dispatch = useAppDispatch();
  const [prevOffset, setPrevOffset] = useState(newOffset || '0');
  const [prevData, setPrevData] = useState<TRequestBet[] | undefined>(
    undefined
  );

  const notifyUser = useCallback(() => {
    (async () => {
      await new Audio(notification as string).play();
    })().catch(logError);
  }, []);

  useEffect(() => {
    if (!newData?.length) {
      return;
    }
    if (!prevData) {
      setPrevData(newData);
      return;
    }
    // Due to pagination on the pending bet approvals, we need to first check the page hasn't
    // changed before comparing prev data to new data
    if (newOffset === prevOffset) {
      if (
        !prevData?.some((rec) => rec?.request_id === newData?.[0]?.request_id)
      ) {
        notifyUser();
      }
      // Otherwise compare total record count and notify if it has increased
    } else if (newData?.length > prevData?.length) {
      notifyUser();
    }
    setPrevOffset(newOffset);
    setPrevData(newData);
  }, [dispatch, newData, notifyUser, prevData, newOffset, prevOffset]);

  return {
    totalRecords: newData?.length,
  };
};

/* Handles approving, rejecting or counter an individual request */
export const useActions = () => {
  const { mutateAsync: actionApproval } = useMutationApproval();

  return { actionApproval };
};

/* Handle modals for reject & counter */
export const useModals = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const newRelicLog = useNewRelic();

  const [
    {
      Generic,
      BetApprovalsPage: { Toast: ToastStrings },
    },
  ] = getStrings();

  const { actionApproval } = useActions();

  const { modalRejectBet, modalCounterBet } = useAppSelector(
    (state) => state.betApprovals
  );

  const showRejectBetModal = (bet?: CommonResponse) => {
    if (!bet?.requestId) {
      toast({
        title: Generic.Error,
        status: 'error',
        position: 'top-right',
        duration: 5000,
      });
      return;
    }
    dispatch(setModalRejectBet(bet));
  };

  const showCounterBetModal = (bet?: CommonResponse) => {
    if (!bet?.requestId) {
      toast({
        title: Generic.Error,
        status: 'error',
        position: 'top-right',
        duration: 5000,
      });
      return;
    }

    dispatch(setModalCounterBet(bet));
  };

  const approveRequest = async (datum: CommonResponse) => {
    const isSettlement = datum?.requestType?.toLowerCase() === 'settlement';
    const actionPayload: TBetRequestStatusInput = {
      action: EBetRequestStatusInputAction.Approved,
      request_id: datum.requestId ?? '',
    };

    if (isSettlement) {
      actionPayload.type = 'Settlement';
    }

    actionApproval(actionPayload, {
      onSuccess: () => {
        toast({
          title: ToastStrings.Approved,
          description: ToastStrings.YouApproved.replace(
            '%',
            centsToDollars(isSettlement ? datum.stake : datum.requestedStake)
          ),
          status: 'success',
          position: 'top-right',
          duration: 5000,
        });

        newRelicLog(ENewRelicActions.BetApproval, {
          punter_id: datum.punterId,
          request_id: datum.requestId,
          message: 'Bet Approved',
        });
      },
      onError(error) {
        newRelicLog(ENewRelicActions.BetApproval, {
          error: JSON.stringify(error),
          punter_id: datum.punterId,
          request_id: datum.requestId,
        });
      },
    });
  };

  const acceptMBL = async (datum: CommonResponse) => {
    actionApproval(
      {
        action: EBetRequestStatusInputAction.AcceptMBL,
        request_id: datum.requestId ?? '',
        reason: EBetRequestRejectReasons.mbl_applied,
      },
      {
        onSuccess: () => {
          toast({
            title: ToastStrings.MblAccepted,
            description: ToastStrings.YouApproved.replace(
              '%',
              centsToDollars(datum.mblStake)
            ),
            status: 'success',
            position: 'top-right',
            duration: 5000,
          });

          newRelicLog(ENewRelicActions.BetApproval, {
            punter_id: datum.punterId,
            request_id: datum.requestId,
            message: 'Bet Approved',
          });
        },
        onError(error) {
          newRelicLog(ENewRelicActions.BetApproval, {
            error: JSON.stringify(error),
            punter_id: datum.punterId,
            request_id: datum.requestId,
          });
        },
      }
    );
  };

  // const acceptSettlement = (bet: TRequestBet) => {};

  const closeCounterBetModal = () => dispatch(setModalCounterBet(undefined));
  const closeRejectBetModal = () => dispatch(setModalRejectBet(undefined));

  return {
    showRejectBetModal,
    closeRejectBetModal,
    modalRejectBet,
    rejectBetModalOpened: modalRejectBet !== undefined,

    showCounterBetModal,
    closeCounterBetModal,
    modalCounterBet,
    counterBetModalOpened: modalCounterBet !== undefined,

    approveRequest,
    acceptMBL,
  };
};

// $200,000.00 cut off
export const MAX_SAFE_BET = 20_000_000;

export const useApprovedColumns = () => {
  const [
    {
      BetApprovalsPage: { Table },
    },
  ] = getStrings();

  const columns = [
    {
      accessorKey: 'punter_name',
      header: Table.Punter,
      cell: (props: { row: { original: TRequestBet } }) => (
        <PunterWrapper bet={props.row.original} />
      ),
    },
    {
      accessorKey: 'event_title',
      header: Table.Market,
      cell: (props: { row: { original: TRequestBet } }) => (
        <EventMarketInfo bet={props.row.original} />
      ),
    },
    {
      accessorKey: 'sticky_note',
      header: 'Notes',
      cell: (props: { row: { original: { sticky_note: TStickyNote } } }) =>
        !!props?.row?.original?.sticky_note && (
          <Flex
            sx={{
              bg: '#fffab1',
              p: '2',
              color: 'black',
              maxW: '150px',
              h: 'full',
              boxShadow: 'lg',
            }}
          >
            {props?.row?.original?.sticky_note?.text}
          </Flex>
        ),
    },
    {
      accessorKey: 'bet_request',
      header: 'Type',
      cell: (props: { row: { original: TRequestBet } }) => {
        const type = props?.row?.original?.request_type;
        const isPlacement = type?.toLowerCase() === 'placement';

        return (
          <ChakraBadge colorScheme={isPlacement ? 'green' : 'purple'} p="1">
            {type}
          </ChakraBadge>
        );
      },
    },
    {
      accessorKey: 'raain_rule_breached',
      header: null,
      cell: (props: {
        row: {
          original: { raain_rule_breached: boolean };
        };
      }) => {
        const isRuleReached = props?.row?.original?.raain_rule_breached;

        return (
          isRuleReached && (
            <RaainBoxWrapper>
              <RaainLogoImage src={RaainLogo} alt="Raain Logo" />
            </RaainBoxWrapper>
          )
        );
      },
    },
    {
      accessorKey: 'requested_odds',
      header: Table.RequestedOdds,
      cell: (props: {
        row: {
          original: {
            requested_odds: number;
            proposition_odds: number;
            price_type?: TPriceType;
          };
        };
      }) => {
        if (props.row.original.price_type === 'starting') {
          return (
            <ChakraBadge colorScheme="blue">
              <FormattedMessage id="generic.startingPriceAcronym" />
            </ChakraBadge>
          );
        }

        if (props.row.original.price_type === 'tote_single_mid') {
          return <ChakraBadge colorScheme="blue">MD</ChakraBadge>;
        }

        if (props.row.original.price_type === 'tote_single_best') {
          return <ChakraBadge colorScheme="blue">BT</ChakraBadge>;
        }

        if (props.row.original.price_type === 'even_shot') {
          return (
            <Text fontWeight="medium" fontSize="md">
              {oddsFormatted(props.row.original.requested_odds)}
            </Text>
          );
        }

        return (
          <Flex direction="column">
            <Text fontWeight="medium">
              {oddsFormatted(props.row.original.proposition_odds)}
            </Text>
            {props.row.original.proposition_odds !==
              props.row.original.requested_odds && (
              <Text
                textDecoration={
                  props.row.original.proposition_odds > 0
                    ? 'line-through'
                    : 'none'
                }
                fontWeight="medium"
                fontSize="md"
                mt={props.row.original.proposition_odds && '1'}
              >
                {oddsFormatted(props.row.original.requested_odds)}
              </Text>
            )}
          </Flex>
        );
      },
    },
    {
      accessorKey: 'requested_stake',
      header: Table.RequestedStake,
      cell: (props: {
        row: {
          original: {
            requested_stake: number;
            stake: number;
          };
        };
      }) => (
        <Flex flexDirection="column">
          <Text fontSize="md" fontWeight="medium">
            {centsToDollars(
              props?.row.original.requested_stake ?? props?.row.original.stake,
              true
            )}
          </Text>
        </Flex>
      ),
    },
    {
      accessorKey: 'return',
      header: Table.Exposure,
      cell: (props: {
        row: {
          original: {
            requested_stake: number | undefined;
            requested_payout?: number;
            stake?: number;
            requested_odds: number | undefined;
            price_type?: TPriceType;
            request_type?: string;
            is_bonus_bet?: boolean;
            bet_legs: TRequestBet['bet_legs'];
            type: string;
          };
        };
      }) => {
        const original = props?.row?.original;
        const type = original.request_type;
        const isPlacement = type?.toLowerCase() === 'placement';
        const requestedOdds = original?.requested_odds ?? 0;

        const isMysteryBet =
          original?.bet_legs?.[0]?.price_type === 'mystery_bet';

        const hasRollover =
          isMysteryBet && original.type.toLowerCase() === 'multi';

        if (
          props.row.original.price_type === 'starting' ||
          props.row.original.price_type === 'tote_single_mid' ||
          props.row.original.price_type === 'tote_single_best'
        ) {
          return <FormattedMessage id="generic.na" />;
        }

        return (
          <Text fontSize="md" fontWeight="medium">
            {centsToDollars(
              isPlacement
                ? exposureCalculator(
                    original.requested_stake,
                    hasRollover ? requestedOdds * requestedOdds : requestedOdds
                  )
                : original.is_bonus_bet
                ? original.requested_payout ?? 0
                : (original.requested_payout ?? 0) - (original?.stake ?? 0),
              true
            )}
          </Text>
        );
      },
    },
    {
      accessorKey: 'requested_payout',
      header: 'Return',
      cell: (props: {
        row: {
          original: {
            requested_payout: number;
            request_type: string;
            price_type?: TPriceType;
            requested_odds: number;
            requested_stake: number;
            bet_legs: TRequestBet['bet_legs'];
            type: string;
            is_bonus_bet: boolean;
          };
        };
      }) => {
        if (
          props.row.original.price_type === 'starting' ||
          props.row.original.price_type === 'tote_single_mid' ||
          props.row.original.price_type === 'tote_single_best'
        ) {
          return <FormattedMessage id="generic.na" />;
        }

        const isPlacement =
          props.row.original.request_type?.toLowerCase() === 'placement';

        const isMysteryBet =
          props.row.original?.bet_legs?.[0]?.price_type === 'mystery_bet';

        const hasRollover =
          isMysteryBet && props.row.original.type.toLowerCase() === 'multi';

        const data = props?.row.original;

        let payout;

        const mbOdds = hasRollover
          ? (data?.is_bonus_bet
              ? data?.requested_odds - 1
              : data?.requested_odds) * data?.requested_odds
          : data?.is_bonus_bet
          ? data?.requested_odds - 1
          : data?.requested_odds;

        if (isMysteryBet) {
          payout = data?.is_bonus_bet
            ? mbOdds * data?.requested_stake - data?.requested_stake
            : mbOdds * data?.requested_stake;
        } else {
          payout = isPlacement
            ? props.row.original.requested_odds *
              props.row.original.requested_stake
            : props?.row?.original?.requested_payout;
        }

        const isOverLimit = payout >= MAX_SAFE_BET;
        return (
          <Flex flexDirection="column">
            <Text fontSize="md" fontWeight="medium">
              {centsToDollars(payout, true)}
            </Text>
            {isOverLimit && (
              <ChakraBadge colorScheme="red" w="fit-content">
                HIGH RETURN
              </ChakraBadge>
            )}
          </Flex>
        );
      },
    },
    {
      accessorKey: 'approvals',
      header: '',
      cell: (props: { row: { original: TRequestBet } }) => (
        <ActionWrapper data={normalizeBetApproval(props.row.original)} />
      ),
    },
  ];
  return columns;
};

/* Provides the shared columns for both pending and history views with slight differences defined by displayType */
export const useColumns = () => {
  const [
    {
      BetApprovalsPage: { Table },
    },
  ] = getStrings();

  const columns = [
    {
      accessorKey: 'punter_name',
      header: Table.Punter,
      cell: (props: { row: { original: TRequestBet } }) => (
        <PunterWrapper bet={props.row.original} />
      ),
    },
    {
      accessorKey: 'event_title',
      header: Table.Market,
      cell: (props: { row: { original: TRequestBet } }) => (
        <EventMarketInfo bet={props.row.original} />
      ),
    },
    {
      accessorKey: 'sticky_note',
      header: 'Notes',
      cell: (props: { row: { original: { sticky_note: TStickyNote } } }) =>
        !!props?.row?.original?.sticky_note &&
        props?.row?.original?.sticky_note.text !== '' && (
          <Flex
            sx={{
              bg: '#fffab1',
              p: '2',
              color: 'black',
              maxW: '150px',
              h: 'full',
              boxShadow: 'lg',
            }}
          >
            {props?.row?.original?.sticky_note?.text}
          </Flex>
        ),
    },
    {
      accessorKey: 'bet_request',
      header: 'Type',
      cell: (props: { row: { original: TRequestBet } }) => {
        const type = props?.row?.original?.request_type;
        const isPlacement = type?.toLowerCase() === 'placement';
        const isRuleReached = props?.row?.original?.raain_rule_breached;
        return (
          <VStack>
            {isWincore && (
              <img
                src={logoFromBookieName(
                  props.row.original?.source_platform_name ?? ''
                )}
                alt=""
                width="200px"
              />
            )}
            <Badge status={props.row.original.status ?? undefined} />
            <ChakraBadge colorScheme={!isPlacement ? 'purple' : 'green'} p="1">
              {type}
            </ChakraBadge>
            {isRuleReached && (
              <RaainBoxWrapper>
                <RaainLogoImage src={RaainLogo} alt="Raain Logo" />
              </RaainBoxWrapper>
            )}
          </VStack>
        );
      },
    },
    {
      accessorKey: 'requested_odds',
      header: Table.RequestedOdds,
      cell: (props: {
        getValue: () => number | undefined;
        row: {
          original: {
            price_type?: TPriceType;
            bet_legs: TRequestBet['bet_legs'];
          };
        };
      }) => {
        const priceType = isWincore
          ? props.row.original.bet_legs
            ? props.row.original.bet_legs[0]?.price_type
            : ''
          : props.row.original.price_type;
        const isFixedOdds =
          priceType !== 'starting' &&
          priceType !== 'tote_single_mid' &&
          priceType !== 'tote_single_best';

        const renderType = () => {
          switch (priceType) {
            case 'tote_single_mid':
              return 'MD';
            case 'starting':
              return 'SP';
            case 'tote_single_best':
              return 'BT';
            default:
              return 'N/A';
          }
        };

        return isFixedOdds ? (
          <Text as="span" size="sm">
            {oddsFormatted(props.getValue())}
          </Text>
        ) : (
          <ChakraBadge colorScheme="blue">{renderType()}</ChakraBadge>
        );
      },
    },
    {
      accessorKey: 'requested_stake',
      header: Table.RequestedStake,
      cell: (props: {
        row: {
          original: {
            requested_stake: number;
            approved_stake: number;
            request_type: string;
            stake: number;
          };
        };
      }) => {
        const prop = props?.row?.original;

        const isSettlement = prop.request_type?.toLowerCase() === 'settlement';

        if (isSettlement)
          return <Text fontSize="md">{centsToDollars(prop.stake, true)}</Text>;

        return (
          <Flex flexDirection="column">
            <Text fontSize="md">
              {prop.approved_stake == null
                ? centsToDollars(prop.requested_stake, true)
                : centsToDollars(prop.approved_stake, true)}
            </Text>
            {!!prop.approved_stake &&
              prop.approved_stake !== prop.requested_stake && (
                <Text fontSize="md" textDecoration="line-through">
                  {centsToDollars(prop.requested_stake, true)}
                </Text>
              )}
          </Flex>
        );
      },
    },
    {
      accessorKey: 'bet_payout',
      header: Table.PotentialAndActual,
      cell: (props: {
        row: {
          original: {
            bet_payout: number;
            approved_stake: number;
            requested_odds: number;
            bet_status: EBetStatus;
            is_bonus_bet: boolean;
            price_type?: TPriceType;
            request_type?: string;
            requested_payout?: number;
            bet_legs: TRequestBet['bet_legs'];
            type: string;
          };
        };
      }) => {
        const priceType = isWincore
          ? props.row.original.bet_legs
            ? props.row.original.bet_legs[0]?.price_type
            : ''
          : props.row.original.price_type;

        const data = props?.row.original;
        let potentialReturn;

        const requestdPayout = data?.requested_payout;

        const isSettlement = data?.request_type?.toLowerCase() === 'settlement';

        const isMysteryBet =
          props.row.original?.bet_legs?.[0]?.price_type === 'mystery_bet';

        const hasRollover =
          isMysteryBet && props.row.original.type.toLowerCase() === 'multi';

        const mbOdds = hasRollover
          ? (data?.is_bonus_bet
              ? data?.requested_odds - 1
              : data?.requested_odds) * data?.requested_odds
          : data?.is_bonus_bet
          ? data?.requested_odds - 1
          : data?.requested_odds;

        if (isMysteryBet) {
          potentialReturn = data?.is_bonus_bet
            ? mbOdds * data?.approved_stake - data?.approved_stake
            : mbOdds * data?.approved_stake;
        } else {
          potentialReturn = data.is_bonus_bet
            ? data.approved_stake * data.requested_odds - data.approved_stake
            : data.approved_stake * data.requested_odds;
        }

        return (
          <Text fontSize="md">
            {priceType === 'starting' ||
            priceType === 'tote_single_mid' ||
            priceType === 'tote_single_best' ? (
              <FormattedMessage id="generic.na" />
            ) : (
              centsToDollars(isSettlement ? requestdPayout : potentialReturn)
            )}{' '}
            /
            {data.bet_status === EBetStatus.Pending ? (
              <Badge status={data.bet_status ?? undefined} />
            ) : (
              centsToDollars(data.bet_payout, true)
            )}
          </Text>
        );
      },
    },
    {
      accessorKey: 'actioned_at',
      header: Table.ApprovedDateBy,
      cell: (props: {
        row: { original: { actioned_at: string; actioned_by: string } };
      }) => (
        <>
          <Text fontSize="sm">
            <Icon as={Time} />{' '}
            {dayjs(props?.row.original.actioned_at ?? '').format(
              'DD/MM/YYYY HH:mm:ss'
            )}
          </Text>
          <Text fontSize="sm">
            <Icon as={UserCircle} /> {props?.row.original.actioned_by}
          </Text>
        </>
      ),
    },
  ];

  return columns;
};
