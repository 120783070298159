import React, { useState } from 'react';
import { ColumnConfig } from 'grommet';
import { Text } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { mixed, object } from 'yup';
import dayjs from 'dayjs';
import { centsToDollars, getStrings } from '../../../common/utils';
import { EBonusAwardReason, TBulkBonusAward, TCSVUploadInput } from '../types';
import { addBulkBonusAward } from '../../../api/bulkBonusAwards/bulkBonusAwards';
import { useDebounce } from '../../../common/hooks/useDebounce';
import { TBonusBet } from '../../../api/promos/bonus-bets/bonusBet.types';
import { TBonusAwardsParams } from '../../../api/bulkBonusAwards/bulkBonusAwards.types';
import { ENewRelicActions } from '@/constants/newRelicActions';
import { useNewRelic } from '@/common/hooks/useNewRelic';
import { useQueryPromoSummary } from '@/api/promoSummary/promoSummary.hooks';
import DownloadCSVButton from '@/features/promoManager/pages/PromoManager/tabs/DepositMatch/components/DownloadCSVButton/DownloadCSVButton';

export const useBulkBonusAwards = () => {
  const initialParams = {
    applied_from: '',
    applied_to: '',
    offset: 0,
  };

  const limit = 20;
  const [params, setParams] = useState<TBonusAwardsParams>(initialParams);
  const { applied_from, applied_to } = useDebounce(params, 250);
  const newRelicLog = useNewRelic();

  const clearFilters = () => setParams(initialParams);

  const setFilters = (filter: TBonusAwardsParams) => {
    setParams({ ...filter, offset: 0 });
  };

  const [offset, setOffset] = useState<string>('');

  const { data, isError, isFetching, isLoading } = useQueryPromoSummary(
    {
      promo_type: 'bonus_bet',
      limit,
      ...(applied_from ? { applied_from } : {}),
      ...(applied_to ? { applied_to } : {}),
      ...(offset ? { offset } : {}),
    },
    {
      keepPreviousData: true,
    }
  );

  const { mutateAsync: addBulkBonus, isLoading: isAddingBulkBonusAward } =
    useMutation(addBulkBonusAward, {
      onSuccess: (success) => {
        if (success) {
          window.location.reload();
        }
      },
    });

  const handleAddBulkBonusAward = async (bonusAward: TCSVUploadInput) => {
    try {
      const response = await addBulkBonus(bonusAward);
      newRelicLog(ENewRelicActions.PromoUpload, {
        message: response,
        name: bonusAward.name,
        reason: bonusAward.reason,
      });
    } catch (e) {
      newRelicLog(ENewRelicActions.PromoUpload, {
        error: JSON.stringify(e),
        name: bonusAward.name,
        reason: bonusAward.reason,
      });
    }
  };

  return {
    clearFilters,
    filters: params,
    handleAddBulkBonusAward,
    isAddingBulkBonusAward,
    isLoading,
    isLastFetch: (data?.data.promotions.length ?? 0) < limit,
    setOffset,
    offset,
    limit,
    data,
    setFilters,
    isError,
    isFetching,
  };
};

export const useColumns = (): ColumnConfig<TBonusBet>[] => {
  const [
    {
      PromoManagerPage: {
        BulkBonusAwards: { TableHeadings },
      },
    },
  ] = getStrings();

  const columns = [
    {
      property: 'name',
      header: <Text>{TableHeadings.name}</Text>,
      primary: true,
      render: (datum: TBulkBonusAward) => <Text>{datum.name}</Text>,
    },
    {
      property: 'num_of_punters_credited',
      header: <Text fontSize="sm">{TableHeadings.numOfPuntersCredited}</Text>,
      render: (datum: TBulkBonusAward) => <Text>{datum.total_punters}</Text>,
    },
    {
      property: 'total_amount_credited',
      sortable: true,
      header: <Text fontSize="sm">{TableHeadings.totalAmountCredited}</Text>,
      render: (datum: TBulkBonusAward) => (
        <Text size="sm">{centsToDollars(datum.total_credited)}</Text>
      ),
    },
    {
      property: 'date',
      header: <Text fontSize="sm">{TableHeadings.date}</Text>,
      render: (datum: TBulkBonusAward) => (
        <Text size="sm">
          {dayjs(datum.promotion_date).format('DD/MM/YYYY')}
        </Text>
      ),
      sortable: true,
    },
    {
      accessorKey: 'promo_id',
      header: <Text>Actions</Text>,
      render: (datum: Api['PromoSummaryResponseItem']) => (
        <DownloadCSVButton
          promoId={datum.promo_id}
          filename={datum.file_name}
        />
      ),
    },
  ];

  return columns as ColumnConfig<TBulkBonusAward>[];
};

const [{ FormValidation }] = getStrings();

export const CSVUploadValidation = object({
  bonus_awards: mixed().required(FormValidation.FileRequired),
  reason: mixed<EBonusAwardReason>().required(FormValidation.RequiredField),
});
