import api from '@/api/api';
import {
  TClosePropositionBody,
  TClosePropositionResponse,
} from './closeProposition.types';

export const closePropositionURL =
  '/bookie/trade-manager/sport-details/open-close-proposition';

// Post: Sports Status - Close Proposition
export const mutateCloseProposition = (body: TClosePropositionBody) =>
  api
    .post<TClosePropositionResponse>(closePropositionURL, body)
    .then((res) => res.data);
