import api from '../../api';
import { TMarketBet } from '../trademanager.types';
import {
  TConfigProps,
  TGetMatchParams,
  TMarket,
  TMarketGroup,
  TMatch,
  TSettleMarketProps,
} from './match.types';

export const matchDetailsURL = '/bookie/trade-manager/sport-details/match';
export const queryMatchData = (params: TGetMatchParams) =>
  api.get<TMatch>(matchDetailsURL, { params }).then((res) => res.data);

export const marketGroupsURL =
  '/bookie/trade-manager/sport-details/market-groups/';
export const queryMarketGroups = (matchId: string) =>
  api
    .get<TMarketGroup[]>(`${marketGroupsURL}${matchId}`)
    .then((res) => res.data);

export const marketsURL = `/bookie/trade-manager/sport-details/markets`;
export const queryMarkets = (params: Record<string, unknown>) =>
  api.get<TMarket[]>(marketsURL, { params }).then((res) => res.data);

export const marketPropositionsURL =
  '/bookie/trade-manager/sport-details/proposition-for-market';
export const queryMarketPropositions = (params: Record<string, unknown>) =>
  api.get<TMarket[]>(marketPropositionsURL, { params }).then((res) => res.data);

// This is the only query being used for the time being, above will be come into play once the matchDetails.tsx has been re-written.
export const betsForMarketURL =
  '/bookie/trade-manager/sport-details/bets-for-market';
export const queryBetsForMarket = (params: Record<string, unknown>) =>
  api.get<TMarketBet[]>(betsForMarketURL, { params }).then((res) => res.data);

export const settleMarketPropsURL =
  '/bookie/trade-manager/sport-details/settle-sport-market-propositions';

export const mutateMarketProps = (marketProps: TSettleMarketProps) =>
  api.post(settleMarketPropsURL, marketProps).then((res) => res.data);

export const mutateMatchConfig = (configProps: TConfigProps) => {
  const { match_id, ...restConfig } = configProps; // Destructure match_id from configProps
  const apiURL = `/bookie/trade-manager/sport-details/matches/${match_id}/settings`;

  return api.patch(apiURL, restConfig).then((res) => res.data);
};
