import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Input } from '../../../../common/components';

export const FormContainer = styled.div`
  margin-bottom: 30px;
  position: relative;
`;

export const SubmitButton = styled.button`
  height: 56px;
  width: 100%;
  line-height: 56px;
  background-image: linear-gradient(90deg, #66c5ef 0%, #1467ae 100%);
  border: none;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.global.colors.primaryButtonText};
  cursor: pointer;
  margin-top: 50px;
`;

export const StyledInput = styled(Input)`
  font-size: 18px;
`;

export const LoginLink = styled(Link)`
  padding: 20px 0 43px;
  color: ${({ theme }) => theme.global.colors.loginLinkText};
  font-size: 16px;
  font-weight: 500;
  display: block;
`;

export const BackButton = styled.button`
  align-items: center;
  border: none;
  border-radius: 8px;
  background: ${({ theme }) => theme.global.colors.lightButtonGrey};
  margin-bottom: 55px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 56px;
  width: 56px;
`;
