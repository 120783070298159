import api from '@/api/api';
import {
  TQueryRaceNumbersParams,
  TQueryRaceNumbersResponse,
} from './raceNumbers.types';

// Race numbers for venue
const raceNumbersURL =
  '/bookie/trade-manager/race-details/race-numbers-for-venue';

export async function queryRaceNumbers(params: TQueryRaceNumbersParams) {
  const res = await api.get<TQueryRaceNumbersResponse[]>(raceNumbersURL, {
    params,
  });
  return res;
}
