import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TAddSportBody } from './addSport.types';

const addSportURL = '/bookie/trade-manager/market-creation/add-sport';

// Trade Manager - Add Sport

export const mutateAddSport = (body: TAddSportBody) =>
  api.post<TMarketCreationResponse>(addSportURL, body).then((res) => res.data);
