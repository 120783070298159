import React from 'react';

import { Outlet } from 'react-router-dom';
import { srcBetcloudLogo } from '../../assets/logos';
import { srcLoginFeatureA } from '../../assets/images';

import {
  Container,
  MainContent,
  ChildContentWrapper,
  Logo,
  Slider,
  Slide,
  SlideImage,
  SlideText,
} from './Styles';

export default function PublicLayout() {
  return (
    <Container>
      <MainContent>
        <Logo>
          <img src={srcBetcloudLogo} alt="Betcloud" />
        </Logo>
        <ChildContentWrapper>
          <Outlet />
        </ChildContentWrapper>
      </MainContent>
      <Slider>
        <Slide>
          <SlideImage src={srcLoginFeatureA} alt="Feature Slide Example" />
          <SlideText>Get closer to your punters.</SlideText>
        </Slide>
      </Slider>
    </Container>
  );
}
