import React, {
  FC,
  ChangeEventHandler,
  FocusEventHandler,
  useState,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';
import { PasswordHide, PasswordShow } from '../../../assets/icons';

import {
  InputContainer,
  LabelContainer,
  StyledLabel,
  ErrorLabel,
  StyledInput,
  PasswordToggle,
  InputWrapper,
  IconWrapper,
} from './Styles';

export type TInputProps = {
  type?: string;
  placeholder?: string;
  name?: string;
  id?: string;
  labelText?: string;
  error?: boolean;
  errorText?: string;
  value?: string | number;
  disabled?: boolean;
  className?: string;
  dataCy?: string;
  border?: boolean;
  icon?: ReactNode;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
};

export const Input: FC<TInputProps> = ({
  type,
  placeholder,
  name,
  id,
  labelText,
  disabled,
  onChange,
  icon,
  value,
  error,
  errorText,
  className,
  dataCy,
  onBlur,
  onFocus,
  border,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const inputType = type === 'password' && isPasswordVisible ? 'text' : type;

  const handlePasswordIcons = useMemo(
    () => (isPasswordVisible ? <PasswordHide /> : <PasswordShow />),
    [isPasswordVisible]
  );

  const handlePasswordVisibility = useCallback(
    () => setIsPasswordVisible(!isPasswordVisible),
    [isPasswordVisible]
  );

  return (
    <InputContainer>
      {labelText && (
        <LabelContainer>
          <StyledLabel error={error}>{labelText}</StyledLabel>
          {errorText && <ErrorLabel>{errorText}</ErrorLabel>}
        </LabelContainer>
      )}
      <InputWrapper error={error} border={border}>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <StyledInput
          type={inputType}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          className={className}
          error={error}
          value={value}
          id={id}
          disabled={disabled}
          data-cy={dataCy}
        />
        {type === 'password' && (
          <PasswordToggle onClick={handlePasswordVisibility}>
            {handlePasswordIcons}
          </PasswordToggle>
        )}
      </InputWrapper>
    </InputContainer>
  );
};
