import _ from 'lodash';
import { TExoticCombo } from '../../../../../common/components/ExoticsBetSelection/Services/Types.ExoticsBetCombos';
import { TBetsData } from './Types.Bets';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';

// 👇 Simple version for selections EG: (1, 2, 7, 10)
export const getExoticSelections = (exoticSelections: TExoticCombo[]) =>
  exoticSelections?.map((place) => {
    if (!place?.exotic_combos) {
      return [];
    }
    return [...place?.exotic_combos]
      ?.sort((a, b) => {
        if (!a?.runner_number || !b?.runner_number) {
          return 0;
        }
        return a.runner_number - b.runner_number;
      })
      .map((pl) => pl?.runner_number);
  });

export const isBoxed = (selections: number[][]) =>
  !selections
    .map((place, index) => {
      if (index === 0) {
        return true;
      }
      return _.isEqual(place, selections[index - 1]);
    })
    .includes(false);

/* Returns the total combos for an exotic */
export const getValidCombos = (bet: TBetsData | undefined) => {
  const runnerSelections = isWincore
    ? bet?.selection
    : bet?.exotic_selections?.map((exoticCombos) =>
        exoticCombos?.exotic_combos?.map(
          (selection) => selection?.runner_number
        )
      );

  const formattedNumbers: number[][] = [];
  // Necessary for .reduce function below
  runnerSelections?.forEach((entry) => {
    const position: number[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (entry as any)?.forEach((place: any) => {
      if (place !== undefined) {
        position.push(place);
      }
    });
    formattedNumbers.push(position);
  });

  // Find Cartesian product of all total combos, i.e. list of all possible
  // combinations
  const totalCombos = formattedNumbers.reduce<number[][]>(
    (results, entries) =>
      results
        .map((result) => entries.map((entry) => [...result, entry]))
        .reduce((subResults, result) => [...subResults, ...result], []),
    [[]]
  );

  // Filter out any combinations containing the same runner in more than one position
  // e.g. [5, 5] or [1, 2, 1]
  const validCombinations = totalCombos.filter(
    (combo) => combo.length === new Set(combo).size
  );

  // Quinella bet types should remove any excess combinations where the same runners
  // are present but in different positions, e.g. [1, 2] & [2, 1]
  if (
    isWincore
      ? bet?.bet_type === 'Exotics' &&
        bet?.bet_legs?.at(0)?.bet_description === 'Quinella'
      : bet?.bet_description === 'Quinella'
  ) {
    if (isWincore) {
      const uniqueArrays = new Set();

      return validCombinations.filter((subArr) => {
        const sortedSubArr = [...subArr].sort().toString();
        if (!uniqueArrays.has(sortedSubArr)) {
          uniqueArrays.add(sortedSubArr);
          return true;
        }
        return false;
      });
    }
    const filteredCombos = validCombinations.filter((element, index, array) => {
      const duplicate = array
        .slice(0, index)
        .some((el) => el.includes(element[0]) && el.includes(element[1]));
      return !duplicate;
    });
    return filteredCombos;
  }
  return validCombinations;
};

export const getStakePerCombo = (
  bet: TBetsData | undefined
): number | undefined => {
  const validCombos = getValidCombos(bet);
  if ((!bet?.stake && !bet?.bonus_stake) || !validCombos) {
    return undefined;
  }
  if (bet?.bonus_stake) {
    return bet?.bonus_stake / validCombos?.length;
  }
  if (bet?.stake) {
    return bet?.stake / validCombos?.length;
  }
  return undefined;
};

/* Calculates the flexi % for exotics - stake is already in cents so no need to x100 */
export const getFlexi = (bet: TBetsData | undefined): number | undefined => {
  const validCombos = getValidCombos(bet);
  if ((!bet?.stake && !bet?.bonus_stake) || !validCombos) {
    return undefined;
  }
  if (bet?.bonus_stake) {
    return +(bet?.bonus_stake / validCombos.length).toFixed(2);
  }
  if (bet?.stake) {
    return +(bet?.stake / validCombos.length).toFixed(2);
  }
  return undefined;
};
