import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TAddMatchBody } from './addMatch.types';

const addMatchURL = '/bookie/trade-manager/market-creation/add-match';

// Trade Manager - Add Match

export const mutateAddMatch = (body: TAddMatchBody) =>
  api.post<TMarketCreationResponse>(addMatchURL, body).then((res) => res.data);
