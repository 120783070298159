import { Next } from '@styled-icons/foundation/Next';
import { HorseHead } from '@styled-icons/fa-solid/HorseHead';
import { SportBasketball } from '@styled-icons/fluentui-system-filled/SportBasketball';
import { MonitorOutline } from '@styled-icons/evaicons-outline/MonitorOutline';
import { SearchAlt } from '@styled-icons/boxicons-regular/SearchAlt';
import { Customize } from '@styled-icons/boxicons-regular/Customize';
import SGMLogo from '@/assets/icons/sgm-icon.svg';
import SRMLogo from '@/assets/icons/srm-icon.svg';
import { FEATURE_FLAGS } from '@/constants/featureFlags';

const tabs = [
  {
    to: '/trade-manager',
    text: 'Next to jump',
    icon: Next,
    dataCy: 'tradeManagerNextToJumpLink',
  },
  {
    to: '/trade-manager/by-racing',
    text: 'Racing',
    icon: HorseHead,
    dataCy: 'tradeManagerRacingLink',
  },
  {
    to: '/trade-manager/by-sport',
    text: 'Sport',
    icon: SportBasketball,
    dataCy: 'tradeManagerBySportLink',
  },

  {
    to: '/trade-manager/find-an-event',
    text: 'Find an event',
    icon: SearchAlt,
    dataCy: 'tradeManagerBySportLink',
  },

  {
    to: '/trade-manager/multi-monitor',
    text: 'Multi Monitor',
    icon: MonitorOutline,
    dataCy: 'tradeManagerBySportLink',
  },

  {
    to: '/trade-manager/sgm',
    text: 'SGM',
    icon: SGMLogo,
    dataCy: 'tradeManagerBySportLink',
    isHidden: !FEATURE_FLAGS.IS_SGM_ENABLED,
  },

  {
    to: '/trade-manager/srm',
    text: 'SRM',
    icon: SRMLogo,
    dataCy: 'tradeManagerBySportLink',
    isHidden: !FEATURE_FLAGS.IS_SRM_ENABLED,
  },

  {
    to: '/trade-manager/custom-markets',
    text: 'Custom Markets',
    icon: Customize,
    dataCy: 'tradeManagerBySportLink',
  },
];

export default tabs;
