import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TUpdateMarketGroupBody } from './updateMarketGroup.types';

const updateMarketGroupURL =
  '/bookie/trade-manager/market-creation/update-market-group';

// Trade Manager - Update Market Group

export const mutateUpdateMarketGroup = (body: TUpdateMarketGroupBody) =>
  api
    .post<TMarketCreationResponse>(updateMarketGroupURL, body)
    .then((res) => res.data);
