import DropzonePreview from '@/common/components/Dropzone/DropzonePreview';
import {
  Button,
  Checkbox,
  Flex,
  Radio,
  Select,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { AU_STATES } from '@/lib/Constants';
import BCCheckbox from '../Checkbox/BCCheckbox';
import { ErrorMessage, useFormikContext } from 'formik';
import { TFormikValues } from './FormHOC';
import Input from '@/common/components/FormElements/Input';
import { BCGridItem, BCGridWrapper } from '../../styles/Promotion.styles';
import {
  FlexDropZoneBody,
  FlexDrozoneWrapper,
  RadioButtonWrapper,
  TextError,
} from './Form.styles';
import { usePromotion } from '../../services/Promotion.hooks';
import { usePunterCategoriesObject } from './Form.hooks';
import {
  EDisplay,
  EStatus,
  TData,
} from '@/api/promoManager/promotions/promotions.types';
import Wyswyg from '@/features/settings/pages/Settings/tabs/SystemSetup/tabs/Security/components/Wyswyg';
import DateTimePicker from '@/common/components/FormElements/DateTimePicker/DateTimePicker';
import dayjs from 'dayjs';
import { PROMO_TYPES } from './Form.types';
import { queryPromoSummary } from '@/api/promoSummary/promoSummary';
import { useQuery } from 'react-query';

type TFormData = {
  promotion?: TData;
};

export default function Form({ promotion }: TFormData) {
  const { values, setFieldValue, dirty, isSubmitting, errors } =
    useFormikContext<TFormikValues>();
  const { handleDelete, isLoading } = usePromotion({ id: promotion?.id });
  const punterCategoryList = usePunterCategoriesObject();
  const navigate = useNavigate();

  const { data: promotions } = useQuery(
    ['promoSummary', values.has_money_back_promo],
    () =>
      queryPromoSummary({
        promo_type: 'money_back',
        limit: 100,
        sources: 'manual',
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  if (isLoading) return <></>;

  return (
    <BCGridWrapper>
      <BCGridItem area={'title'}>
        <Text>Title</Text>
        <Input
          type="text"
          name="title"
          value={values.title}
          defaultValue={promotion?.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('title', e.target.value)
          }
          placeholder="Promotion Title"
        />
      </BCGridItem>
      <BCGridItem area={'main'}>
        <TextError>
          <ErrorMessage
            name="genericError"
            component="div"
            className="error-message"
          />
        </TextError>

        <Flex justifyContent="space-between" w="full" mb="3" flexDir="column">
          <Text>Description</Text>

          <Textarea
            placeholder="Promotion Description"
            size="sm"
            resize="none"
            defaultValue={values?.description}
            value={values.description}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setFieldValue('description', e.target.value)
            }
          />
        </Flex>

        <Flex justifyContent="space-between" w="full" mb="3" flexDir="column">
          <Text>Promotion Terms and Conditions</Text>

          <Wyswyg
            initialContent={values.terms_and_conditions ?? ''}
            withSave={false}
            onChange={(e) => setFieldValue('terms_and_conditions', e)}
          />
        </Flex>

        <Flex justifyContent="space-between" w="full" mt="10">
          <Text>Web (Desktop)</Text>
        </Flex>

        <TextError>
          <ErrorMessage
            name="media.desktop.base64"
            component="div"
            className="error-message"
          />
        </TextError>
        <FlexDrozoneWrapper>
          <Flex flexDir="column" w="full">
            <DropzonePreview
              file={values?.media?.desktop?.publicUrl ?? ''}
              onFilesSelected={(e) =>
                setFieldValue('media.desktop.publicUrl', e)
              }
              getName={(e) => setFieldValue('media.desktop.name', e)}
              placeholder="Recommended size: 836 x 120px."
              name="media.desktop.base64"
            />
          </Flex>
        </FlexDrozoneWrapper>

        <Flex justifyContent="space-between" w="full">
          <Text>Web (Mobile)</Text>
        </Flex>
        <TextError>
          <ErrorMessage
            name="media.mobile.base64"
            component="div"
            className="error-message"
          />
        </TextError>

        <FlexDrozoneWrapper>
          <FlexDropZoneBody>
            <DropzonePreview
              name="media.mobile.base64"
              file={values?.media?.mobile?.publicUrl ?? ''}
              getName={(e) => setFieldValue('media.mobile.name', e)}
              onFilesSelected={(e) =>
                setFieldValue('media.mobile.publicUrl', e)
              }
              placeholder="Recommended size: 836 x 120px."
            />
          </FlexDropZoneBody>
        </FlexDrozoneWrapper>

        <Text>App</Text>
        <TextError>
          <ErrorMessage
            name="media.app.base64"
            component="div"
            className="error-message"
          />
        </TextError>

        <DropzonePreview
          file={values?.media.app?.publicUrl ?? ''}
          onFilesSelected={(e) => setFieldValue('media.app.publicUrl', e)}
          getName={(e) => setFieldValue('media.app.name', e)}
          placeholder="Recommended size: 836 x 120px."
          name="media.app.base64"
        />

        <Input
          type="text"
          name="button_text"
          label="Button Text (Maximum 20 characters)"
          placeholder="Bet now"
          value={values.button_text}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('button_text', e.target.value)
          }
          sx={{ w: 'full' }}
        />

        <Input
          type="text"
          name="button_link"
          label="Button Hyperlink"
          placeholder="Button Hyperlink"
          value={values.button_link}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('button_link', e.target.value)
          }
          sx={{ mt: '7', w: 'full' }}
        />
      </BCGridItem>

      {/** Sidenav */}
      <BCGridItem area={'nav'} bg="transparent">
        <Flex sx={{ gap: '3', justifyContent: 'space-between' }}>
          {!promotion && (
            <Button type="submit" sx={{ bg: 'origin.500', w: 'full' }}>
              Save
            </Button>
          )}
        </Flex>
        <Text mt="5">Status</Text>
        <RadioButtonWrapper
          onChange={(e: string) => setFieldValue('status', e)}
          defaultValue={(promotion?.status || values.status) ?? EStatus.Draft}
          name="status"
        >
          <VStack align="flex-start" spacing={2} sx={{ fontSize: 'sm' }}>
            <Radio value={EStatus.Active}>{EStatus.Active}</Radio>
            <Radio value={EStatus.Draft}>{EStatus.Draft}</Radio>
            <Radio value={EStatus.Scheduled}>{EStatus.Scheduled}</Radio>
          </VStack>
        </RadioButtonWrapper>

        <BCCheckbox
          name="available_on"
          title="Available On"
          values={PROMO_TYPES}
          banner={promotion?.available_on ?? []}
        />

        {values.status === EStatus.Scheduled && (
          <>
            Start time
            <DateTimePicker
              disableClock
              minDate={
                promotion?.start_date
                  ? new Date(promotion.start_date)
                  : new Date()
              }
              name="start_date"
              value={
                values.start_date ? new Date(values.start_date) : undefined
              }
              wrapperProps={{ h: '10' }}
              onChange={(value) =>
                setFieldValue(
                  'start_date',
                  value === null
                    ? undefined
                    : dayjs(value).format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
                )
              }
            />
            {errors.start_date && <TextError>{errors.start_date}</TextError>}
            End time
            <DateTimePicker
              disableClock
              minDate={new Date()}
              name="end_date"
              value={values.end_date ? new Date(values.end_date) : undefined}
              wrapperProps={{ h: '10' }}
              onChange={(value) =>
                setFieldValue(
                  'end_date',
                  value === null
                    ? undefined
                    : dayjs(value).format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
                )
              }
            />
            {errors.end_date && <TextError>{errors.end_date}</TextError>}
          </>
        )}
        {!values.has_money_back_promo && (
          <>
            <Text mt="5">Banner Display</Text>
            <RadioButtonWrapper
              onChange={(e: string) => setFieldValue('display', e)}
              defaultValue={
                (promotion?.display || values.display) ?? EDisplay.LoggedIn
              }
              name="display"
            >
              <VStack align="flex-start" spacing={2} sx={{ fontSize: 'sm' }}>
                <Radio value={EDisplay.LoggedIn}>{EDisplay.LoggedIn}</Radio>
                <Radio value={EDisplay.LoggedOut}>{EDisplay.LoggedOut}</Radio>
                <Radio value={EDisplay.Both}>{EDisplay.Both}</Radio>
              </VStack>
            </RadioButtonWrapper>
          </>
        )}

        <Checkbox
          size="md"
          colorScheme="blue"
          defaultChecked={!!values.has_money_back_promo}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('has_money_back_promo', e.target.checked);
            setFieldValue('display', EDisplay.Both);
          }}
        >
          Money Back Promotion
        </Checkbox>

        {(promotion?.has_money_back_promo || !!values.has_money_back_promo) && (
          <Select
            mt="3"
            placeholder="Select a Promo"
            defaultValue={
              !!promotion
                ? promotion.money_back_promo_id
                : values.money_back_promo_id
            }
            value={
              !!values.money_back_promo_id
                ? values.money_back_promo_id
                : promotion?.money_back_promo_id
            }
            onChange={(e) =>
              setFieldValue('money_back_promo_id', e.target.value)
            }
          >
            {promotions?.data.promotions.map((promotion) => (
              <option key={promotion.promo_id} value={promotion.promo_id}>
                {promotion.name}
              </option>
            ))}

            {/* Add more options as needed */}
          </Select>
        )}

        {values.display === EDisplay.LoggedIn && (
          <>
            <BCCheckbox
              name="location"
              title="Locations"
              values={AU_STATES}
              banner={promotion?.location ?? []}
            />

            <BCCheckbox
              name="punter_category"
              title="Punter Category"
              values={punterCategoryList}
              banner={promotion?.punter_category ?? []}
            />
          </>
        )}

        {promotion && (
          <Flex gap={1}>
            <Button
              colorScheme="blue"
              variant="outline"
              isDisabled={!dirty}
              onClick={() => navigate(`/promo-manager/promotions/`)}
            >
              Cancel
            </Button>

            <Button
              colorScheme="blue"
              variant="solid"
              type="submit"
              isLoading={isSubmitting}
              isDisabled={!dirty || isSubmitting}
            >
              Save changes
            </Button>
          </Flex>
        )}
        {promotion && (
          <Button
            colorScheme="red"
            variant="outline"
            onClick={handleDelete}
            w="full"
            mt="3"
          >
            Discard
          </Button>
        )}
      </BCGridItem>
    </BCGridWrapper>
  );
}
