import api from '../../../api';
import { TSettleSportMarketPropositionsBody } from './settleSportMarketPropositions.types';

export const settleSportMarketPropositionsURL =
  '/bookie/trade-manager/sport-details/settle-sport-market-propositions';

// Post: settle sport market propositions
export const mutateSettleSportMarketPropositions = (
  body: TSettleSportMarketPropositionsBody
) =>
  api
    .post<TSettleSportMarketPropositionsBody>(
      settleSportMarketPropositionsURL,
      body
    )
    .then((res) => res.data);
