import { RacingType } from '../../../../../lib/Constants';
import { TRacingCountryAndStates } from '../../TradeManager/tabs/ByRacing/types';
import {
  TRaceDetails,
  TRunnerResult,
  ReactRouterLocationState,
  TVenue,
  TRaceExoticResult,
  ERaceStatus,
  TRaceMarket,
} from '../../../../../lib/DBModels';
import { TRaceDetailsFormValues } from '../components/Markets/WinPlace/components/RaceDetailsTable/RaceDetailsTable.types';

export type TModifierType = 'win_proposition' | 'place_proposition';

export enum EEventType {
  Race = 'race',
  Match = 'match',
}

export type TConfigureRunnerModal = {
  isOpen: boolean;
  values?: TRaceDetailsFormValues;
};

export type TRaceDetailsState = {
  raceData: TRaceDetails;
  raceMarkets: TRaceMarket[];
  raceResults: TRunnerResult[] | undefined;
  raceExoticResults: TRaceExoticResult[] | undefined;
  raceNumbers: TRaceNumbersForVenueResponse[];
  raceNumbersLoading: boolean;
  raceConfigLoading: boolean;
  manualManageSwitchOn: boolean;
  ignoreWinterSwitchOn: boolean;
  raceResultsLoading: boolean;
  configureRunnerModal: TConfigureRunnerModal;
  isConfigureRaceModalOpen: boolean;
  isConfigureRaceModalLoading: boolean;
};

export type TRaceRunnerMarginModifiersInput = {
  race_runner_id: string;
  propositionType: TModifierType;
  updateValue: number;
};

export type TRaceNumbersForVenueResponse = {
  race_id: string;
  race_number: string;
  start_time: string;
  win_modifier: number;
  place_modifier: number;
};

export interface ReactRouterLocationByRacingFilterState
  extends ReactRouterLocationState {
  filterParams?: {
    raceType?: RacingType;
    venue?: TVenue;
    country?: TRacingCountryAndStates;
    state?: string;
    status?: ERaceStatus;
    startDate?: string;
    endDate?: string;
    sortBy?: string;
    sortDirection?: 'asc' | 'desc';
    selectedPage: number;
  };
}

export enum ERaceMarketType {
  WinPlace = 'winplace',
  Exotics = 'exotics',
  MultiBets = 'multibets',
  SRMulti = 'srmulti',
  Blended = 'blended',
  EvenShot = 'even_shot',
  MysteryBet = 'mystery_bet',
}

export enum EModifierType {
  Win = 'win',
  Place = 'place',
}
