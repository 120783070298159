import React from 'react';
import { TColumnConfig } from '@/common/components/TableChakra/TableChakra.types';
import { FormattedMessage } from 'react-intl';
import { TTransaction } from '@/api/regulator/transactions/transactions.types';
import {
  TextTableCell,
  TextTableHeader,
} from '@/views/Regulator/Regulator.styles';

export const useColumns = () => {
  const columns: TColumnConfig<TTransaction>[] = [
    {
      property: 'sequence_number',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.sequencenumber" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.sequence_number}</TextTableCell>,
    },
    {
      property: 'bookie_name',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.bookiename" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.bookie_name}</TextTableCell>,
    },
    {
      property: 'full_name',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.clientname" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.full_name}</TextTableCell>,
    },
    {
      property: 'email',
      header: <TextTableHeader>Client ID</TextTableHeader>,
      render: (data) => <TextTableCell>{data.email}</TextTableCell>,
    },
    {
      property: 'punter_location',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.punterlocation" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.punter_location}</TextTableCell>,
    },
    {
      property: 'ip_address',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.ipaddress" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.ip_address}</TextTableCell>,
    },
    {
      property: 'transaction_type',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.transactiontype" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.transaction_type}</TextTableCell>,
    },
    {
      property: 'amount',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.amount" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.amount}</TextTableCell>,
    },
    {
      property: 'comment',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.comment" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.comment}</TextTableCell>,
    },
    {
      property: 'created_at',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.createdat" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.created_at}</TextTableCell>,
    },
    {
      property: 'timezone',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.bettinghistory.timezone" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.timezone}</TextTableCell>,
    },
    {
      property: 'running_balance',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.runningbalance" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.running_balance}</TextTableCell>,
    },
    {
      property: 'status',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.transactionstatus" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.status}</TextTableCell>,
    },
    {
      property: 'success_at',
      header: (
        <TextTableHeader>
          <FormattedMessage id="regulatorpage.successat" />
        </TextTableHeader>
      ),
      render: (data) => <TextTableCell>{data.success_at}</TextTableCell>,
    },
  ];

  return { columns };
};
