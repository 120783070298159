import { keys } from '@/api/api.keys';
import { useMutationAddCompetition } from '@/api/tradeManager/marketCreation/addCompetition/addCompetition.hooks';
import { useMutationAddMarket } from '@/api/tradeManager/marketCreation/addMarket/addMarket.hooks';
import { useMutationAddMarketGroup } from '@/api/tradeManager/marketCreation/addMarketGroup/addMarketGroup.hooks';
import { useMutationAddMatch } from '@/api/tradeManager/marketCreation/addMatch/addMatch.hooks';
import { useMutationAddProposition } from '@/api/tradeManager/marketCreation/addProposition/addPropostion.hooks';
import { useMutationAddSport } from '@/api/tradeManager/marketCreation/addSport/addSport.hooks';
import { useQueryGetCompetition } from '@/api/tradeManager/marketCreation/getCompetition/getCompetition.hooks';
import { useQueryGetSport } from '@/api/tradeManager/marketCreation/getSport/getSport.hooks';
import { useQueryGetSportMarket } from '@/api/tradeManager/marketCreation/getSportMarket/getSportMarket.hooks';
import { useMutationUpdateCompetition } from '@/api/tradeManager/marketCreation/updateCompetition/updateCompetition.hooks';
import { useMutationUpdateMarket } from '@/api/tradeManager/marketCreation/updateMarket/updateMarket.hooks';
import { useMutationUpdateMarketGroup } from '@/api/tradeManager/marketCreation/updateMarketGroup/updateMarketGroup.hooks';
import { useMutationUpdateMatch } from '@/api/tradeManager/marketCreation/updateMatch/updateMatch.hooks';
import { useMutationUpdateProposition } from '@/api/tradeManager/marketCreation/updateProposition/updateProposition.hooks';
import { useMutationUpdateSport } from '@/api/tradeManager/marketCreation/updateSport/updateSport.hooks';
import { TOptionType } from '@/common/components/FormElements/SelectAdvanced/SelectAdvanced';
import { useAppDispatch, useAppSelector } from '@/common/hooks/useRedux';
import { dollarsToCents } from '@/common/utils';
import { setCustomMarketModal } from '@/features/tradeManager/pages/TradeManager/slices';
import { ECustomMarketModalMode } from '@/features/tradeManager/pages/TradeManager/types';
import { MARGIN_TICK_MAP } from '@/lib/Constants';
import { useTheme } from '@chakra-ui/react';
import { FormikContextType } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { SingleValue } from 'react-select';
import { TCustomMarketsFormValues } from '../../../pages/TradeManager/tabs/CustomMarkets/Custommarket.types';
import { INITIAL_FORM_VALUES } from './CustomMarketModal.config';
import {
  checkFormValuesChanged,
  formatDateTime,
  getPrefilledFormValues,
} from './CustomMarketModal.utils';

export const useCustomMarketForm = (
  context?: FormikContextType<TCustomMarketsFormValues>
) => {
  const theme = useTheme();
  const intl = useIntl();

  const [, setPropositionsTouched] = useState<number>(0);

  const [sportOptions, setSportOptions] = useState<TOptionType[]>([]);

  const [competitionOptions, setCompetitionOptions] = useState<TOptionType[]>(
    []
  );

  const [firstFetch, setFirstFetch] = useState<boolean>(true);

  const racingFutures = 'Racing Futures';

  const {
    customMarketModal: { mode, market_id },
  } = useAppSelector((state) => state.allSports);

  const { data: sportMarket, isLoading } = useQueryGetSportMarket({
    params: {
      market_id,
    },
    options: {
      enabled: mode === ECustomMarketModalMode.Edit && !!market_id,
      onSuccess(data) {
        // Parse the data to the format expected by the form
        const formData = getPrefilledFormValues(data);

        if (firstFetch) {
          // Set values in the form context
          context?.setValues(formData);
          setFirstFetch(false);
        }
      },
    },
  });

  const { isLoading: sportsLoading } = useQueryGetSport({
    options: {
      onSuccess(data) {
        const sportSelectOptions = data
          ?.filter(
            (s) =>
              s.display_name === 'Specials' ||
              s.display_name === 'Racing Futures' ||
              s.display_name === 'Sports Futures'
          )
          .map((s) => ({
            value: s.sport_id ?? '',
            label: s.display_name ?? '',
          }));

        setSportOptions(sportSelectOptions);
      },
    },
  });

  const { isLoading: competitionsLoading } = useQueryGetCompetition({
    params: {
      sport_id: context?.values?.code?.value,
    },
    options: {
      enabled: !!context?.values.code && !context?.values.code.__isNew__,
      onSuccess(data) {
        const competitionSelectOptions = data?.map((s) => ({
          value: s.competition_id ?? '',
          label: s.display_name ?? '',
        }));

        setCompetitionOptions(competitionSelectOptions);

        // Competition name has to be 'Racing Futures' for Racing Futures sport
        if (context?.values?.code?.label === racingFutures) {
          context?.setFieldValue(
            'competition',
            competitionSelectOptions.find((c) => c.label === racingFutures) ?? {
              label: racingFutures,
              value: racingFutures,
              __isNew__: true,
            }
          );
        }
      },
    },
  });

  /**
   * Handles changes to the sport select component.
   * If a sport is selected and the form context exists, update the "code" field in the form context with the selected value.
   * If the "competition" field in the form context is not empty, clear its value to avoid inconsistent data.
   * If the selected sport is a newly created option, add it to the options array used in the component.
   *
   * @param selectedSport The selected sport option in the format { label: string, value: string, __isNew__?: boolean }
   */
  const onChangeSport = (selectedSport: SingleValue<TOptionType>) => {
    if (!context || !selectedSport?.value) return;

    const { setFieldValue, values } = context;

    // Clear the "competition" field if a sport is selected and it has a value
    if (values.competition) {
      setFieldValue('competition', '');
    }

    // Add the selected sport to the options array if it's a newly created option and replace any other existing new option
    if (selectedSport?.__isNew__) {
      setSportOptions((prev) => {
        const noNewOptionsPrev = prev.filter((o) => !o.__isNew__);

        return [...noNewOptionsPrev, selectedSport];
      });
    }

    // Update the "code" field in the form context with the selected sport value
    setFieldValue('code', selectedSport);
  };

  /**
   * Handles changes to the competition select component.
   * If a competition is selected and the form context exists, update the "code" field in the form context with the selected value.
   * If the selected competition is a newly created option, add it to the options array used in the component.
   *
   * @param selectedCompetition The selected competition option in the format { label: string, value: string, __isNew__?: boolean }
   */
  const onChangeCompetition = (
    selectedCompetition: SingleValue<TOptionType>
  ) => {
    if (!context || !selectedCompetition?.value) return;

    const { setFieldValue } = context;

    // Add the selected competition to the options array if it's a newly created option
    if (selectedCompetition?.__isNew__) {
      setCompetitionOptions((prev) => [...prev, selectedCompetition]);
    }

    // Update the "competition" field in the form context with the selected competition value
    setFieldValue('competition', selectedCompetition);
  };

  const addProposition = (index: number) => {
    if (!context) return;

    const { setFieldValue, values } = context;

    const newPropositions = [...values.propositions];
    newPropositions[index] = {
      name: '',
      odds: 1.01,
      tickIndex: 0,
      isNew: true,
    };
    setFieldValue('propositions', newPropositions);
  };

  const removeProposition = (index: number) => {
    if (!context) return;

    const { setFieldValue, values } = context;

    const newPropositions = [...values.propositions];
    newPropositions.splice(index, 1);
    setFieldValue('propositions', newPropositions);
  };

  const handleChangeOdds = (newVal: number, i: number) => {
    if (!context) return;

    const { setFieldValue, values } = context;

    const newPropositions = [...values.propositions];

    const newTickIndex = (newPropositions[i].tickIndex ?? 0) + newVal;

    // Clamp the tickIndex between 0 to MARGIN_TICK_MAP length - 1 (0 indexed)
    const clampedTickIndex = Math.min(
      MARGIN_TICK_MAP.length - 1,
      Math.max(0, newTickIndex)
    );

    newPropositions[i] = {
      ...newPropositions[i],
      odds: MARGIN_TICK_MAP[clampedTickIndex].odds,
    };
    setFieldValue('propositions', newPropositions);
  };

  /*
   * If we're editing a market, set the initial number of propositions to the number of propositions in the market.
   * This way we can track how many propositions were added/removed and update the market accordingly.
   */
  const initialPropositions =
    mode === ECustomMarketModalMode.Edit
      ? sportMarket?.competition?.match?.market?.propositions?.length ?? 1
      : 1;

  return {
    isLoading,
    sportOptions,
    competitionOptions,
    sportsLoading,
    competitionsLoading,
    modalMode: mode,
    theme,
    racingFutures,
    intl,
    initialPropositions,
    onChangeSport,
    onChangeCompetition,
    addProposition,
    removeProposition,
    handleChangeOdds,
    setPropositionsTouched,
  };
};

export const useCustomMarketFormHOC = () => {
  const client = useQueryClient();

  const dispatch = useAppDispatch();

  const [initialValues, setInitialValues] =
    useState<TCustomMarketsFormValues>(INITIAL_FORM_VALUES);

  const { customMarketModal } = useAppSelector((state) => state.allSports);
  const { mode, market_id } = customMarketModal;

  /** ADD/CREATE MARKET Mutations */
  const { mutateAsync: mutateAddCompetition } = useMutationAddCompetition();
  const { mutateAsync: mutateAddMarket } = useMutationAddMarket();
  const { mutateAsync: mutateAddMarketGroup } = useMutationAddMarketGroup();
  const { mutateAsync: mutateAddMatch } = useMutationAddMatch();
  const { mutateAsync: mutateAddProposition } = useMutationAddProposition();
  const { mutateAsync: mutateAddSport } = useMutationAddSport();

  /** UPDATE MARKET Mutations */
  const { mutateAsync: mutateUpdateCompetition } =
    useMutationUpdateCompetition();
  const { mutateAsync: mutateUpdateMarket } = useMutationUpdateMarket();
  const { mutateAsync: mutateUpdateMarketGroup } =
    useMutationUpdateMarketGroup();
  const { mutateAsync: mutateUpdateMatch } = useMutationUpdateMatch();
  const { mutateAsync: mutateUpdateProposition } =
    useMutationUpdateProposition();
  const { mutateAsync: mutateUpdateSport } = useMutationUpdateSport();

  const { data: sportMarket } = useQueryGetSportMarket({
    params: {
      market_id,
    },
    options: {
      enabled: mode === ECustomMarketModalMode.Edit && !!market_id,
      onSuccess(data) {
        // Parse the data to the format expected by the form and set it as the initial values
        setInitialValues(getPrefilledFormValues(data));
      },
    },
  });

  const onSubmit = async (values: TCustomMarketsFormValues) => {
    if (!values.code || !values.competition) return;

    /** Check if we created any new sports or competitions */

    let newSportID = null;

    if (values.code.__isNew__) {
      await mutateAddSport([{ name: values?.code?.label }]).then((res) => {
        if (!res || !Array.isArray(res) || !res.length || !res[0]) return;

        newSportID = res[0];
      });
    }

    let newCompetitionID = null;

    if (newSportID || values.competition.__isNew__) {
      await mutateAddCompetition([
        {
          name: values?.competition?.label,
          sport_id: newSportID ?? values.code.value,
        },
      ]).then((res) => {
        if (!res || !Array.isArray(res) || !res.length || !res[0]) return;

        newCompetitionID = res[0];
      });
    }

    let newMatchID = '';

    await mutateAddMatch([
      {
        name: values?.match,
        competition_id: newCompetitionID ?? values.competition.value,
        start_time: formatDateTime({ date: values.date, time: values.time }),
      },
    ]).then((res) => {
      if (!res || !Array.isArray(res) || !res.length || !res[0]) return;

      newMatchID = res[0];
    });

    await mutateAddMarketGroup([
      {
        name: values?.market_group_name,
        match_id: newMatchID,
      },
    ]);

    let newMarketID = '';

    await mutateAddMarket([
      {
        name: values?.market_name,
        match_id: newMatchID,
        market_type: values.market_type,
      },
    ]).then((res) => {
      if (!res || !Array.isArray(res) || !res.length || !res[0]) return;

      newMarketID = res[0];
    });

    await mutateAddProposition(
      values.propositions
        .filter((p) => p)
        .map((p) => ({
          name: p.name,
          odds: p.odds,
          market_id: newMarketID,
          max_stake_limit: Number(dollarsToCents(values.max_bet)),
          allow_multi: values.allow_multis,
        }))
    );

    await client.invalidateQueries([keys.getMarket]);
    await client.refetchQueries([keys.getMarket]);

    dispatch(
      setCustomMarketModal({
        isOpen: false,
        market_id: undefined,
        market_name: undefined,
      })
    );
  };

  const onUpdate = async (values: TCustomMarketsFormValues) => {
    if (!values.code || !values.competition) return;

    const hasChanged = checkFormValuesChanged(values, initialValues);

    if (hasChanged.code) {
      await mutateUpdateSport([
        { name: values?.code?.label, sport_id: sportMarket?.sport_id },
      ]);
    }

    if (hasChanged.competition) {
      await mutateUpdateCompetition([
        {
          name: values?.competition?.label,
          competition_id: sportMarket?.competition?.competition_id,
        },
      ]);
    }

    if (hasChanged.match || hasChanged.date || hasChanged.time) {
      await mutateUpdateMatch([
        {
          name: values?.match,
          match_id: sportMarket?.competition?.match?.match_id,
          start_time:
            hasChanged.date || hasChanged.time
              ? formatDateTime({ date: values.date, time: values.time })
              : sportMarket?.competition?.match?.start_time,
        },
      ]);
    }

    if (hasChanged.market_group_name) {
      await mutateUpdateMarketGroup([
        {
          name: values?.market_group_name,
          market_group_id:
            sportMarket?.competition?.match?.market_groups?.[0]
              ?.market_group_id,
        },
      ]);
    }

    if (hasChanged.market_name || hasChanged.market_type) {
      await mutateUpdateMarket([
        {
          name: values?.market_name,
          market_id: sportMarket?.competition?.match?.market?.market_id,
          market_type: values?.market_type,
        },
      ]);
    }

    if (values.propositions.some((p) => p.isNew)) {
      await mutateAddProposition(
        values.propositions
          .filter((p) => p.isNew)
          .map((p) => ({
            name: p.name,
            odds: p.odds,
            market_id: sportMarket?.competition?.match?.market?.market_id,
            max_stake_limit: Number(dollarsToCents(values.max_bet)),
            allow_multi: values.allow_multis,
          }))
      );
    }

    if (
      (hasChanged.propositions ||
        hasChanged.max_bet ||
        hasChanged.allow_multis) &&
      sportMarket?.competition?.match?.market?.propositions?.length
    ) {
      await mutateUpdateProposition(
        sportMarket?.competition?.match?.market?.propositions?.map((p) => ({
          name: values.propositions.find((v) => v.id === p?.proposition_id)
            ?.name,
          proposition_id: p?.proposition_id,
          market_id: sportMarket?.competition?.match?.market?.market_id,
          max_stake_limit: Number(dollarsToCents(values.max_bet)),
          odds: values.propositions.find((v) => v.id === p?.proposition_id)
            ?.odds,
          allow_multi: values.allow_multis,
        }))
      );
    }

    await client.invalidateQueries([keys.getMarket]);
    await client.refetchQueries([keys.getMarket]);
    dispatch(
      setCustomMarketModal({
        isOpen: false,
        market_id: undefined,
        market_name: undefined,
      })
    );
  };

  return {
    modalMode: mode,
    initialValues,
    onSubmit,
    onUpdate,
  };
};
