import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TUpdateMarketBody } from './updateMarket.types';

const updateMarketURL = '/bookie/trade-manager/market-creation/update-market';

// Trade Manager - Update Market

export const mutateUpdateMarket = (body: TUpdateMarketBody) =>
  api
    .post<TMarketCreationResponse>(updateMarketURL, body)
    .then((res) => res.data);
