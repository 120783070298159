import { TDeepPartial } from '../../../../lib/types';
import { EGeneralStatus, TPromotion } from '../../../../lib/DBModels';

export type TEventResponse = {
  items: TEvent[];
  paging: { next_offset: string };
};

export type TEvent = TDeepPartial<{
  event_id: string;
  event_type: string;
  event_title: string;
  event_subtitle: string;
  event_start_time: string;
  event_status: EGeneralStatus;
  event_icon: string;
  total_bets: number;
  biggest_bet: number;
  turnover: number;
  exposure: number;
  event_suspended: boolean;
  cash_out_turnover: number | null;
  promotions?: TPromotion[];
}>;

export type TEventsResponse = TDeepPartial<{
  records: TEvent[];
  total_pages: number;
  total_records: number;
}>;

export type TStatusType = {
  value: string;
  label: string;
};

export type TModifier = TDeepPartial<{
  display_modifier: number;
  modifier: number;
}>;

export type TSport = TDeepPartial<{
  sport_id: string | undefined;
  sport_name: string;
  display_name?: string;
  icon?: string;
  sport_modifier?: TModifier;
}>;

export enum ECustomMarketModalMode {
  Edit = 'Edit',
  Create = 'Create',
}

type TCustomMarketModal = {
  isOpen: boolean;
  mode?: ECustomMarketModalMode;
  market_id?: string;
  market_name?: string;
};

export type TAllSportsState = {
  allSports: TSport[];
  sportsLoading: boolean;
  customMarketModal: TCustomMarketModal;
};
