import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Badge,
  Flex,
  Icon,
  Text,
  Image,
  Tooltip,
  Link,
  Box,
} from '@chakra-ui/react';
import { Refund } from '@styled-icons/remix-fill';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import SRMLogo from '@/assets/icons/srm-icon.svg';
import BlendedLogo from '@/assets/icons/blended-icon.svg';
import {
  EBetStatus,
  EPlayerType,
  EStakeReductionReason,
  TPriceType,
  TPromotion,
} from '../../../../../lib/DBModels';
import { StartTimeCalculator } from '../../../../../common/components';
import {
  getStrings,
  getIconAssetPath,
  centsToDollars,
} from '../../../../../common/utils';
import { AppState } from '../../../../../app/types';
import BetStatusBadge from '../../../../../common/components/BetStatusBadge';
import BetsTablePopover from '../../../../../common/components/BetsTablePopover';
import { useVoidBetModal } from '../../../modals/VoidBetModal/Services/Services.VoidBet';
import { TFilterOptionsParams, getPlacedBetStates } from '../PlacedBets.utils';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../common/hooks/useRedux';
import { useDebounce } from '../../../../../common/hooks/useDebounce';
import MBLBadge from '../../../../../common/components/MBLBadge/MDLBadge';
import BetReturnText from '../../../../../common/components/BetReturnText';
import { TExoticCombo } from '../../../../../common/components/ExoticsBetSelection/Services/Types.ExoticsBetCombos';
import { setModalInfoBet } from '../../../../punters/pages/Bets/Services/Slice.Bets';
import { TBetsData } from '../types';
import { usePunterCategory } from '../tabs/Upcoming/Services/Upcoming.utils';
import IconSvg from '@/components/IconSvg/IconSvg';
import {
  useQueryPlacedBets,
  useQueryPlacedBetsWincore,
} from '../../../../../api/placedBets/placedBets.hooks';
import { TFilter } from '../tabs/Upcoming/Services/Upcoming.slice';
import { UserBadge } from '@/features/punters/pages/Punters/styles/Punters.styles';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { logoFromBookieName } from '@/common/components/BetsTable/components/utils';
import { TStickyNote } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.types';
import { ManualApprovalTooltip } from '@/features/bets/components/ManualApprovalTooltip';
import { TPlacedBetWincore } from '@/api/placedBets/placedBets.types';
import { TBetLegs } from '@/api/punters/punters.types';
import { TPromoToken } from '@/api/promos/token/tokenSummary.types';
import { OddsBoostTooltip } from '@/features/bets/components/OddsBoostTooltip';

export enum EEventType {
  Race = 'Race',
  Match = 'Match',
}

export const useColumns = (includeStartTimeCalc = false) => {
  const { showVoidBetFromBet } = useVoidBetModal();
  const navigate = useNavigate();

  const { getPlayerIcon, getPunterCategoryColor } = usePunterCategory();

  const dispatch = useAppDispatch();
  const [{ BetTablePopover: BetTablePopoverStrings }] = getStrings();

  const [
    {
      PlaceBetsPage: { Table },
    },
  ] = getStrings();

  const location = useLocation();

  const isUpcoming = location.pathname === '/placed-bets/upcoming';

  const columns = [
    {
      accessorKey: 'event_title',
      header: Table.EventMarketPunter,
      omit: true,
      cell: (props: {
        row: {
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          original: any;
          promotions: TPromotion[];
        };
        getValue: () => string | undefined;
      }) => {
        const bet = props.row.original;
        let isSGMBet = false;
        let isSRMBet = false;
        let isBlended = false;
        let isEvenShot = false;
        let isMulti = false;
        let isMysteryBet = false;
        let betIcon = '';

        if (!isWincore) {
          isSGMBet =
            bet.bet_description?.toLocaleLowerCase() === 'same game multi';
          isSRMBet =
            bet.bet_description?.toLocaleLowerCase() === 'same race multi';
          isBlended = bet.bet_description?.toLowerCase() === 'blended';
          isMulti = bet.bet_description?.toLowerCase() === 'multi';
          isEvenShot = bet.bet_description
            ?.toLowerCase()
            .startsWith('even shot');
          betIcon = bet.event_icon;
          isMysteryBet = bet.price_type === 'mystery_bet';
        } else {
          isSGMBet = bet.bet_type === 'SGMulti';
          isSRMBet = bet.bet_type === 'SRMulti';
          isBlended = bet.bet_type === 'Blended';
          isMulti =
            bet.bet_type === 'Multi' &&
            bet.bet_legs[0].price_type !== 'mystery_bet';
          isEvenShot =
            bet.bet_type === 'Single' &&
            bet.bet_legs[0].price_type === 'even_shot';
          betIcon = isMulti ? 'default' : bet.bet_legs[0].event_icon;
          isMysteryBet = bet.bet_legs[0].price_type === 'mystery_bet';
        }

        const hasRollover = isMysteryBet && (bet.bet_legs?.length ?? 0) > 1;

        const promotions = bet.promotions?.filter(
          (promotion: TPromotion) => promotion.type === 'money_back'
        );

        return (
          <>
            <Flex direction="row">
              <Flex direction="row" mr={4} width={8} alignItems="center">
                <IconSvg
                  name={getIconAssetPath(
                    'sports',
                    isMysteryBet ? 'square-question' : betIcon
                  )}
                  sx={{ boxSize: '10' }}
                />
              </Flex>
              <Flex direction="column" justify="center" align="left" gap="2">
                <Link
                  _hover={{ textDecoration: 'none' }}
                  href={
                    isWincore
                      ? '#'
                      : `/trade-manager/${(!isWincore
                          ? bet.event_type
                          : bet.bet_legs[0].event_type
                        )?.toLowerCase()}-details?id=${
                          !isWincore
                            ? props.row.original.event_id
                            : props.row.original.bet_legs[0].event_id
                        }`
                  }
                  onClick={() => {
                    if (!isWincore) return false;
                  }}
                >
                  <Flex flexDir="row" gap="2">
                    {includeStartTimeCalc &&
                      (!isWincore
                        ? bet.event_start
                        : bet.bet_legs[0].event_start) && (
                        <StartTimeCalculator
                          sx={{ justifyContent: 'flex-start' }}
                          date={
                            !isWincore
                              ? bet.event_start
                              : bet.bet_legs[0].event_start
                          }
                        />
                      )}

                    {!!promotions &&
                      promotions.map((promotion: TPromotion) => (
                        <Box
                          key={promotion.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/promo-manager/money-back/promotion?id=${promotion.id}}`
                            );

                            return;
                          }}
                        >
                          <IconSvg
                            name={getIconAssetPath('sports', 'money-back')}
                            sx={{ boxSize: '6', cursor: 'pointer' }}
                          />
                        </Box>
                      ))}
                  </Flex>
                  <Text sx={{ fontWeight: 'bold' }} fontSize="sm">
                    {!isMulti
                      ? !isWincore
                        ? bet.event_title
                        : bet.bet_legs[0].event_title ?? ''
                      : ''}
                  </Text>
                </Link>
                <Flex flexDir="row">
                  {isMysteryBet &&
                    `Mystery Bet ${hasRollover ? 'with Rollover' : ''}`}

                  {isMulti && !isMysteryBet && (
                    <Text>
                      {bet.bet_legs?.length} {Table.Legs}{' '}
                      {!isWincore
                        ? bet.bet_description ?? ''
                        : bet.bet_type ?? ''}
                    </Text>
                  )}
                  {!(
                    isSGMBet ||
                    isSRMBet ||
                    isBlended ||
                    isEvenShot ||
                    isMulti ||
                    isMysteryBet
                  ) && (
                    <Text fontSize="sm">
                      {bet.event_type === EEventType.Race
                        ? !isWincore
                          ? bet.event_subtitle
                          : bet.bet_legs[0].event_subtitle
                        : !isWincore
                        ? bet.market_name
                        : bet.bet_legs[0].market_name}
                    </Text>
                  )}

                  {isSRMBet && (
                    <Flex alignItems="center" mt="-2">
                      {bet.bet_legs?.length} Legs{' '}
                      <Image
                        src={SRMLogo}
                        sx={{ boxSize: '10', ml: '2' }}
                        alt="Same Game Multi Icon Hook"
                      />
                    </Flex>
                  )}

                  {isSGMBet && (
                    <Flex alignItems="center" mt="-2" h="30px">
                      {bet.bet_legs?.length} Legs{' '}
                      <IconSvg
                        name={getIconAssetPath('sports', 'samegamemulti')}
                        sx={{ boxSize: '10', ml: '2' }}
                      />
                    </Flex>
                  )}

                  {isBlended && (
                    <Flex alignItems="center" mt="-2">
                      {bet.bet_legs?.length} Legs Blended
                      <Image
                        src={BlendedLogo}
                        sx={{ boxSize: '10', ml: '2' }}
                        alt="Same Game Multi Icon Hook"
                      />
                    </Flex>
                  )}

                  {isEvenShot && (
                    <Flex alignItems="start" flexDirection="row">
                      {bet.event_type === EEventType.Race
                        ? !isWincore
                          ? bet.event_subtitle
                          : bet.bet_legs[0].event_subtitle
                        : !isWincore
                        ? bet.market_name
                        : bet.bet_legs[0].market_name}
                      <Badge ml="2" colorScheme="blackAlpha">
                        Even shot
                      </Badge>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </>
        );
      },
    },
    {
      accessorKey: 'created_at',
      header: Table.Date,
      cell: (props: { getValue: () => string | undefined }) => (
        <>
          <Text fontSize="sm">
            {dayjs(props.getValue()).format('DD/MM/YYYY')}
          </Text>
          <Text fontSize="sm">
            {dayjs(props.getValue()).format('HH:mm:ss')}
          </Text>
        </>
      ),
    },
    {
      accessorKey: 'punter_name',
      header: Table.Punter,
      omit: true,
      cell: (props: {
        row: {
          original: {
            punter_id: string;
            punter_type: EPlayerType;
            source_portal_url: string;
          };
        };
        getValue: () => string | undefined;
      }) => (
        <Flex>
          <Link
            _hover={{ textDecoration: 'none' }}
            href={
              isWincore
                ? `${props.row.original.source_portal_url}/punter/${props.row.original.punter_id}`
                : `/punter/${props?.row.original?.punter_id ?? ''}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text fontSize="sm">{props.getValue() ?? ''}</Text>
            <Flex alignItems="center">
              <Tooltip
                mr="1"
                hasArrow
                bg={getPunterCategoryColor(
                  props?.row.original?.punter_type ?? EPlayerType.Newbie
                )}
                label={props?.row.original?.punter_type ?? ''}
              >
                <UserBadge
                  bg={getPunterCategoryColor(
                    props?.row.original?.punter_type ?? EPlayerType.Newbie
                  )}
                >
                  <Image
                    src={getPlayerIcon(
                      props?.row.original?.punter_type ?? EPlayerType.Newbie
                    )}
                    boxSize="5"
                    borderRadius="full"
                    p="1"
                  />
                </UserBadge>
              </Tooltip>

              <Text>{props?.row.original?.punter_type}</Text>
            </Flex>
          </Link>
        </Flex>
      ),
    },
    {
      accessorKey: 'sticky',
      header: 'Notes',
      cell: (props: {
        row: { original: { sticky_note: TStickyNote } };
        getValue: () => string | undefined;
      }) =>
        props.row.original.sticky_note ? (
          <Flex
            sx={{
              bg: '#fffab1',
              p: '2',
              color: 'black',
              w: '150px',
              h: 'full',
              boxShadow: 'lg',
            }}
          >
            {props.row.original.sticky_note.text}
          </Flex>
        ) : null,
    },
    {
      accessorKey: 'status',
      header: Table.Status,
      cell: (props: {
        row: { original: { status: EBetStatus; source_platform_name: string } };
        getValue: () => string | undefined;
      }) => (
        <Flex flexDirection="column" maxW="50%">
          <BetStatusBadge status={props.getValue() as EBetStatus} />
          {props?.row.original.source_platform_name && (
            <img
              src={logoFromBookieName(props?.row.original.source_platform_name)}
              alt=""
              width="120px"
              style={{ marginTop: '10px', borderRadius: '5px' }}
            />
          )}
        </Flex>
      ),
    },
    {
      accessorKey: 'stake',
      header: Table.Stake,
      omit: true,
      cell: (props: {
        row: {
          original: {
            stake: number;
            bonus_stake: number;
            is_bonus: boolean;
            stake_reduction_reason: EStakeReductionReason;
          };
        };
        getValue: () => string | undefined;
      }) => (
        <>
          <Text fontSize="sm">
            {centsToDollars(
              props.row.original.is_bonus
                ? props.row.original.bonus_stake
                : props.row.original.stake ?? 0,
              true
            )}
          </Text>
          {props?.row.original.is_bonus && (
            <Badge
              variant="solid"
              bg="black"
              borderRadius="md"
              textAlign="center"
              my="1"
              px="2"
              w="fill-content"
            >
              {Table.BB}
            </Badge>
          )}
          {props?.row?.original?.stake_reduction_reason ===
            EStakeReductionReason.MBLThreshold && (
            <Flex flexDir="column">
              <MBLBadge />
            </Flex>
          )}
        </>
      ),
    },
    {
      accessorKey: 'odds',
      header: Table.Odds,
      cell: (props: {
        row: {
          original: {
            price_type?: TPriceType;
            bet_legs: TBetLegs[];
            promo_tokens: TPromoToken[];
          };
        };
        getValue: () => number | undefined;
      }) => {
        const odds = props.getValue();
        const { price_type } = props.row.original;
        const winCorePrice =
          props?.row?.original?.bet_legs &&
          props?.row?.original?.bet_legs[0]?.price_type;

        const renderContent = () => {
          const priceType = isWincore ? winCorePrice : price_type;
          if (odds === 0 && !priceType) return 'N/A';
          const isSPBet = props.row.original.price_type === 'starting';
          const oddsBoost = props.row.original.promo_tokens?.find(
            ({ token_type }) => token_type === 'odds-boost'
          );
          if (odds || isSPBet) {
            return (
              <Flex flexDirection="row">
                {odds?.toFixed(2)}
                {!!oddsBoost && (
                  <Text
                    whiteSpace="nowrap"
                    opacity="0.8"
                    textDecoration="line-through"
                    ml={1}
                  >
                    {oddsBoost.original_odds?.toFixed(2)}
                  </Text>
                )}
                {isSPBet && (
                  <Badge colorScheme="blue">
                    <FormattedMessage id="generic.startingPriceAcronym" />
                  </Badge>
                )}
                {!!oddsBoost && <OddsBoostTooltip />}
              </Flex>
            );
          }

          switch (priceType) {
            case 'tote_single_mid':
              return 'MD';
            case 'starting':
              return 'SP';
            case 'tote_single_best':
              return 'BT';
            default:
              return odds?.toFixed(2);
          }
        };

        return (
          <Text as="span" fontSize="sm">
            {renderContent()}
          </Text>
        );
      },
    },
    {
      accessorKey: 'return',
      header: isUpcoming ? Table.PotentialReturns : Table.Return,
      omit: true,
      cell: (props: {
        row: {
          original: {
            bet_type: string;
            status: EBetStatus;
            is_won: boolean;
            stake: number;
            odds: number;
            is_bonus: boolean;
            bonus_stake: number;
            exotic_selections: TExoticCombo[];
            selection: TExoticCombo[];
            payout: number;
            price_type?: TPriceType;
            cashout_value?: number;
            is_manual_approval: boolean;
            bet_legs: TBetLegs[];
            promo_tokens: TPromoToken[];
          };
        };
        getValue: () => number | undefined;
      }) => {
        const data = props?.row?.original;
        const firstLeg =
          props.row.original.bet_legs && props.row.original.bet_legs[0];
        const oddsBoost = data.promo_tokens?.find(
          ({ token_type }) => token_type === 'odds-boost'
        );

        if (
          data.status === EBetStatus.Voided ||
          data.status === EBetStatus.Cancelled
        ) {
          return <BetStatusBadge status={data.status} />;
        }

        if (isUpcoming) {
          const isExoticBet = !isWincore
            ? !!data.exotic_selections
            : data.bet_type === 'Exotics' &&
              Number(data?.selection?.length ?? 0) > 0;
          const isSPBet =
            data.price_type === 'starting' ||
            firstLeg?.price_type === 'starting';
          const isTote =
            data.price_type === 'tote_single_mid' ||
            data.price_type === 'tote_single_best' ||
            firstLeg?.price_type === 'tote_single_mid' ||
            firstLeg?.price_type === 'tote_single_best';

          if (isExoticBet || isSPBet || isTote) {
            return <FormattedMessage id="generic.na" />;
          }

          let potentialReturn;
          const isMysteryBet = isWincore
            ? data.bet_legs?.[0].price_type === 'mystery_bet'
            : data.price_type === 'mystery_bet';
          const hasRollover =
            isMysteryBet &&
            props.row.original.bet_type.toLowerCase() == 'multi';

          const mbOdds = hasRollover
            ? (data?.is_bonus ? data?.odds - 1 : data?.odds) * data?.odds
            : data?.is_bonus
            ? data?.odds - 1
            : data?.odds;

          if (isMysteryBet) {
            potentialReturn = data?.is_bonus
              ? mbOdds * data?.bonus_stake
              : mbOdds * data?.stake;
          } else {
            potentialReturn = data.is_bonus
              ? data.bonus_stake * data.odds - 1
              : data.stake * data.odds;
          }

          return (
            <>
              <Flex>
                {centsToDollars(potentialReturn)}
                {oddsBoost && (
                  <Text
                    as="span"
                    opacity="0.8"
                    textDecoration="line-through"
                    ml={1}
                  >
                    {centsToDollars(oddsBoost.original_odds * data.stake)}
                  </Text>
                )}
                {props.row.original.is_manual_approval && (
                  <ManualApprovalTooltip />
                )}
              </Flex>

              {data?.cashout_value && (
                <Text>
                  <Badge
                    variant="solid"
                    bg="origin.500"
                    borderRadius="md"
                    textAlign="center"
                    my="1"
                    px="2"
                    mr="2"
                    w="fill-content"
                  >
                    Cash out
                  </Badge>{' '}
                  {centsToDollars(data.cashout_value, true)}
                </Text>
              )}
            </>
          );
        }

        return (
          <Flex flexDir="column">
            <BetReturnText status={data?.status} payout={data?.payout} />
            {oddsBoost ? (
              <BetReturnText
                as="span"
                status={props.row.original?.status}
                payout={data?.is_won ? oddsBoost.original_odds * data.stake : 0}
                opacity="0.8"
                textDecoration="line-through"
              />
            ) : undefined}
            {props.row.original.is_manual_approval && <ManualApprovalTooltip />}
          </Flex>
        );
      },
    },
    {
      accessorKey: 'settings',
      header: '',
      cell: (props: {
        row: { original: { stake: number; status: EBetStatus } };
        getValue: () => number | undefined;
      }) => (
        <Flex w="10" justifyContent="space-around" m="0">
          <Icon
            onClick={() =>
              dispatch(setModalInfoBet(props.row.original as TBetsData))
            }
            boxSize="8"
            color="gray.600"
            borderRadius="full"
            p="1"
            _hover={{ bg: 'gray.100' }}
            as={EyeFill}
          />
          <BetsTablePopover
            rows={[
              {
                onClick: () => showVoidBetFromBet(props.row.original),
                title: BetTablePopoverStrings.Void,
                disabled:
                  props?.row.original?.status === EBetStatus.Voided ||
                  props?.row.original?.status === EBetStatus.Cancelled,
                icon: Refund,
              },
            ]}
          />
        </Flex>
      ),
    },
  ];

  return columns;
};

export const useResultedBets = (
  status: 'settled' | 'pending',
  params: {
    punter_category?: string[];
    bet_type?: string;
    max_odds?: number;
    min_exposure?: number;
    min_stake?: number;
  }
) => {
  /* TODO move into one object */
  const [dateFromFilter, setDateFromFilter] = useState<string | undefined>();
  const [dateToFilter, setDateToFilter] = useState<string | undefined>();
  const [punterFilter, setPunterFilter] = useState<string | undefined>();
  const [eventTypeFilter, setEventTypeFilter] = useState<string>('');
  const [cashoutOnly, setCashOutOnly] = useState<boolean>(false);
  const { resultedBetsResponse, betsLoading } = useSelector(
    (state: AppState) => state.resultedBets
  );

  const [sortBy, setSortBy] = useState<string | undefined>();
  const [sortDirection, setSortDirection] = useState<
    'asc' | 'desc' | undefined
  >();
  const [offset, setOffset] = useState<string>(isWincore ? '0' : '');
  const limit = 20;

  /* refetch if user is on the first page and no filter is set */
  const shouldRefetch =
    !dateFromFilter && !dateToFilter && !punterFilter && offset === '0';

  /* TODO this can be removed when all the filters are put in one object, see useHistoryRequestData hook as example */
  useEffect(() => {
    setOffset('0');
  }, [dateFromFilter, dateToFilter, punterFilter, eventTypeFilter]);

  const { data, isLoading } = useQueryPlacedBets({
    params: {
      offset: Number(offset === '' ? 0 : offset),
      limit,
      ...(eventTypeFilter && { event_type: eventTypeFilter }),
      states: cashoutOnly ? 'cashed_out' : getPlacedBetStates(),
      sort_direction: sortDirection,
      sort_by: sortBy,
      punter_name: punterFilter,
      start_date: dateFromFilter?.slice(0, 10),
      end_date: dateToFilter?.slice(0, 10),
    },
    options: {
      refetchInterval: shouldRefetch ? 5000 : 0, // polling every 5 sec
      refetchIntervalInBackground: shouldRefetch,
      enabled: !isWincore,
    },
  });

  const [pageIndex, setPageIndex] = useState(0);
  const [offsetHistory, setOffsetHistory] = useState<string[]>([]);

  const [wincoreData, setWincoreData] = useState<TPlacedBetWincore>();
  const { isLoading: isWincoreLoading, refetch } = useQueryPlacedBetsWincore({
    params: {
      statuses:
        status === 'pending'
          ? 'pending'
          : ['settled', 'cashed_out', 'voided', 'cancelled'].join(','),
      ...(offset !== '0' ? { offset } : {}),
      ...params,
    },
    options: {
      refetchIntervalInBackground: shouldRefetch,
      enabled: isWincore,
      keepPreviousData: true,
      onSuccess: (data) => {
        // needs to add the new offset it its exceeds the pageIndex otherwise we update it
        if (pageIndex === offsetHistory.length - 1)
          setOffsetHistory([...offsetHistory, data.paging.next_offset ?? '']);
        else
          setOffsetHistory((prevHistory) =>
            prevHistory.map((o, i) =>
              i === pageIndex ? data.paging.next_offset ?? '' : o
            )
          );

        setWincoreData(data);
      },
    },
  });

  useEffect(() => {
    if (isWincore) refetch();
  }, [params, refetch]);

  function next() {
    if (!wincoreData) return;
    setOffset(wincoreData.paging.next_offset ?? '');
    setPageIndex((prevIdx) => ++prevIdx);
  }

  function previous() {
    if (pageIndex === 0) return;
    setOffset(offsetHistory[pageIndex]);
    setPageIndex((prevIdx) => --prevIdx);
  }
  const hidePagination =
    pageIndex === 0 && wincoreData?.paging.next_offset === null;

  /**
   * if we're on the last page
   * and there isnt a token on the last data
   * we disable the next button
   */

  const handleUpdateBetFromSocket = useCallback(
    (bet: any, filterOptions: TFilterOptionsParams, isPending: boolean) =>
      setWincoreData((prev = { bets: [], paging: { next_offset: null } }) => {
        if (isPending) {
          if (bet.status !== 'pending') {
            return {
              ...prev,
              bets: prev.bets.filter((b) => b.bet_id !== bet.bet_id),
            };
          }
        }

        const {
          bet_type: betType,
          max_odds: maxOdds,
          min_exposure: minExposure,
          min_stake: minStake,
          punter_category: punterCategory,
        } = filterOptions;

        if (betType && betType !== bet.bet_type) return prev;
        if (maxOdds && maxOdds <= bet.odds) return prev;
        if (minExposure && minExposure > bet.stake * (bet.odds - 1))
          return prev;
        if (minStake && minStake > bet.stake) return prev;
        if (punterCategory && punterCategory !== bet.punter_type) return prev;

        return {
          ...prev,
          bets: [bet, ...prev.bets],
        };
      }),
    [setWincoreData]
  );

  const nextDisabled = wincoreData?.paging.next_offset === '';
  return {
    dateFromFilter,
    setDateFromFilter,
    dateToFilter,
    setDateToFilter,
    punterFilter,
    setPunterFilter,
    eventTypeFilter,
    setEventTypeFilter,
    resultedBetsResponse,
    betsLoading,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    offset,
    setOffset,
    data: isWincore ? wincoreData?.bets : data,
    isLoading: isWincore ? isWincoreLoading : isLoading,
    isLastFetch: (data?.length ?? 0) < limit,
    next,
    previous,
    previousDisabled: pageIndex === 0,
    nextDisabled,
    hidePagination,
    setCashOutOnly,
    handleUpdateBetFromSocket,
  };
};

export const useUpcomingBetsData = () => {
  const filters = useAppSelector((state) => state.upcomingBets.filter);
  const paramsWithEmptyValues = useDebounce(filters, 250);
  const params = Object.entries(paramsWithEmptyValues).reduce<TFilter>(
    (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
    {}
  );
  const { punter_name } = params;
  const [offset, setOffset] = useState(0);
  const limit = 20;

  /* refetch if user is on the first page and filter is not set */
  const shouldRefetch = !punter_name && offset === 0;

  const { data, isLoading, isFetching, isFetched } = useQueryPlacedBets({
    params: { ...params, limit, offset, states: EBetStatus.Pending },
    options: {
      refetchInterval: shouldRefetch ? 5000 : 0, // polling every 5 sec
      refetchIntervalInBackground: shouldRefetch,
      enabled: !isWincore,
    },
  });
  return {
    data,
    isLoading,
    isFetching,
    isFetched,
    isLastFetch: (data?.length ?? 0) < limit,
    offset,
    setOffset,
  };
};
