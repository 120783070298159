import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetRequest } from '../../../../../lib/api/api';
import { TGetMatch, TMatch } from '../../../../../lib/DBModels';
import { setMatchData } from '../slices';
import { AppThunkDispatch } from '../../../../../app/types';

export const getMatch = createAsyncThunk<
  TMatch,
  TGetMatch,
  { dispatch: AppThunkDispatch }
>('matchDetails/getMatch', async (params, thunkAPI) => {
  const matchData = await apiGetRequest<TMatch>(
    `/bookie/trade-manager/sport-details/match?match_id=${params.matchId}${
      params.isUpdate ? `&t=${new Date().valueOf()}` : ''
    }`
  );
  thunkAPI.dispatch(setMatchData(matchData));

  return matchData;
});
