import api from '../../api';
import {
  TBlendedCombinationParams,
  TBlendedCombinationPuntersParams,
} from './combinations.types';

// Blended - All Bets
const blendedCombinationsURL = '/bookie/trade-manager/blended/combinations';
export const queryBlendedCombinations = (params: TBlendedCombinationParams) =>
  api
    .get<Api['BlendedCombinationStatsResponse']>(blendedCombinationsURL, {
      params,
    })
    .then((res) => res.data);

const blendedCombinationPuntersURL =
  '/bookie/trade-manager/blended/combination-punters';

export const queryBlendedCombinationPunters = (
  params: TBlendedCombinationPuntersParams
) =>
  api
    .get<Api['BlendedCombinationPunterStatsResponse']>(
      blendedCombinationPuntersURL,
      {
        params,
      }
    )
    .then((res) => res.data);
