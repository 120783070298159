import { TMarketId } from '../../../features/tradeManager/pages/MatchDetails/types';
import { TProposition } from '../../../lib/DBModels';

import api from '../../api';

export const propositionForMarketURL = `/bookie/trade-manager/sport-details/proposition-for-market`;

export const queryPropositionForMarket = (params: TMarketId) =>
  api
    .get<{ propositions: TProposition[] }>(propositionForMarketURL, { params })
    .then((res) => res.data);
