import React from 'react';
import { Sport } from '@styled-icons/fluentui-system-regular/Sport';
import { HorseHead } from '@styled-icons/fa-solid/HorseHead';
import { DeviceMobile } from '@styled-icons/heroicons-outline/DeviceMobile';
import { Desktop } from '@styled-icons/ionicons-sharp/Desktop';
import { Image, Tag, HStack, Icon, Flex } from '@chakra-ui/react';
import { usePunterCategoriesObject } from '../views/Promotion/components/Form/Form.hooks';
import { TMedia } from '@/api/promoManager/banners/banner.types';
import { useQueryPromotions } from '@/api/promoManager/promotions/promotions.hooks';

export const usePromotions = () => {
  const punterCategoryList = usePunterCategoriesObject();
  const { data, isLoading } = useQueryPromotions();

  const getCategoryNameById = (id: string) => {
    if (!punterCategoryList) return;
    const categoryName = punterCategoryList[id];
    return categoryName ?? null;
  };

  const columns = [
    {
      accessorKey: 'desktop',
      header: 'Thumnnail',
      cell: (props: { row: { original: { media: TMedia } } }) => {
        const cell = props?.row?.original?.media;
        return (
          <Image
            sx={{ maxW: '280px', maxH: '150px' }}
            src={
              (cell.desktop?.publicUrl ||
                cell.mobile?.publicUrl ||
                cell.app?.publicUrl) ??
              ''
            }
          />
        );
      },
    },
    {
      accessorKey: 'title',
      header: 'Name',
      cell: (props: { getValue: () => string }) => props.getValue(),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: (props: { getValue: () => string }) => props.getValue(),
    },
    {
      accessorKey: 'location',
      header: 'States Available',
      cell: (props: { getValue: () => string[] }) => (
        <HStack spacing={1}>
          {props.getValue().map((el) => (
            <Tag size="sm" key={el} variant="solid" bg="origin.500">
              {el}
            </Tag>
          ))}
        </HStack>
      ),
    },
    {
      accessorKey: 'display',
      header: 'Authenticated',
      cell: (props: { getValue: () => string }) => props.getValue(),
    },
    {
      accessorKey: 'punter_category',
      header: 'Punter Category',
      cell: (props: { getValue: () => string[] }) => (
        <HStack spacing={1}>
          {props.getValue().map((el) => (
            <Tag size="sm" key={el} variant="solid" bg="origin.500">
              {getCategoryNameById(el)}
            </Tag>
          ))}
        </HStack>
      ),
    },
    {
      accessorKey: 'device',
      header: 'Device',
      cell: (props: { row: { original: { media: TMedia } } }) => (
        <Flex>
          {!!props.row.original.media.desktop?.publicUrl && (
            <Icon as={Desktop} boxSize="8" />
          )}
          {(!!props.row.original.media.mobile?.publicUrl ||
            props.row.original.media.mobile) && (
            <Icon as={DeviceMobile} boxSize="8" />
          )}
        </Flex>
      ),
    },
    {
      accessorKey: 'available_on',
      header: 'Available on',
      cell: (props: { row: { original: { available_on: string[] } } }) => (
        <Flex pl="4">
          {!!props?.row?.original?.available_on?.includes('RACING') && (
            <Icon as={HorseHead} boxSize="8" />
          )}
          {!!props?.row?.original?.available_on?.includes('SPORTS') && (
            <Icon as={Sport} boxSize="8" />
          )}
        </Flex>
      ),
    },
    {
      accessorKey: 'order',
      header: 'Order',
      cell: (props: { row: { index: number } }) => (
        <Flex>{props.row.index + 1}</Flex>
      ),
    },
  ];

  return { columns, data, isLoading };
};
