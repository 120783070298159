import api from '@/api/api';

export type TDashboardQueryParams = {
  start_time?: string;
  end_time?: string;
  punter_category?: string[];
  bet_type?: string[];
  event_type?: string[];
  region?: string[];
  code?: string[];
  race_type?: string[];
  sport_id?: string;
};

type TValueAndPercentage = {
  value: number;
  percentage_change: number;
};

export type TDashboardDataResponse = {
  real_money: {
    bets_placed_count: TValueAndPercentage; // bets places
    bets_placed_stake: TValueAndPercentage;
    active_bets_count: TValueAndPercentage; // active bets count
    active_bets_stake: TValueAndPercentage;
    average_bet_stake: TValueAndPercentage;
    bets_settled_count: TValueAndPercentage;
    bets_settled_payout: TValueAndPercentage;
    bets_settled_stake: TValueAndPercentage;
    profit: TValueAndPercentage;
    margin: TValueAndPercentage;
  };
  bonus_bet: {
    bets_placed_count: TValueAndPercentage; // bets places
    bets_placed_stake: TValueAndPercentage;
    active_bets_count: TValueAndPercentage; // active bets count
    active_bets_stake: TValueAndPercentage;
    average_bet_stake: TValueAndPercentage;
    bets_settled_count: TValueAndPercentage;
    bets_settled_payout: TValueAndPercentage;
    bets_settled_stake: TValueAndPercentage;
    profit: TValueAndPercentage;
    margin: TValueAndPercentage;
  };
};

export type TPunterCategory = {
  category: string;
  stats: TValueAndPercentage;
};

export type TPunterCategoryStats = {
  PunterCategoryStats: TPunterCategory[];
};

export type TTransactionStatsResponse = {
  deposit_count: TValueAndPercentage;
  deposit_amount: TValueAndPercentage;
  average_deposit_amount: TValueAndPercentage;
  first_deposit_count: TValueAndPercentage;
  first_deposit_amount: TValueAndPercentage;
  withdrawal_count: TValueAndPercentage;
  withdrawal_amount: TValueAndPercentage;
  total_balance: TValueAndPercentage;
  average_bet_stake?: TValueAndPercentage;
  new_punters: number;
};

export function getDashboardData(queryParams: TDashboardQueryParams) {
  return api.get<TDashboardDataResponse>('/dashboard/bet-stats/', {
    params: queryParams,
  });
}

export function queryPunterStats(queryParams: TDashboardQueryParams) {
  return api.get<TPunterCategoryStats>('/dashboard/punter-bet-stats/', {
    params: queryParams,
  });
}

export function queryTransactionStats(queryParams: TDashboardQueryParams) {
  return api.get<TTransactionStatsResponse>('/dashboard/transaction-stats', {
    params: queryParams,
  });
}
