import api from '@/api/api';
import { TMarketBet } from '../../trademanager.types';
import { TBetsForExoticsParams } from './betsForExotics.types';

export const betsForExoticsURL =
  '/bookie/trade-manager/race-details/bets-for-exotics';

// Bets For Exotics
export const queryBetsForExotics = (params: TBetsForExoticsParams) =>
  api.get<TMarketBet[]>(betsForExoticsURL, { params }).then((res) => res.data);
